// 'use client'
// import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import chair from '../../assets/pics/Chair.svg'
// import people from '../../assets/pics/people.svg'
// import houses from '../../assets/pics/houses.svg'
// import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
// import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
// import { TbBed } from "react-icons/tb";



// const HotelDetailComp = ({ data }) => {
//     const [favorites, setFavorites] = useState({});
//     const [isMobileView, setIsMobileView] = useState(false);

//     const toggleFavorite = (itemIndex, imageIndex) => {
//         setFavorites(prevState => ({
//             ...prevState,
//             [itemIndex]: {
//                 ...prevState[itemIndex],
//                 [imageIndex]: !prevState[itemIndex]?.[imageIndex]
//             }
//         }));
//     };

//     useEffect(() => {
//         const handleResize = () => {
//             setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
//         };

//         window.addEventListener('resize', handleResize);

//         handleResize(); // Set initial value

//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const CustomNextArrow = ({ onClick }) => (
//         <div
//             className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
//             onClick={onClick}
//         >
//             <SlArrowRight className='text-[#000]' />
//         </div>
//     );

//     const CustomPrevArrow = ({ onClick }) => (
//         <div
//             className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
//             onClick={onClick}
//         >
//             <SlArrowLeft className='text-[#000] text-xl' />
//         </div>
//     );

//     const imageSettings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         nextArrow: <CustomNextArrow />,
//         prevArrow: <CustomPrevArrow />,
//     };

//     const mainCarouselSettings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         arrows: false,
//         responsive: [
//             {
//                 breakpoint: 1024, // lg
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 1,
//                 }
//             },
//             {
//                 breakpoint: 768, // md
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 1,
//                 }
//             },
//             {
//                 breakpoint: 640, // sm
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 }
//             },

//         ]
//     };

//     return (
//         <div className={`w-full ${isMobileView ? '' : 'grid grid-cols-4 gap-6'}`}>
//             {isMobileView ? (
//                 <Slider {...mainCarouselSettings}>
//                     {data.map((item, itemIndex) => (
//                         <div key={itemIndex} className='p-2'>
//                             <div className='bg-white rounded-lg shadow-lg'>
//                                 <Slider {...imageSettings}>
//                                     {item.images.map((e, imageIndex) => (
//                                         <div key={imageIndex} className='w-full relative'>
//                                             <img src={e.img1} alt="" className='rounded-lg w-full' />
//                                             <div
//                                                 className='bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer'
//                                                 onClick={() => toggleFavorite(itemIndex, imageIndex)}
//                                             >
//                                                 {favorites[itemIndex]?.[imageIndex] ? (
//                                                     <IoMdHeart className='text-primary' />
//                                                 ) : (
//                                                     <IoMdHeartEmpty className='text-primary' />
//                                                 )}
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </Slider>
//                                 <div className='flex flex-col items-start gap-2 p-3'>
//                                     <h1 className='text-darkGray lg:text-xl text-base font-medium'>{item.name}</h1>
//                                     <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.loc}</p>
//                                     <div className='flex items-center w-full justify-between gap-4'>
//                                         <div className='flex items-center gap-1'>
//                                             <TbBed className='text-sm text-primary' />
//                                             <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.room}</p>
//                                         </div>
//                                         <h1 className='text-secondary text-xl'>•</h1>
//                                         <div className='flex items-center gap-1'>
//                                             <img src={houses} alt="" />
//                                             <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.venu}</p>
//                                         </div>
//                                     </div>

//                                     <div className='flex flex-col w-full '>
//                                         <h1 className='text-primary lg:text-lg sm:text-base text-sm '>{item.aed}</h1>
//                                         <p className='text-secondary sm:text-sm text-[10px] mt-1'>{item.price}</p>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </Slider>
//             ) : (
//                 data.map((item, itemIndex) => (
//                     <div key={itemIndex} className='bg-white rounded-lg shadow-lg'>
//                         <Slider {...imageSettings}>
//                             {item.images.map((e, imageIndex) => (
//                                 <div key={imageIndex} className='w-full relative'>
//                                     <img src={e.img1} alt="" className='rounded-lg' />
//                                     <div
//                                         className='bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer'
//                                         onClick={() => toggleFavorite(itemIndex, imageIndex)}
//                                     >
//                                         {favorites[itemIndex]?.[imageIndex] ? (
//                                             <IoMdHeart className='text-primary' />
//                                         ) : (
//                                             <IoMdHeartEmpty className='text-primary' />
//                                         )}
//                                     </div>
//                                 </div>
//                             ))}
//                         </Slider>
//                         <div className='flex flex-col items-start gap-2 p-3'>
//                             <h1 className='text-darkGray text-xl font-medium'>{item.name}</h1>
//                             <p className='text-secondary'>{item.loc}</p>

//                             <div className='flex items-center w-full justify-between gap-4'>
//                                 <div className='flex items-center gap-1'>
//                                     <TbBed className='text-lg text-primary' />
//                                     <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.room}</p>
//                                 </div>
//                                 <h1 className='text-secondary text-xl'>•</h1>
//                                 <div className='flex items-center gap-1'>
//                                     <img src={houses} alt="" />
//                                     <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.venu}</p>
//                                 </div>
//                             </div>

//                             <div className='flex flex-col w-full '>
//                                 <h1 className='text-primary lg:text-lg sm:text-base text-sm '>{item.aed}</h1>
//                                 <p className='text-secondary sm:text-sm text-[10px] mt-1'>{item.price}</p>
//                             </div>
//                         </div>
//                     </div>
//                 ))
//             )}
//         </div>
//     );
// };

// export default HotelDetailComp;








////////////////////////////////////***********Dumy Data***********/////////////////////////////////////////






'use client'
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import chair from '../../assets/pics/Chair.svg'
import people from '../../assets/pics/people.svg'
import houses from '../../assets/pics/houses.svg'
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { TbBed } from "react-icons/tb";



const HotelDetailComp = ({ data }) => {
    const [favorites, setFavorites] = useState({});
    const [isMobileView, setIsMobileView] = useState(false);

    const toggleFavorite = (itemIndex, imageIndex) => {
        setFavorites(prevState => ({
            ...prevState,
            [itemIndex]: {
                ...prevState[itemIndex],
                [imageIndex]: !prevState[itemIndex]?.[imageIndex]
            }
        }));
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
        };

        window.addEventListener('resize', handleResize);

        handleResize(); // Set initial value

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const CustomNextArrow = ({ onClick }) => (
        <div
            className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
            onClick={onClick}
        >
            <SlArrowRight className='text-[#000]' />
        </div>
    );

    const CustomPrevArrow = ({ onClick }) => (
        <div
            className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
            onClick={onClick}
        >
            <SlArrowLeft className='text-[#000] text-xl' />
        </div>
    );

    const imageSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };

    const mainCarouselSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024, // lg
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768, // md
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 640, // sm
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },

        ]
    };

    return (
        <div className={`w-full ${isMobileView ? '' : 'grid grid-cols-4 gap-6'}`}>
            {isMobileView ? (
                <Slider {...mainCarouselSettings}>
                    {data.map((item, itemIndex) => (
                        <div key={itemIndex} className='p-2'>
                            <div className='bg-white rounded-lg shadow-lg'>
                                <Slider {...imageSettings}>
                                    {item.images.map((e, imageIndex) => (
                                        <div key={imageIndex} className='w-full relative'>
                                            <img src={e.img1} alt="" className='rounded-lg w-full h-full' />
                                            <div
                                                className='bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer'
                                                onClick={() => toggleFavorite(itemIndex, imageIndex)}
                                            >
                                                {favorites[itemIndex]?.[imageIndex] ? (
                                                    <IoMdHeart className='text-primary' />
                                                ) : (
                                                    <IoMdHeartEmpty className='text-primary' />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                <div className='flex flex-col items-start gap-2 p-3'>
                                    <h1 className='text-darkGray lg:text-xl text-base font-medium'>{item.name}</h1>
                                    <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.loc}</p>
                                    <div className='flex items-center w-full justify-between gap-4'>
                                        <div className='flex items-center gap-1'>
                                            <TbBed className='text-sm text-primary' />
                                            <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.room}</p>
                                        </div>
                                        <h1 className='text-secondary text-xl'>•</h1>
                                        <div className='flex items-center gap-1'>
                                            <img src={houses} alt="" />
                                            <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.venu}</p>
                                        </div>
                                    </div>

                                    <div className='flex flex-col w-full '>
                                        <h1 className='text-primary lg:text-lg sm:text-base text-sm '>{item.aed}</h1>
                                        <p className='text-secondary sm:text-sm text-[10px] mt-1'>{item.price}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                data.map((item, itemIndex) => (
                    <div key={itemIndex} className='bg-white rounded-lg shadow-lg'>
                        <Slider {...imageSettings}>
                            {item.images.map((e, imageIndex) => (
                                <div key={imageIndex} className='w-full relative'>
                                    <img src={e.img1} alt="" className='rounded-lg w-full h-full' />
                                    <div
                                        className='bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer'
                                        onClick={() => toggleFavorite(itemIndex, imageIndex)}
                                    >
                                        {favorites[itemIndex]?.[imageIndex] ? (
                                            <IoMdHeart className='text-primary' />
                                        ) : (
                                            <IoMdHeartEmpty className='text-primary' />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className='flex flex-col items-start gap-2 p-3'>
                            <h1 className='text-darkGray text-xl font-medium'>{item.name}</h1>
                            <p className='text-secondary'>{item.loc}</p>

                            <div className='flex items-center w-full justify-between gap-4'>
                                <div className='flex items-center gap-1'>
                                    <TbBed className='text-lg text-primary' />
                                    <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.room}</p>
                                </div>
                                <h1 className='text-secondary text-xl'>•</h1>
                                <div className='flex items-center gap-1'>
                                    <img src={houses} alt="" />
                                    <p className='text-secondary lg:text-base sm:text-sm text-[11px]'>{item.venu}</p>
                                </div>
                            </div>

                            <div className='flex flex-col w-full '>
                                <h1 className='text-primary lg:text-lg sm:text-base text-sm '>{item.aed}</h1>
                                <p className='text-secondary sm:text-sm text-[10px] mt-1'>{item.price}</p>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default HotelDetailComp;
