/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FaStar, FaMapMarkerAlt, FaRegMap } from 'react-icons/fa';
import {
	OverviewIcon1,
	OverviewIcon2,
	OverviewIcon3,
	OverviewIcon5,
} from '../../svgs';

const OverviewSection = ({ data }) => {
	// Overview details pulled dynamically from data
	const overviewDetails = [
		{
			icon: <OverviewIcon1 />,
			label: 'Starts From',
			value: `${data?.pricing?.basePrice || 'Price Missing'
				} AED per Night`,
		},
		{
			icon: <OverviewIcon2 />,
			label: 'Rooms',
			value: data?.accommodation?.accommodations || '0',
		},
		{
			icon: <OverviewIcon3 />,
			label: 'Venues',
			value: data?.venues?.length || 'Missing',
		},
		{
			icon: <OverviewIcon5 />,
			label: 'Hotel Chain',
			value: data?.overview?.basicDetails?.hotelChain,
		},
	];


	// console.log(data)

	return (
		<>
			<div className='lg:w-3/4 w-full px-5 mx-auto'>
				<div className='flex justify-between items-center mb-4'>
					<h2 className='text-3xl font-bold flex gap-4 items-center'>
						{data?.overview?.basicDetails?.hotelName ||
							'Hotel Name Missing'}
						<span className='flex gap-2 items-center text-sm text-[#F2C200]'>
							{data?.overview.basicDetails?.hotelRating ? (
								Array.from(
									{
										length: data?.overview?.basicDetails?.hotelRating,
									},
									(_, i) => <FaStar key={i} />
								)
							) : (
								<FaStar className='text-gray-400' />
							)}
						</span>
					</h2>
				</div>

				<div className='text-gray-600 flex gap-2 items-center mb-4'>
					<FaMapMarkerAlt className='inline mr-2' />
					<span className='font-medium underline decoration-2 underline-offset-2'>
						{`${data?.overview?.locationDetails?.city}, ${data?.overview?.locationDetails?.area}` ||
							'Location Missing'}
					</span>
					<a
						href='#map'
						className='text-blue-500 flex ml-1 font-semibold gap-2 items-center'
					>
						<FaRegMap />{' '}
						<span className='underline underline-offset-2 decoration-2'>
							Show on map
						</span>
					</a>
				</div>

				<h3 className='text-2xl font-bold py-4'>Overview</h3>

				<div className='grid lg:grid-cols-4 grid-cols-2 gap-8 mb-4'>
					{overviewDetails.map((detail, index) => (
						<div
							key={index}
							className='flex flex-col gap-2 w-full text-black items-center bg-[#F7F7F7] p-4 rounded-lg shadow-md'
						>
							{detail.icon}
							{detail.label !== 'Starts From' ? (
								<>
									<p>{detail.value}</p>
									<p>{detail.label}</p>
								</>
							) : (
								<>
									<p>{detail.label}</p>
									<p>{detail.value}</p>
								</>
							)}
						</div>
					))}
				</div>

				<div className='h-[1px] bg-[#EBEBEB] w-full my-10'></div>

				<h3 className='text-2xl font-bold mb-4'>Highlights</h3>
				<div className='grid grid-cols-1 md:grid-cols-4 gap-4 p-6 w-full rounded-xl bg-[#FFEBEB]'>
					<div className='col-span-3 border-r border-gray-300 pr-4 pl-4 md:pl-0'>
						<h3 className='text-xl font-medium mb-4'>Hotel Type</h3>
						<div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
							{data?.overview?.basicDetails?.selectedTags?.types.map(
								(item, index) => (
									<div key={index} className='flex items-center'>
										<span
											className={`${item.disabled
												? 'text-gray-400 line-through'
												: 'text-black'
												}`}
										>
											{item}
										</span>
									</div>
								)
							)}
						</div>
					</div>
					<div className='col-span-1 pl-4'>
						<div className='flex flex-col gap-2 items-start'>
							<>
								<h3 className='text-xl font-medium'>Build Year</h3>
								<span className={`text-black`}>
									{data?.overview?.additionalDetails?.hotelBuiltYear == null ? '' : new Date(
										data?.overview?.additionalDetails?.hotelBuiltYear
									).getFullYear()}
								</span>
							</>
							<>
								<h3 className='text-xl font-medium'>
									Renovated Year
								</h3>
								<span className={`text-black`}>
									{data?.overview?.additionalDetails?.lastRenovatedYear == null ? '' : new Date(
										data?.overview?.additionalDetails?.lastRenovatedYear
									).getFullYear()}
								</span>
							</>
						</div>
					</div>
				</div>

				<div className='flex justify-between mt-5 p-2 rounded-lg bg-[#FFEBEB]'>
					<div className='p-4 rounded-lg w-1/2 mr-2'>
						<h4 className='font-semibold mb-2'>View</h4>
						<p>
							{data?.overview?.basicDetails?.selectedTags?.views.join(
								', '
							) || 'Views Missing'}
						</p>
					</div>
					<div className='p-4 border-l border-gray-300 pl-5 w-1/2 ml-2'>
						<h4 className='font-semibold mb-2'>Style</h4>
						<p>
							{Object.entries(
								data?.overview?.basicDetails?.selectedTags?.styles ||
								{}
							)
								.flatMap(([key, values]) => values)
								.join(', ') || 'Styles Missing'}
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default OverviewSection;
