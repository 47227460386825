import React, { useState } from "react";
import { Link } from "react-router-dom";
import p1 from "../../assets/icons/venuPre1.svg";
import p2 from "../../assets/icons/venuPre2.svg";
import p3 from "../../assets/icons/venuPre3.svg";
import p4 from "../../assets/icons/venuPre4.svg";
import p5 from "../../assets/icons/venuPre5.svg";
import p6 from "../../assets/icons/venuPre6.svg";
import p7 from "../../assets/icons/venuPre7.svg";

const CapacityLayout = ({ data, UploadDocuments }) => {
  const icons = {
    standing: p1,
    sitting: p2,
    dining: p3,
    theatre: p4,
    boardroom: p5,
    cabaret: p6,
    ushaped: p7,
  };

  const capacityData = [
    {
      icon: p1,
      title: "Standing",
      description: data?.standing ? `up to ${data?.standing} People` : "Not available",
    },
    {
      icon: p2,
      title: "Sitting",
      description: data?.sitting ? `up to ${data?.sitting} People` : "Not available",
    },
    {
      icon: p3,
      title: "Dining",
      description: data.additionalLayout?.dining?.enabled
        ? `up to ${data.additionalLayout?.dining?.value} People`
        : "Not available",
    },
    {
      icon: p4,
      title: "Theatre",
      description: data.additionalLayout?.theatre?.enabled
        ? `up to ${data.additionalLayout?.theatre?.value} People`
        : "Not available",
    },
    {
      icon: p5,
      title: "Boardroom",
      description: data.additionalLayout?.boardroom?.enabled
        ? `up to ${data.additionalLayout?.boardroom?.value} People`
        : "Not available",
    },
    {
      icon: p6,
      title: "Cabaret",
      description: data.additionalLayout?.cabaret?.enabled
        ? `up to ${data.additionalLayout?.cabaret?.value} People`
        : "Not available",
    },
    {
      icon: p7,
      title: "U-shaped",
      description: data.additionalLayout?.ushaped?.enabled
        ? `up to ${data.additionalLayout?.ushaped?.value} People`
        : "Not available",
    },
  ];

  // Filter out entries with "Not available" descriptions
  const filteredData = capacityData.filter(item => item.description !== "Not available");

  const [visibleCount, setVisibleCount] = useState(2);
  const itemsPerPage = 2;

  const handleShowMore = () => {
    setVisibleCount(prevCount => Math.min(prevCount + itemsPerPage, filteredData.length));
  };

  const handleShowLess = () => {
    setVisibleCount(itemsPerPage); // Reset to initial number of items (2)
  };

  // Slice the filtered data to show the current set of items
  const displayedItems = filteredData.slice(0, visibleCount);

  return (
    <div className="rounded-lg mb-8 border-t border-gray-300 lg:w-3/4 w-full px-5 mx-auto py-10">
      <h2 className="text-2xl font-bold mb-4">Capacity & Layout</h2>

      <div className="flex justify-between flex-wrap">
        <div className="mb-4 flex flex-col">
          <span className="text-lg font-semibold">Capacity</span>
          {UploadDocuments?.floorPlans &&
							UploadDocuments?.floorPlans.length > 0 && (
								<button
									class='text-red-500 underline cursor-pointer'
									onClick={() => {
										UploadDocuments.floorPlans.forEach(
											(menuLink) => {
												const link = document.createElement('a');
												link.href = menuLink;
												link.setAttribute('download', ''); // Use the filename from the URL, or you can provide a specific one
												document.body.appendChild(link);
												link.click();
												document.body.removeChild(link);
											}
										);
									}}
								>
									View Floor Plan
								</button>
							)}
        </div>
        <div className="grid lg:w-3/4 w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {displayedItems.map((item, index) => (
            <div key={index} className="flex items-center py-4 rounded-lg">
              <div className="flex justify-center items-center mr-4">
                <img src={item.icon} alt={item.title} />
              </div>
              <div className="grid grid-cols-2 gap-2 w-full">
                <div className="col-span-1 text-lg font-semibold">{item.title}</div>
                <div className="col-span-1 text-gray-600">{item.description}</div>
              </div>
            </div>
          ))}
          {filteredData.length > itemsPerPage && (
            <div className="text-center mt-4 md:col-span-2 w-full flex justify-start">
              {visibleCount < filteredData.length && (
                <button
                  onClick={handleShowMore}
                  className="text-[#FE4747] underline mr-4"
                >
                  Show More
                </button>
              )}
              {visibleCount > itemsPerPage && (
                <button onClick={handleShowLess} className="text-[#FE4747] underline">
                  Show Less
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CapacityLayout;
