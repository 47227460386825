import React from "react";
import { IoMdCheckmark } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

const GetStart = () => {
  const navigate = useNavigate();
  const navigateRegister = () => {
    navigate("/register");
  };
  return (
    <div className="w-full flex  justify-center my-8">
      <div className="flex items-center sm:flex-row flex-col gap-8  lg:w-[75%] w-[90%]">
        <div className="flex-1 w-full">
          <h1 className="lg:text-[30px] sm:text-2xl text-xl  font-semibold">
            Let&apos;s get your Venue Noticed!
          </h1>
        </div>
        <div className="xl:w-[35%] lg:w-[45%] sm:w-1/2 w-full">
          <div className="bg-white rounded-2xl shadow-xl flex flex-col gap-4 p-7 items-start">
            <h1 className="sm:text-2xl text-xl font-medium">
              Maximize Your Reach: Join Now and Book More Events!
            </h1>
            <div className="flex items-center gap-3">
              <IoMdCheckmark className="text-xl flex-shrink-0" />
              <p>Increased visiblity to potential client</p>
            </div>
            <div className="flex items-center gap-3">
              <IoMdCheckmark className="text-xl flex-shrink-0" />
              <p>Valuable insights into client preferences </p>
            </div>
            <div className="flex items-center gap-3">
              <IoMdCheckmark className="text-xl flex-shrink-0" />
              <p>
                Revenue boost through targeted promotions and collaborations
                visiblity to potential client
              </p>
            </div>

            <button
              onClick={navigateRegister}
              className="bg-primary rounded-lg text-white py-2 w-full"
            >
              Let's get started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStart;
