import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import logo from '../assets/images/logo.svg';
import profileIcon from '../assets/images/profile_icon.svg'; // Ensure you have the correct path
import { Cookies } from 'react-cookie';
function ResponsiveAppBar() {
	const [anchorElExplore, setAnchorElExplore] = useState(null);
	const [anchorElProfile, setAnchorElProfile] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();

	const isLoggedIn = !!localStorage.getItem('accessToken'); // Check if user is logged in
	const handleOpenExploreMenu = (event) => {
		setAnchorElExplore(event.currentTarget);
	};

	const handleCloseExploreMenu = () => {
		setAnchorElExplore(null);
	};

	const handleOpenProfileMenu = (event) => {
		setAnchorElProfile(event.currentTarget);
	};

	const handleCloseProfileMenu = () => {
		setAnchorElProfile(null);
	};

	const handleLogout = () => {
		const cookies = new Cookies();

		// Remove the token from cookies
		cookies.remove('token', { path: '/' });

		// Remove the accessToken and vendorId from localStorage
		localStorage.removeItem('accessToken');
		localStorage.removeItem('vendorId');
		localStorage.removeItem('invitedBy');
		localStorage.removeItem('checkAdmin');

		// Redirect to the home page or login page
		navigate('/');
	};
	return (
		<AppBar position='static'>
			<Container maxWidth='xl'>
				<Toolbar disableGutters>
					<Link
						to='/'
						style={{ display: 'block', width: 'auto', zIndex: '999' }}
					>
						<Box
							component='img'
							src={logo}
							alt='Logo'
							sx={{ display: 'block', width: '100%', height: 'auto' }}
						/>
					</Link>

					<Box sx={{ flexGrow: 1 }} />
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{location.pathname !== '/onboarding' && (
							<Link
								variant='body2'
								component={Link}
								to='/onboarding'
								sx={{
									mr: 6,
									pr: 8,
									textDecoration: 'none',
									color: 'inherit',
								}}
							>
								List your Venue
							</Link>
						)}
						<Box
							sx={{
								display: { xs: 'none', md: 'flex' },
								alignItems: 'center',
							}}
						>
							<IconButton onClick={handleOpenProfileMenu}>
								<Box
									component='img'
									src={profileIcon}
									alt='profile'
									sx={{ height: 45, width: 45 }}
								/>
							</IconButton>
							<Menu
								anchorEl={anchorElProfile}
								open={Boolean(anchorElProfile)}
								onClose={handleCloseProfileMenu}
							>
								{/* Display the Log Out or Log In menu item based on login status */}
								{isLoggedIn ? (
									<>
										<MenuItem onClick={handleCloseProfileMenu}>
											Profile
										</MenuItem>
										<MenuItem onClick={handleLogout}>
											Log Out
										</MenuItem>
									</>
								) : (
									<MenuItem
										component={Link}
										to='/login'
										onClick={handleCloseProfileMenu}
									>
										Log In
									</MenuItem>
								)}
							</Menu>
						</Box>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}

export default ResponsiveAppBar;
