// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  margin-left: 1rem;
}

.tooltip:hover:before {
  background-color: #fff;
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  bottom: 0;
  content: "";
  display: block;
  height: 16px;
  overflow: hidden;
  position: absolute;
  top: 28px;
  transform: rotate(45deg);
  width: 16px;
  z-index: 2;
  padding: 0;
}

.tooltip:hover:after {
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #dddddd;
  color: var(--tooltip-color);
  content: attr(data-tooltip);
  font-family: Outfit;
  font-size: 14px;
  left: -40px;
  padding: 16px;
  position: absolute;
  top: 35px;
  white-space: wrap;
  z-index: 1;
  width: 15rem;
  text-transform: none;
}

.tooltip[data-error="true"] {
  --tooltip-color: #f25700;
}

.tooltip[data-error="false"] {
  --tooltip-color: var(--theme-grey-text);
}
`, "",{"version":3,"sources":["webpack://./src/components/Tooltip.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,8BAA8B;EAC9B,6BAA6B;EAC7B,SAAS;EACT,WAAW;EACX,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,wBAAwB;EACxB,WAAW;EACX,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,2BAA2B;EAC3B,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".tooltip {\n  margin-left: 1rem;\n}\n\n.tooltip:hover:before {\n  background-color: #fff;\n  border-left: 1px solid #d9d9d9;\n  border-top: 1px solid #d9d9d9;\n  bottom: 0;\n  content: \"\";\n  display: block;\n  height: 16px;\n  overflow: hidden;\n  position: absolute;\n  top: 28px;\n  transform: rotate(45deg);\n  width: 16px;\n  z-index: 2;\n  padding: 0;\n}\n\n.tooltip:hover:after {\n  background-color: #fff;\n  border-radius: 16px;\n  border: 1px solid #dddddd;\n  color: var(--tooltip-color);\n  content: attr(data-tooltip);\n  font-family: Outfit;\n  font-size: 14px;\n  left: -40px;\n  padding: 16px;\n  position: absolute;\n  top: 35px;\n  white-space: wrap;\n  z-index: 1;\n  width: 15rem;\n  text-transform: none;\n}\n\n.tooltip[data-error=\"true\"] {\n  --tooltip-color: #f25700;\n}\n\n.tooltip[data-error=\"false\"] {\n  --tooltip-color: var(--theme-grey-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
