import React, { useContext, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { base_URL } from "../../config/config";
import { VenueContext } from "../../context/VenueContext";
import Cookies from "js-cookie";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Add carousel styles
import { MdClose, MdFullscreen } from "react-icons/md";

const HeroSection = ({ data = [], data2, videos, id }) => {
  const { formData, resetForm, setCurrentStep } = useContext(VenueContext);

  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

  const vendorId = localStorage.getItem("vendorId");
  const path = useLocation();
  const errors = localStorage.getItem("Errors");

  // Handle form submission
  const handleSubmit = async () => {
    if (errors) {
      toast.error(errors.trim());
    } else {
      const updatedItemStatus =
        formData?.itemStatus === "Approved"
          ? "Edit Pending"
          : formData?.itemStatus === "Rejected"
          ? "ReSubmitting"
          : "Pending";

      // Manually construct the updated form data
      const updatedFormData = {
        ...formData,
        itemStatus: updatedItemStatus,
        vendorId: vendorId,
        status: "completed",
        venueName: formData?.basicDetails?.venueName,
      };

      // Log the updated form data for verification
      console.log("Form Data before submit:", updatedFormData);

      if (path.pathname?.includes("venue-preview")) {
        const response = await axios.post(
          `${base_URL}/api/venueform/updateVenueForm`,
          updatedFormData
          // { ...formData, vendorId: vendorId, status: "completed" }
        );

        if (response.data.success) {
          // Assuming `success` indicates a successful registration
          toast.success("Venue created successfully!");
          localStorage.removeItem("Errors");
          Cookies.remove("uploadedFiles");
          resetForm();
          setTimeout(() => {
            navigate("/dashboard?venueRegister=true"); // Redirect to the dashboard
          }, 1000); // Delay to allow toast message to display
        } else {
          // Handle unsuccessful venue creation
          toast.error("Failed to create the venue.");
        }
      } else {
        const updatedItemStatus =
          formData?.itemStatus === "Approved"
            ? "Edit Pending"
            : formData?.itemStatus === "Rejected"
            ? "ReSubmitting"
            : "pending";

        // Manually construct the updated form data
        const updatedFormData = {
          ...formData,
          itemStatus: updatedItemStatus,
          vendorId: vendorId,
          status: "completed",
        };
        const response = await axios.post(
          `${base_URL}/api/restaurantform/updateResForm`,
          updatedFormData
          // { ...formData, vendorId: vendorId, status: "completed" }
        );

        if (response.data.success) {
          toast.success("Restaurant created successfully!");
          Cookies.remove("uploadedFiles");
          resetForm();
          setTimeout(() => {
            navigate("/dashboard?restaurantRegister=true");
          }, 1000); // Delay to allow toast message to display
        } else {
          console.log(response);
          // Handle unsuccessful restaurant creation
          toast.error("Failed to create the restaurant.");
        }
      }
    }
  };

  // Toggle modal visibility
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  // Open the modal with all photos
  const handleShowAllPhotos = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleFullScreen = () => {
    const elem = document.querySelector(".custom-carousel");
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const handleBackToPreview = () => {
    setCurrentStep(10); // Navigate to step 10 (Preview)
    // window.history.back()
    if (path.pathname?.includes("restaurant-preview")) {
      navigate(`/venu-restaurant-Form/Restaurant/${id}`);
    } else {
      window.history.back();
    }
    // navigate(`/hostel-Listing-Form/${id}`); // Programmatically navigate to the Preview page
  };

  return (
    <div className="p-6">
      <div className="flex flex-wrap lg:px-20 justify-between gap-5 items-center w-full mb-6">
        <div
          onClick={() => window.history.back()}
          className="flex items-center text-gray-600 cursor-pointer"
        >
          <FaArrowLeft className="mr-2" />
          <span>Back</span>
        </div>

        <div className="flex gap-3 items-center">
          <div className="bg-black px-2 md:px-0 md:w-[200px] h-[40px] gap-x-2 flex items-center justify-center rounded-lg">
            <img src="/tick.svg" alt="" />
            <button className=" text-white  " onClick={handleSubmit}>
              Submit for review
            </button>
          </div>
          <Link
            to={
              path.pathname?.includes("/restaurant-preview")
                ? "/venu-restaurant-Form"
                : "/venu-Listing-Form"
            }
            className="bg-red-500 text-white px-2 md:px-0 md:w-[200px] h-[40px] justify-center rounded-lg flex items-center"
          >
            <AiOutlineEdit className="mr-2" /> Edit
          </Link>
        </div>
      </div>
      {/* venue name and previewlisting card button */}
      <div className="flex justify-between lg:px-20 mb-5">
        <div>
          <h2 className="text-[32px] font-bold flex gap-4 items-center">
            Venue Detail Page
          </h2>
        </div>
        {/* preview button */}

        <div
          className="active:opacity-30 mt-1 flex items-center justify-center text-[#222222] font-[500px] px-2 border border-[#222222] rounded-lg md:px-0 md:w-[250px] h-[40px] gap-x-3 cursor-pointer"
          onClick={handleBackToPreview}
        >
          <img src="/eye.svg" alt="eye" />
          <button>Preview Listing Card</button>
        </div>
      </div>
      {/* Main image and right side images */}
      <div className="flex justify-between flex-wrap gap-1 lg:gap-0 lg:px-16 px-0 mb-4">
        {data[0] && (
          <div className="relative lg:w-1/2 w-full">
            <img
              onClick={handleExpandClick}
              src={data[0]}
              alt="Main"
              className="rounded-lg w-full h-[585px] object-cover cursor-pointer"
            />
          </div>
        )}

        {/* Right side images and video */}
        <div className="flex flex-col lg:w-[49.5%] w-full">
          {/* First row of images */}
          <div className="flex justify-between gap-1 flex-wrap">
            {data[1] && (
              <div className="w-full sm:w-[49%] h-[290px] relative">
                <img
                  src={data[1]}
                  alt="2"
                  className="rounded-lg w-full h-full object-cover cursor-pointer"
                />
              </div>
            )}
            {data[2] && (
              <div className="w-full sm:w-[49%] h-[290px] relative mb-1">
                <img
                  src={data[2]}
                  alt="3"
                  className="rounded-lg w-full h-full object-cover cursor-pointer"
                />
              </div>
            )}
          </div>

          {/* Second row with video and additional image */}
          <div className="flex justify-between gap-1 flex-wrap">
            {/* YouTube video */}
            {videos && videos[0] && (
              <div className="w-full sm:w-[49%] h-[290px] relative mb-1">
                <iframe
                  className="rounded-lg w-full h-full object-cover cursor-pointer"
                  src={`https://www.youtube.com/embed/${
                    videos[0].split("v=")[1]
                  }`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded YouTube Video"
                />
              </div>
            )}

            {/* Additional image */}
            {data.length > 3 && (
              <div
                className="w-full sm:w-[49%] h-[290px] relative mb-1 cursor-pointer"
                onClick={handleShowAllPhotos}
              >
                <img
                  src={data[3]}
                  alt="3"
                  className="rounded-lg w-full h-full object-cover"
                />
                <div className="absolute top-0 h-full w-full bg-black bg-opacity-80 text-white flex items-center justify-center rounded-lg cursor-pointer gap-x-3">
                  <img src="/puzzle.svg" alt="" />
                  Show all photos
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal for showing all photos */}
      {isModalOpen && (
        <div className="custom-carousel z-[5000] absolute inset-0 bg-black/80 flex items-center justify-center">
          <div className="text-[16px] text-white flex gap-x-2 absolute top-2 z-[5000] ">
            <p>{`${activeIndex + 1} / ${data?.length} `}</p>
            <button
              className="text-white mr-3 text-lg cursor-pointer"
              onClick={toggleFullScreen}
            >
              <MdFullscreen />
            </button>
            <button
              className="text-white text-lg cursor-pointer"
              onClick={handleCloseModal}
            >
              <MdClose />
            </button>
          </div>

          <Carousel
            selectedItem={activeIndex}
            showArrows
            onChange={(index) => setActiveIndex(index)}
            showThumbs={true}
            showStatus={false}
            infiniteLoop
            className=" custom-carousel"
            showIndicators={false}
            // renderArrowPrev={(onClickHandler, hasPrev) => (
            //   <CustomPrevButton onClick={onClickHandler} disabled={!hasPrev} />
            // )}
            // renderArrowNext={(onClickHandler, hasNext) => (
            //   <CustomNextButton onClick={onClickHandler} disabled={!hasNext} />
            // )}
          >
            {data?.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${index}`}
                className="object-contain max-w-full max-h-[566px]"
              />
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default HeroSection;
