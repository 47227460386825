// GoogleMapComponent.jsx
import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import MapContainer from "../hostel_listing_form/overview-form/googleMap";
import extractMapCoordinates from "../../utils/extractMapCoordinates";
import { useQuery } from "@tanstack/react-query";
import { getVendorHotel } from "../../api/hotel-api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 0,
  lng: 0,
};

const GoogleMapComponent = ({ data }) => {
  const vendorId = localStorage?.getItem("vendorId");

  const [mapLocation, setMapLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [locationDetails, setLocationDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    area: "",
    lat: 0,
    lng: 0,
    poBox: "",
    country: "",
    mapLink: "",
  });

  const { data: hotel, isPending } = useQuery({
    queryKey: ["vendor-hotel"],
    queryFn: async () => {
      const res = await getVendorHotel(vendorId);
      setLocationDetails({
        ...locationDetails,
        addressLine1: res?.overview?.locationDetails?.addressLine1,
        addressLine2: res?.overview?.locationDetails?.addressLine2,
        city: res?.overview?.locationDetails?.city,
        area: res?.overview?.locationDetails?.area,
        poBox: res?.overview?.locationDetails?.poBox,
        country: res?.overview?.locationDetails?.country,
        mapLink: res?.overview?.locationDetails?.mapLink,
        lat: res?.overview?.locationDetails?.lat,
        lng: res?.overview?.locationDetails?.lng,
      });
      return res;
    },
  });
  //   const address = `${data.city}, ${data.area}, ${data.country}`;
  //   const [location, setLocation] = useState(center);

  //   useEffect(() => {
  //     if (!data || !data.lat || !data.lng) {
  //       return;
  //     }
  //     setLocation({
  //       lat: data.lat,
  //       lng: data.lng,
  //     });
  //   }, [data]);

  useEffect(() => {
    if (!isPending) {
      if (locationDetails?.lat && locationDetails?.lng) {
        setMapLocation({
          lat: locationDetails?.lat,
          lng: locationDetails?.lng,
        });
      } else if (locationDetails?.mapLink && locationDetails?.mapLink !== "") {
        const coords = extractMapCoordinates(locationDetails?.mapLink);
        if (coords) {
          setMapLocation(coords);
        } else {
          console.log("Invalid map link");
        }
      } else {
        if (
          locationDetails?.city &&
          locationDetails?.area &&
          locationDetails?.country &&
          typeof window !== "undefined"
        ) {
          const address = `${locationDetails?.city}, ${locationDetails?.area}, ${locationDetails?.country}`;
          geocodeAddress(address);
        }
      }
    }
  }, [locationDetails, isPending]);

  // useEffect(() => {
  //     // Function to get latitude and longitude from address
  //     const fetchLocation = async () => {
  //         try {
  //             const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg`);
  //             const data = await response.json();

  //             console.log(data)
  //             if (data.results.length > 0) {
  //                 const { lat, lng } = data.results[0].geometry.location;
  //                 setLocation({ lat, lng });
  //             }
  //         } catch (error) {
  //             console.error('Error fetching location:', error);
  //         }
  //     };

  //     fetchLocation();
  // }, [address]);

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0].geometry.location;
        const coords = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setMapLocation(coords);
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };

  return (
    <div className="py-5 lg:w-3/4 w-full px-5 border-t mx-auto">
      <h2 className="text-2xl font-bold mb-4">Where We Are Located</h2>
      <MapContainer
        isHotel={false}
        isVenue={true}
        lat={mapLocation.lat}
        lng={mapLocation.lng}
        mapLocation={mapLocation}
        setMapLocation={setMapLocation}
        draggable={false}
        showHeader={false}
      />
    </div>
  );
};

export default GoogleMapComponent;
