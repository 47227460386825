import React, { useContext, useState, useEffect } from "react";
import SelectableTags from "../../shared/common/selectableTags";
import { hotelViews, VenueStyles } from "../../hostel_listing_form/data";
import CustomRadioBox from "../../shared/common/CustomRadioBox";
import { eventTypes } from "./event-type-data";
import { VenueContext } from "../../../context/VenueContext";
import CloseableToolTip from "../CloseableToolTip";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Tooltip } from "@mui/material";

const venueSitting = ["Indoor", "Outdoor"];

const AdditionalDetailsForm = () => {
  const { formData, setFormData } = useContext(VenueContext);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setSelectedEventTypes(formData.additionalDetails?.selectedEventTypes || []);
  }, [formData.additionalDetails]);

  const additionalDetails = {
    venueView: formData.additionalDetails?.venueView || [],
    venueSetting: formData.additionalDetails?.venueSetting || [],
    venueStyle: formData.additionalDetails?.venueStyle || {
      traditionalStyle: [],
      modernContemporaryStyle: [],
      eclecticStyle: [],
      culturalRegionalStyle: [],
    },
    selectedEventTypes: formData.additionalDetails?.selectedEventTypes || [],
  };

  const handleInputChange = (field, value) => {
    const updatedAdditionalDetails = {
      ...additionalDetails,
      [field]: value,
    };
    setFormData((prevData) => ({
      ...prevData,
      additionalDetails: updatedAdditionalDetails,
    }));
  };

  // Calculate the total number of selected tags across all styles
  const totalSelectedTags = Object.values(additionalDetails.venueStyle).reduce(
    (acc, styleTags) => acc + styleTags.length,
    0
  );

  const handleTagClick = (category, tag) => {
    const updatedTags = additionalDetails[category].includes(tag)
      ? additionalDetails[category].filter((t) => t !== tag)
      : [...additionalDetails[category], tag];

    const updatedAdditionalDetails = {
      ...additionalDetails,
      [category]: updatedTags,
    };
    setFormData((prevData) => ({
      ...prevData,
      additionalDetails: updatedAdditionalDetails,
    }));
  };

  const handleStyleTagClick = (styleCategory, tag) => {
    const isSelected =
      additionalDetails.venueStyle[styleCategory].includes(tag);
    if (!isSelected && totalSelectedTags >= 5) {
      // If adding a new tag exceeds the limit of 5, prevent the selection
      return;
    }

    const updatedStyles = isSelected
      ? additionalDetails.venueStyle[styleCategory].filter((t) => t !== tag)
      : [...additionalDetails.venueStyle[styleCategory], tag];

    const updatedAdditionalDetails = {
      ...additionalDetails,
      venueStyle: {
        ...additionalDetails.venueStyle,
        [styleCategory]: updatedStyles,
      },
    };
    setFormData((prevData) => ({
      ...prevData,
      additionalDetails: updatedAdditionalDetails,
    }));
  };
  // venue hire
  const handleEventTypeClick = (id, name) => {
    setSelectedId(id); // Update selectedId
    setSelectedEventTypes(name); // Update the selected event type

    const updatedAdditionalDetails = {
      ...additionalDetails,
      venueHire: name, // Directly set the selected event type as venueHire
    };

    setFormData((prevData) => ({
      ...prevData,
      additionalDetails: updatedAdditionalDetails,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      venueHire: "",
    }));
  };

  const validateVenueHireSelection = () => {
    if (selectedEventTypes.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedEventTypes: "Please select a venue hire option.",
      }));
    }
  };

  useEffect(() => {
    validateVenueHireSelection();
  }, [selectedEventTypes]);

  return (
    <div>
      <h2 className="text-2xl lg:text-3xl font-semibold pb-5">
        Additional Details
      </h2>

      <div className="w-full">
        <label className="block text-neutralBlack text-xl font-bold mb-1 mt-4">
          Venue View <span className="text-red-500 ml-1">*</span>
          <span className="px-1 text-[#433f40] text-md font-semibold">
            {" "}
            (select up to top 5){" "}
          </span>
        </label>

        <SelectableTags
          label=""
          max={5}
          tags={hotelViews}
          selectedTags={additionalDetails.venueView}
          onTagClick={(tag) => handleTagClick("venueView", tag)}
        />
        {/* {errors.venueView && <p className="text-red-500 pt-1">{errors.venueView}</p>} */}
        {formData.additionalDetails?.errors?.venueView && (
          <p className="text-red-500 pt-1">
            {formData.additionalDetails.errors.venueView}
          </p>
        )}
      </div>

      <div className="w-full">
        <label className="block text-secondary text-[18px] font-bold mt-10 mb-1">
          Venue Setting <span className="text-red-500 ml-1">*</span>
        </label>
        <SelectableTags
          max={5}
          label=""
          tags={venueSitting}
          selectedTags={additionalDetails.venueSetting}
          onTagClick={(tag) => handleTagClick("venueSetting", tag)}
        />
        {formData.additionalDetails?.errors?.venueSetting && (
          <p className="text-red-500 pt-1">
            {formData.additionalDetails.errors.venueSetting}
          </p>
        )}
      </div>

      <div className="mb-4 w-full">
        <label className="block text-darkGray text-xl font-bold mt-11 mb-2">
          Venue Style <span className="text-red-500 ml-1">*</span>
          <span className="px-1 text-[#433f40] text-sm font-normal">
            {" "}
            (select up to top 5){" "}
          </span>
        </label>
        {Object.keys(VenueStyles).map((styleCategory) => (
          <div key={styleCategory}>
            <h3 className="mt-6 mb-2 text-secondary text-[18px] font-semibold capitalize">
              {styleCategory === "culturalRegionalStyle"
                ? "Cultural/Regional Style"
                : styleCategory === "modernContemporaryStyle"
                ? "Modern/Contemporary Style"
                : styleCategory.replace(/([A-Z])/g, " $1").trim()}
            </h3>
            <SelectableTags
              max={5}
              tags={VenueStyles[styleCategory]}
              selectedTags={additionalDetails.venueStyle[styleCategory]}
              onTagClick={(tag) => handleStyleTagClick(styleCategory, tag)}
            />
          </div>
        ))}
        {formData?.additionalDetails?.errors?.venueStyle && (
          <p className="text-red-500 pt-1">
            {formData.additionalDetails.errors.venueStyle}
          </p>
        )}
      </div>

      <div className="w-full mb-4">
        <div className="flex items-center gap-4 mt-11  mb-2">
          <label className="block text-black font-bold text-xl">
            Venue hire <span className="text-red-500 ml-1 text-md">*</span>{" "}
            (Select any one){" "}
          </label>

          <Tooltip
            title="Choose the appropriate space type to clearly inform clients about the level of privacy and access your venue offers. This helps clients find the best match for their event requirements, ensuring smoother communication and expectations."
            arrow
          >
            <img src="/Info.svg" width={24} height={24} />
          </Tooltip>
        </div>
        {/* <div className="flex items-center gap-4  mb-2">
          <label className="block text-secondary font-medium">
            Venue hire <span className="text-red-500 ml-1">*</span> (Select any
            one){" "}
          </label>
          <CloseableToolTip
            tooltipText={
              "Choose the appropriate space type to clearly inform clients about the level of privacy and access your venue offers. This helps clients find the best match for their event requirements, ensuring smoother communication and expectations"
            }
            bgColor="bg-black"
            textColor="text-white"
            borderColor="#4a4a4a"
          >
            <img src="/Info.svg" width={24} height={24} />

          </CloseableToolTip>
        </div> */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {eventTypes?.map((data, index) => {
            // Determine height class based on index
            const customHeightClass =
              index === 0 || index === 1 ? "h-[130px]" : "h-[178px]";
            return (
              <CustomRadioBox
                key={data.id}
                icon={data.icon}
                title={data.title}
                description={data.description}
                checked={formData?.additionalDetails?.venueHire === data.title}
                selectedId={selectedId}
                onSelect={() => handleEventTypeClick(data.id, data.title)}
                id={data.id}
                customHeightClass={customHeightClass} // Pass height class to the component
              />
            );
          })}
        </div>
        {formData.additionalDetails.errors?.venueHire && (
          <p className="text-red-500 pt-1">
            {formData?.additionalDetails?.errors?.venueHire}
          </p>
        )}
      </div>
    </div>
  );
};

export default AdditionalDetailsForm;
