// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import { Box, IconButton } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// function TextfieldNumber({ value, onChange }) {
//   const theme = useTheme();
//   const [isFocused, setIsFocused] = useState(false);

//   const handleIncrement = () => {
//     const newValue = Math.min(9999, Math.max(0, Number(value) + 1));
//     onChange({ target: { value: newValue } });
//   };

//   const handleDecrement = () => {
//     onChange({ target: { value: Math.max(0, Number(value) - 1) } });
//   };

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       event.preventDefault(); // Prevent Enter key default action
//     }
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         paddingLeft: "1rem",
//         border: `2px solid ${isFocused ? theme.palette.primary.main : theme.palette.primary.lighter
//           }`,
//         borderRadius: 12,
//       }}
//       onClick={() => setIsFocused(true)}
//       onBlur={() => setIsFocused(false)}
//       tabIndex={-1} // To make div focusable
//     >
//       <input
//         placeholder="00"
//         min={0}
//         max={9999}
//         value={value}
//         onChange={onChange}
//         type="number"
//         style={{
//           width: "100%",
//           border: "none",
//           outline: "none",
//         }}
//         onFocus={() => setIsFocused(true)}
//         onBlur={() => setIsFocused(false)}
//         onKeyDown={handleKeyDown} // Add the keydown event handler here
//       />
//       <Box sx={{ display: "flex", flexDirection: "column" }}>
//         <IconButton
//           sx={{
//             borderRadius: 0,
//             padding: 0,
//             color: theme.palette.primary.main,
//             backgroundColor: theme.palette.primary.lighter,
//             borderTopRightRadius: 12,
//           }}
//           onClick={handleIncrement}
//         >
//           <ExpandLessIcon />
//         </IconButton>
//         <IconButton
//           sx={{
//             borderRadius: 0,
//             padding: 0,
//             color: theme.palette.primary.main,
//             backgroundColor: theme.palette.primary.lighter,
//             borderBottomRightRadius: 12,
//           }}
//           onClick={handleDecrement}
//         >
//           <ExpandMoreIcon />
//         </IconButton>
//       </Box>
//     </div>
//   );
// }

// TextfieldNumber.propTypes = {
//   value: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
// };

// export default TextfieldNumber;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function TextfieldNumber({ value, onChange, min = 0, max = 99999 }) {
	const theme = useTheme();
	const [isFocused, setIsFocused] = useState(false);

	const handleIncrement = () => {
		const currentValue = Number(value) || 0; // Handle empty or invalid value
		const newValue = Math.min(max, currentValue + 1);
    console.log(newValue);
		// Mimic event structure and call onChange
		onChange({ target: { value: newValue } });
	};

	const handleDecrement = () => {

		const currentValue = Number(value) || 0; // Handle empty or invalid value
		const newValue = Math.max(min, currentValue - 1);

		// Mimic event structure and call onChange
		onChange({ target: { value: newValue } });
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Prevent form submission or other default behavior
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				pl: 0,
				border: `2px solid ${
					isFocused
						? theme.palette.primary.main
						: theme.palette.primary.lighter
				}`,
				borderRadius: 2,
				alignItems: 'center',
				':focus-within': { borderColor: theme.palette.primary.main },
			}}
			onClick={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
			tabIndex={-1} // To make div focusable
		>
			<input
				placeholder='00'
				min={min}
				className=''
				max={max}
				value={value}
				onChange={onChange}
				type='number'
				style={{
					minWidth: '60px',
					width: '100%',
					border: 'none',
					outline: 'none',
					padding: '8px',
				}}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				onKeyDown={handleKeyDown} // Handle Enter key
			/>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<IconButton
					sx={{
						borderRadius: 0,
						p: 0,
						color: theme.palette.primary.main,
						backgroundColor: theme.palette.primary.lighter,
						borderTopRightRadius: 2,
					}}
					onClick={handleIncrement}
				>
					<ExpandLessIcon />
				</IconButton>
				<IconButton
					sx={{
						borderRadius: 0,
						p: 0,
						color: theme.palette.primary.main,
						backgroundColor: theme.palette.primary.lighter,
						borderBottomRightRadius: 2,
					}}
					onClick={handleDecrement}
				>
					<ExpandMoreIcon />
				</IconButton>
			</Box>
		</Box>
	);
}

TextfieldNumber.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
};

export default TextfieldNumber;
