import React from "react";
import img1 from "../../assets/pics/work1.png";
import img2 from "../../assets/pics/work2.png";
import img3 from "../../assets/pics/work3.png";
import rope from "../../assets/pics/workRope.svg";
import { BsArrowRight } from "react-icons/bs";

const HowWorks = () => {
  const data = [
    {
      img: img1,
      step: "Step 1",
      name: "Browse Venues",
      des: "Discover perfect venue for your events.",
    },
    {
      img: img2,
      step: "Step 2",
      name: "Compare Venues",
      des: "Compare & select venues that are most suitable for your events.",
    },
    {
      img: img3,
      step: "Step 3",
      name: "Send Enquiries",
      des: "Send Inquiries to venue owners, including your specific event date and time, to request a personalized quote.",
    },
  ];

  return (
    <div className="flex flex-col items-center pt-16 sm:px-32 px-0">
      <div className="flex flex-col items-center z-[100px]">
        <h1 className="text-2xl font-semibold">How it works</h1>
        <p className="mt-2 text-[#626262]">
          Find your ideal venue in three simple steps
        </p>
      </div>
      <div className="relative flex justify-center z-[10px]">
        <div className="grid md:grid-cols-3 grid-cols-1  gap-8 mt-8 md:w-[95%] w-full ">
          {data.map((e, i) => (
            <div key={i} className="flex flex-col items-center gap-6">
              <img src={e.img} alt="" className="h-[200px] w-[200px]" />
              <h1 className="text-[#7B7B7B] font-semibold">{e.step}</h1>
              <h1 className="text-[#252525] text-center font-semibold">
                {e.name}
              </h1>
              <div className="text-[#5B5B5B] text-center px-[80px]">
                {e.des}
              </div>
            </div>
          ))}
        </div>
        <img
          src={rope}
          className="lg:block hidden absolute top-0 left-0 "
          alt=""
        />
      </div>
      <div className="flex flex-col items-center gap-3 my-10">
        <h1 className="text-lg text-center">Learn more about our process</h1>
        <button className="text-white flex bg-black py-3 items-center justify-center rounded-lg  gap-2 w-[170px]">
          Watch Video <BsArrowRight />
        </button>
      </div>
    </div>
  );
};

export default HowWorks;
