import React, { useContext, useEffect, useState } from "react";
import { HotelContext } from "../../context/HotelContext";
import { useParams } from "react-router-dom";

const Stepper = ({
  currentStep,
  currentSubStep,
  setCurrentStep,
  setCurrentSubStep,
}) => {
  // Initialize state for steps
  const [steps, setSteps] = useState([
    {
      title: "Overview",
      errorIcon: false,
      subSteps: ["Basic Details", "Additional Details", "Location"],
    },
    { title: "Accommodation", errorIcon: false, subSteps: [] },
    { title: "Pricing", errorIcon: false, subSteps: [] },
    { title: "Offers & Packages", errorIcon: false, subSteps: [] },
    {
      title: "Amenities",
      errorIcon: false,
      subSteps: ["Room Features", "Business Features", "Recreational Features"],
    },
    { title: "Photos & Videos", errorIcon: false, subSteps: [] },
    { title: "Preview", errorIcon: false, subSteps: [] },
  ]);

  const { id } = useParams(); // Access the dynamic id from the URL
  console.log("Hostel ID: ", id);

  // If id exists, set the currentStep to a specific step (for example, step 3)
  useEffect(() => {
    if (id) {
      // console.log("Hostel ID is present, moving to step: ", id);
      // Set the step you want based on the id
      console.log(currentStep);
      if (currentStep !== parseInt(6)) {
        setCurrentStep(parseInt(0)); // Ensure it's a valid number
      }
    }
  }, [id, setCurrentStep]);

  const { formData, setFormData } = useContext(HotelContext);

  useEffect(() => {
    // Create a copy of the steps state
    const updatedSteps = [...steps];

    const updateErrorState = (stepIndex, hasError) => {
      updatedSteps[stepIndex] = {
        ...updatedSteps[stepIndex],
        errorIcon: hasError,
      };
    };

    // Validate Overview step
    const validateOverview = () => {
      const basicDetails = formData?.overview?.basicDetails || {};
      const additionalDetails = formData?.overview?.additionalDetails || {};
      return (
        !basicDetails?.hotelName ||
        !basicDetails?.hotelRating ||
        !basicDetails?.hotelWebsite ||
        !basicDetails?.hotelDescription ||
        !additionalDetails?.hotelBuiltYear ||
        !additionalDetails?.tradeLicenseFile
      );
    };

    // Validate Accommodation step
    const validateAccommodation = () => {
      const accommodation = formData?.accommodation || {};
      return (
        !accommodation?.accommodations && accommodation?.accommodations !== 0
      );
    };

    // Validate Pricing step
    const validatePricing = () => {
      const pricing = formData?.pricing || {};
      return !pricing?.basePrice;
    };

    // Validate Photos & Videos step
    const validatePhotosAndVideos = () => {
      const photoVideo = formData?.photoVideo || {};
      return !photoVideo?.images?.[0];
    };

    // Use deep comparison for state updates to avoid unnecessary re-renders
    const updateFormDataWithErrors = (field, hasError) => {
      if (formData?.errors?.[field] !== hasError) {
        setFormData((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field]: hasError },
        }));
      }
    };

    // Update error states based on current step and sub-step
    if (currentStep >= 0) {
      const overviewError = validateOverview();
      updateErrorState(0, overviewError);
      updateFormDataWithErrors("overview", overviewError);
    }

    if (currentStep >= 1) {
      const accommodationError = validateAccommodation();
      updateErrorState(1, accommodationError);
      updateFormDataWithErrors("accommodation", accommodationError);
    }

    if (currentStep >= 2) {
      const pricingError = validatePricing();
      updateErrorState(2, pricingError);
      updateFormDataWithErrors("pricing", pricingError);
    }

    if (currentStep >= 5) {
      const photoVideoError = validatePhotosAndVideos();
      updateErrorState(5, photoVideoError);
      updateFormDataWithErrors("photoVideo", photoVideoError);
    }

    setSteps(updatedSteps);
  }, [currentStep, currentSubStep, formData]);

  const showTickMark = (stepIndex) => {
    return stepIndex < currentStep && !steps[stepIndex].errorIcon;
  };

  const showExclamationMark = (stepIndex) => {
    return stepIndex < currentStep && steps[stepIndex].errorIcon;
  };

  const getStepIndicatorClass = (stepIndex) => {
    if (stepIndex < currentStep) {
      return steps[stepIndex].errorIcon ? "bg-[#FFF6E6]" : "bg-green1";
    }
    return "bg-transparent";
  };

  return (
    <div className="w-full bg-white p-4">
      <ul className="relative lg:space-y-3 flex lg:block overflow-x-auto">
        {steps.map((step, index) => (
          <div key={index}>
            <li
              id={`step-${index}`} // Assigning a unique ID to each step
              className={`flex lg:items-center lg:flex-row flex-col font-semibold cursor-pointer relative mb-10 min-w-32 ${
                currentStep === index || Math.floor(currentStep) === index
                  ? "text-[#039f8d]"
                  : "text-[#c9c9c9]"
              }`}
              onClick={() => setCurrentStep(index)}
            >
              <div className="flex items-center">
                <span
                  className={`w-7 h-7 flex items-center justify-center font-semibold rounded-full border-2 ${
                    currentStep === index || Math.floor(currentStep) === index
                      ? "border-green1"
                      : "border-lightGray"
                  } ${getStepIndicatorClass(index)}`}
                >
                  {showTickMark(index) && (
                    <svg
                      className="w-4 h-4 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  )}
                  {showExclamationMark(index) && (
                    <svg
                      className="w-4 h-4 text-yellow-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M4.93 4.93l14.14 14.14M4.93 19.07l14.14-14.14"
                      ></path>
                    </svg>
                  )}
                  {!showTickMark(index) && !showExclamationMark(index) && (
                    <span
                      className={`w-2 h-2 rounded-full ${
                        currentStep === index ||
                        Math.floor(currentStep) === index
                          ? "bg-green1"
                          : "bg-lightGray"
                      }`}
                    ></span>
                  )}
                </span>
                {index !== steps.length - 1 && (
                  <span
                    className={`lg:min-h-full min-h-[2px] absolute lg:left-0 left-4 font-semibold lg:top-8 top-3 lg:min-w-[2px] min-w-[100px] ${
                      currentStep > index || Math.floor(currentStep) === index
                        ? "bg-green1"
                        : "bg-lightGray"
                    } ml-3`}
                  ></span>
                )}
              </div>
              <span
                className={`lg:ml-3 text-xs lg:text-sm font-semibold ${
                  currentStep >= index ? "text-black" : "border-lightGray"
                }`}
              >
                {step.title}
              </span>
            </li>
            {step?.subSteps?.length > 0 &&
              Math.floor(currentStep) === index && (
                <ul className="lg:ml-8">
                  {step?.subSteps?.map((subStep, subIndex) => (
                    <li
                      key={subIndex}
                      className={`flex relative items-center cursor-pointer font-semibold mb-2 ${
                        currentSubStep === subIndex
                          ? "text-green1"
                          : "text-[#c9c9c9]"
                      }`}
                      onClick={() => setCurrentSubStep(subIndex)}
                    >
                      {subIndex !== step?.subSteps?.length && (
                        <span
                          className={`min-h-6 rounded-md min-w-[2px] font-semibold ${
                            currentStep > index + subIndex / 10
                              ? "bg-green1"
                              : "bg-[#c9c9c9]"
                          } ml-3`}
                        ></span>
                      )}
                      <span
                        className={`ml-3 font-semibold ${
                          currentStep > index + subIndex / 10
                            ? "text-black"
                            : "border-[#c9c9c9]"
                        }`}
                      >
                        {subStep}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Stepper;
