import React, { useState } from 'react';

// facility icons
import s1 from '../../assets/icons/facilities/1.svg';
import s2 from '../../assets/icons/facilities/2.svg';
import s3 from '../../assets/icons/facilities/3.svg';
import s4 from '../../assets/icons/facilities/4.svg';
import s5 from '../../assets/icons/facilities/5.svg';
import s6 from '../../assets/icons/facilities/6.svg';
import s7 from '../../assets/icons/facilities/7.svg';
import s8 from '../../assets/icons/facilities/8.svg';
import s9 from '../../assets/icons/facilities/9.svg';

// equipment icons
import BanquetchairsImage from '../../assets/icons/equipments/Banquetchairs.png';
import CocktailtablesImage from '../../assets/icons/equipments/Cocktailtables.png';
import BarImage from '../../assets/icons/equipments/bar.png';
import StagedecksImage from '../../assets/icons/equipments/Stagedecks.png';
import DancefloorImage from '../../assets/icons/equipments/Dancefloor.png';
import StoragespaceImage from '../../assets/icons/equipments/Storagespace.png';

// audio svg
import clientsCanBringOwnDJImage from '../../assets/icons/audio/clientsCanPlayOwnMusic.svg';
import clientsCanPlayOwnMusic from '../../assets/icons/audio/clientsCanPlayOwnMusic.svg';
import Indoormusicallowed from '../../assets/icons/audio/Indoormusicallowed.svg';
import outdoorMusicAllowed from '../../assets/icons/audio/outdoorMusicAllowed.svg';
import paSystemSpeakers from '../../assets/icons/audio/paSystemSpeakers.svg';
import spaceHasNoiseRestrictions from '../../assets/icons/audio/spaceHasNoiseRestrictions.svg';

import * as FaIcons from 'react-icons/fa';
import { IoCheckmark } from 'react-icons/io5';
import p1 from '../../assets/parking.svg';
import p2 from '../../assets/Valet.svg';

const featuresData = {
	facilities: [
		{ icon: s1, text: 'Natural Light' },
		{ icon: s3, text: 'Wifi' },
		{ icon: s5, text: 'Air conditioning' },
		{ icon: s7, text: 'Podium' },
		{ icon: s9, text: 'Whiteboard' },
		{ icon: s2, text: 'Flatscreen TV' },
		{ icon: s4, text: 'Flipchart' },
		{ icon: s6, text: 'Storage space' },
		{ icon: s8, text: 'Conference call facilities' },
	],
	// facilities: [
	//   { icon: <FaRegLightbulb />, text: "Natural Light" },
	//   { icon: <FaWifi />, text: "Wifi" },
	//   { icon: <MdAir />, text: "Air conditioning" },
	//   { icon: <GiPodium />, text: "Podium" },
	//   { icon: <FaChalkboard />, text: "Whiteboard" },
	//   { icon: <FaTv />, text: "Flatscreen TV" },
	//   { icon: <MdOutlineScreenShare />, text: "Flipchart" },
	//   { icon: <RiReservedLine />, text: "Storage space" },
	//   { icon: <HiOutlineLibrary />, text: "Conference call facilities" },
	// ],
	equipment: [
		{ text: 'Banquet chairs', icon: BanquetchairsImage },
		{ text: 'Cocktail tables', icon: CocktailtablesImage },
		{ text: 'Bar', icon: BarImage },
		{ text: 'Stage decks', icon: StagedecksImage },
		{ text: 'Storage space', icon: StoragespaceImage },
		{ text: 'Dance floor', icon: DancefloorImage },
	],
	// equipment: [
	//   { icon: <BiChair />, text: "Banquet chairs" },
	//   { icon: <FaChild />, text: "Cocktail tables" },
	//   { icon: <FaSnowflake />, text: "Stage deck" },
	//   { icon: <RiReservedLine />, text: "Storage space" },
	//   { icon: <GiTheaterCurtains />, text: "Dance floor" },
	//   { text: "Bar", icon: <FaGlassMartiniAlt /> },
	// ],
	audio: [
		{
			icon: Indoormusicallowed,
			text: 'Indoor music allowed until 10:00PM',
			variable: 'indoorMusicAllowed',
		},
		{
			icon: outdoorMusicAllowed,
			text: 'Outdoor music allowed until 10:00PM',
			variable: 'outdoorMusicAllowed',
		},
		{
			icon: clientsCanPlayOwnMusic,
			text: 'Clients can play their own music',
			variable: 'clientsCanPlayOwnMusic',
		},
		{
			icon: paSystemSpeakers,
			text: 'PA system / Speakers',
			variable: 'paSystemSpeakers',
		},
		{
			icon: spaceHasNoiseRestrictions,
			text: 'Space has noise restrictions',
			variable: 'spaceHasNoiseRestrictions',
		},
		{
			icon: clientsCanBringOwnDJImage,
			text: 'Clients can bring their own DJ',
			variable: 'clientsCanBringOwnDJ',
		},
	],
};

const Section = ({ title, items, isCheckIcon }) => {
	const [showAll, setShowAll] = useState(false);

	const toggleShowMore = () => setShowAll(!showAll);

	const displayedItems = showAll ? items : items.slice(0, 4);

	return (
		<div className='py-6 flex flex-wrap w-full border-t border-gray-300 justify-between'>
			<h3 className='text-xl font-semibold mb-2'>{title}</h3>
			<div className='grid lg:w-3/4 w-full grid-cols-1 sm:grid-cols-2 gap-4'>
				{displayedItems.map((item, index) => (
					<div key={index} className='flex items-center'>
						{isCheckIcon ? (
							<IoCheckmark className='text-gray-500 text-2xl mr-2' />
						) : (
							<div className='text-2xl mr-2'>{item.icon}</div>
						)}
						<span className='text-gray-700 capitalize'>
							{isCheckIcon ? item : item.text}
						</span>
					</div>
				))}
				{items.length > 4 && ( // Show button only if there are more than 4 items
					<button
						onClick={toggleShowMore}
						className='text-[#FE4747] underline flex justify-start sm:col-span-2'
					>
						{showAll ? 'Show Less' : 'Show More'}
					</button>
				)}
			</div>
		</div>
	);
};

const Facilities = ({ data }) => {
	// const providedFacilities = data?.facilities?.facilities || [];
	// const providedEquipment = data?.equipment?.equipment || [];
	const providedAudio = data?.audio || {};
	// const audioFeatures = [
	// 	providedAudio.indoorMusicAllowed
	// 		? `Indoor music allowed until ${providedAudio.indoorMusicAllowed}`
	// 		: null,
	// 	providedAudio.outdoorMusicAllowed
	// 		? 'Outdoor music allowed until 10:00PM'
	// 		: null,
	// 	providedAudio.clientsCanPlayOwnMusic
	// 		? 'Clients can play their own music'
	// 		: null,
	// 	providedAudio.paSystemSpeakers ? 'PA system / Speakers' : null,
	// 	providedAudio.spaceHasNoiseRestrictions
	// 		? 'Space has noise restrictions'
	// 		: null,
	// 	providedAudio.clientsCanBringOwnDJ
	// 		? 'Clients can play their own DJ'
	// 		: null,
	// ].filter(Boolean);

	// const filterFeatures = (featureSet, providedSet) => {
	// 	// Ensure providedSet is an array
	// 	const providedArray = Array.isArray(providedSet)
	// 		? providedSet
	// 		: [];
	// 	return featureSet?.filter(
	// 		(feature) =>
	// 			!providedArray.some((provided) => provided === feature.text)
	// 	);
	// };
	// Utility function to convert time to 12-hour format
	const formatTimeTo12Hour = (timeString) => {
		const [hour, minute] = timeString.split(':');
		const hourInt = parseInt(hour, 10);
		const period = hourInt >= 12 ? 'PM' : 'AM';
		const formattedHour = hourInt % 12 || 12; // Converts 0 to 12 for midnight, and handles 12-hour conversion
		return `${formattedHour}:${minute} ${period}`;
	};

	const getAudioItems = () => {
		const items = [];

		if (providedAudio?.indoorMusicAllowed) {
			items.push({
				icon: (
					<img
						src={Indoormusicallowed}
						alt='Indoor music allowed'
						className='w-6 h-6 mr-2'
					/>
				),
				text: `Indoor music allowed until ${formatTimeTo12Hour(
					providedAudio?.indoorMusicAllowedTime
				)}`,
			});
		}

		if (providedAudio?.outdoorMusicAllowed) {
			items.push({
				icon: (
					<img
						src={outdoorMusicAllowed}
						alt='Outdoor music allowed'
						className='w-6 h-6 mr-2'
					/>
				),
				text: `Outdoor music allowed until ${formatTimeTo12Hour(
					providedAudio?.outdoorMusicAllowedTime
				)}`,
			});
		}

		if (providedAudio?.clientsCanPlayOwnMusic) {
			items.push({
				icon: (
					<img
						src={clientsCanPlayOwnMusic}
						alt='Clients can play own music'
						className='w-6 h-6 mr-2'
					/>
				),
				text: 'Clients can play their own music',
			});
		}

		if (providedAudio?.paSystemSpeakers) {
			items.push({
				icon: (
					<img
						src={paSystemSpeakers}
						alt='PA system / Speakers'
						className='w-6 h-6 mr-2'
					/>
				),
				text: 'PA system / Speakers',
			});
		}

		if (providedAudio?.spaceHasNoiseRestrictions) {
			items.push({
				icon: (
					<img
						src={spaceHasNoiseRestrictions}
						alt='Noise restrictions'
						className='w-6 h-6 mr-2'
					/>
				),
				text: 'Space has noise restrictions',
			});
		}

		if (providedAudio?.clientsCanBringOwnDJ) {
			items.push({
				icon: (
					<img
						src={clientsCanBringOwnDJImage}
						alt='Clients can bring DJ'
						className='w-6 h-6 mr-2'
					/>
				),
				text: 'Clients can bring their own DJ',
			});
		}

		return items;
	};

	const getFacilities = () => {
		// Check if facilities are present and have entries
		if (data?.facilities?.facilities) {
			// Map over the facilities keys
			const result = Object.keys(data?.facilities?.facilities).map(
				(key) => {
					// Determine the icon for specific facilities
					let icon = null;

					// Match facility keys to corresponding SVG icons
					if (key === 'Natural Light') {
						icon = s1;
					} else if (key === 'Wifi') {
						icon = s3;
					} else if (key === 'Air conditioning') {
						icon = s5;
					} else if (key === 'Podium') {
						icon = s7;
					} else if (key === 'Whiteboard') {
						icon = s9;
					} else if (key === 'Flatscreen TV') {
						icon = s2;
					} else if (key === 'Flipchart') {
						icon = s4;
					} else if (key === 'Storage space') {
						icon = s6;
					} else if (key === 'Conference call facilities') {
						icon = s8;
					} else {
						// Use a React component from the FaIcons library for other cases
						const IconComponent =
							FaIcons[data?.facilities?.facilitiesFeatureIcons[key]];
						icon = IconComponent ? (
							<IconComponent className='text-2xl mr-2' />
						) : null;
					}

					// Return an object with the icon and text only if the facility is true
					if (data?.facilities?.facilities[key]) {
						return {
							icon:
								typeof icon === 'string' ? (
									<img
										src={icon}
										alt={key}
										className='w-6 h-6 mr-2'
									/>
								) : (
									icon
								),
							text: key,
						};
					}
				}
			);

			return result?.filter((e) => e); // Filter out undefined values
		}

		return [];
	};

	// getEquipmnets
	const getEquipments = () => {
		// Check if equipment data is present and has entries
		if (data?.equipment?.equipment) {
			// Map over the equipment keys
			const result = Object.keys(data?.equipment?.equipment).map(
				(key) => {
					// Find if the key exists in featuresData equipment
					const defaultEquipment = featuresData?.equipment?.find(
						(equipment) => equipment.text === key
					);

					// Return an object with the image and text only if the equipment is available
					if (data?.equipment?.equipment[key]) {
						return {
							icon: defaultEquipment?.icon ? (
								<img
									src={defaultEquipment.icon}
									alt={key}
									className='w-6 h-6 mr-2'
								/>
							) : null,
							text: key,
						};
					}
				}
			);

			return result?.filter((e) => e); // Filter out undefined values
		}

		return [];
	};

	// const getEquipments = () => {
	//   console.log("data in equipments", data?.equipment?.equipment);
	//   // Check if facilities are present and have entries
	//   if (data?.equipment?.equipment) {
	//     // Map over the facilities keys
	//     const result = Object.keys(data?.equipment?.equipment).map((key) => {
	//       // Determine the icon for specific facilities
	//       let icon = null;
	//       if (key === "Flatscreen TV") {
	//         icon = <FaTv />;
	//       } else {
	//         // Find if the key exists in defaultFacilities
	//         const defaultFacility = featuresData?.equipment?.find(
	//           (facility) => facility.text === key
	//         );
	//         if (defaultFacility) {
	//           icon = defaultFacility.icon;
	//         }
	//       }

	//       // Return an object with the icon and text only if the facility is true
	//       if (data?.equipment?.equipment[key]) {
	//         return {
	//           icon: icon || null,
	//           text: key,
	//         };
	//       }
	//     });

	//     console.log("result is", result);

	//     return result?.filter((e) => e); // Filter out undefined values
	//   }

	//   return [];
	// };

	const filteredFacilities = getFacilities();

	const filteredAudio = getAudioItems();
	const filteredEquipment = getEquipments();
	return (
		<div className='rounded-lg mb-8 lg:w-3/4 w-full px-5 space-y-5 mx-auto py-10'>
			{filteredFacilities?.length > 0 && (
				<Section title='Facilities' items={filteredFacilities} />
			)}
			{filteredEquipment?.length > 0 && (
				<Section title='Equipment' items={filteredEquipment} />
			)}
			{!data?.facilities?.activitiesForKidsIncludes.length === 0 && (
				<Section
					title='Activities for kids'
					items={data?.facilities?.activitiesForKidsIncludes || []}
					isCheckIcon
				/>
			)}
			{filteredAudio?.length > 0 && (
				<Section title='Audio' items={filteredAudio} />
			)}
			{/* {!filteredAudio?.length == 0 && (
        <Section title="Audio" items={filteredAudio} />
      )} */}

			<div className=''>
				<div className='flex justify-between mb-8'>
					{/* parking section */}
					{(data?.location?.parkingAvailable ||
						data?.location?.valetParking) && (
						<div className='py-6 border-t border-gray-300 flex flex-wrap w-full justify-between'>
							<h2 className='text-xl font-semibold mb-4'>Parking</h2>
							<div className='flex lg:w-3/4 w-full items-center space-x-4'>
								{data?.location?.parkingAvailable && (
									<div className='flex items-center space-x-1'>
										<img src={p1} alt='' className='w-6' />
										<span>Parking available</span>
									</div>
								)}
								{data?.location?.valetParking && (
									<div className='flex items-center space-x-1'>
										<img src={p2} alt='' className='w-6' />
										<span>Valet Parking</span>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
				<div className='mb-6 flex flex-wrap w-full py-6 border-t border-gray-300 justify-between'>
					<h2 className='text-xl font-semibold '>Space Rules</h2>
					<div className='mb-4 lg:w-3/4 w-full'>
						<div className='flex justify-between'>
							<div>
								<h3 className='font-semibold'>Age Policy</h3>
								<p>
									{data?.spaceRules?.agePolicy
										? `${data.spaceRules.agePolicy.join('-')} years`
										: 'N/A'}
								</p>
							</div>
							<div>
								<h3 className='font-semibold'>
									Ticketed events allowed?
								</h3>
								<p>
									{data?.spaceRules?.ticketedEventsAllowed
										? 'Yes'
										: 'No'}
								</p>
							</div>
						</div>
						<div>
							<h3 className='font-semibold py-2'>More space rules</h3>
							<ul className='list-disc list-inside'>
								{data?.spaceRules?.moreSpaceRules ? (
									data.spaceRules.moreSpaceRules
										.split('\n')
										.map((rule, index) => <li key={index}>{rule}</li>)
								) : (
									<li>No additional rules</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Facilities;
