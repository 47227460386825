/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { ReactComponent as BedSVG } from "../../assets/icons/bed_hotel_listing.svg";
import { ReactComponent as HouseSVG } from "../../assets/icons/house_hotel_listing.svg";
import { HotelContext } from "../../context/HotelContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import RatingStars from "../RatingStars/RatingStars";

const CardPreview = () => {
  const { formData } = useContext(HotelContext);
  // const [isLiked, setIsLiked] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = formData?.photoVideo?.images || [];

  // Fallback image in case no image is provided
  const imageSrc =
    formData.photoVideo.images.length > 0 ? formData.photoVideo.images : "";
  // Destructure formData to simplify access
  const basicDetails = formData.overview?.basicDetails;
  // console.log(basicDetails, "details");
  const hotelName = basicDetails?.hotelName || "Hotel Name Not Available";
  const hotelRating = basicDetails?.hotelRating || "0";
  const hotelDescription =
    basicDetails?.hotelDescription || "Description Not Available";
  const location =
    formData.overview?.locationDetails?.city +
      ", " +
      formData.overview?.locationDetails?.area || "Location Not Available";
  const rooms = formData.accommodation?.accommodations || "0 Rooms";
  const venues = formData.accommodation?.venues || "0 Venues";
  const price = formData.pricing?.basePrice || "0 AED";
  const navigate = useNavigate();

  const handlePreviewClick = () => {
    let errorMessage = "";
    let hasErrors = false;
    // Check for errors in each step
    if (formData.errors.overview) {
      errorMessage += "Data is missing in Overview.\n";
      hasErrors = true;
    }
    if (formData.errors.accommodation) {
      errorMessage += "Data is missing in Accommodation.\n";
      hasErrors = true;
    }
    if (formData.errors.pricing) {
      errorMessage += "Data is missing in Pricing.\n";
      hasErrors = true;
    }
    if (formData.errors.photoVideo) {
      errorMessage += "Data is missing in Photos & Videos.\n";
      hasErrors = true;
    }
    if (hasErrors) {
      toast.error(errorMessage.trim());
    }
    // localStorage.setItem('hotelData', JSON.stringify(formData));
    navigate("/hotel-preview");
  };

  // const handleLikeClick = () => {
  //   setIsLiked(!isLiked);
  // };

  const CustomSkeleton = () => (
    <div className="w-full h-[300px] lg:w-[300px] rounded-lg bg-gray-200 animate-pulse"></div>
  );

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
  };
  return (
    <>
      <div className="bg-white rounded-lg w-full max-w-md ">
        <h2 className="text-3xl font-bold mb-6">Card Preview</h2>
        <div className="rounded-lg overflow-hidden shadow-lg mb-4 lg:w-[300px]">
          <div className="relative rounded-lg overflow-hidden shadow-lg mb-4 lg:w-[300px]">
            {images.length > 0 ? (
              <>
                {/* Image Slide */}
                <div className="w-full h-[190px] rounded-t-lg">
                  <img
                    src={images[currentSlide]}
                    alt={`Slide ${currentSlide}`}
                    className="w-full h-full object-contain"
                  />
                </div>

                {/* Custom Prev Arrow */}
                <div
                  className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50"
                  onClick={handlePrev}
                >
                  <SlArrowLeft className="text-[#000]" />
                </div>

                {/* Custom Next Arrow */}
                <div
                  className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50"
                  onClick={handleNext}
                >
                  <SlArrowRight className="text-[#000]" />
                </div>
              </>
            ) : (
              <CustomSkeleton />
            )}
          </div>
          <div className="p-4">
            <h3 className="text-xl font-semibold">{hotelName}</h3>
            <div className="my-2">
              <RatingStars rating={parseFloat(hotelRating)} />
            </div>
            <p className="text-gray-600">{location}</p>
            <div className="flex items-center text-gray-600 my-2">
              <BedSVG className="mr-1 text-red-500" />
              <span className="mr-3">{rooms}</span>
              <HouseSVG className="mr-1 text-red-500" />
              <span>{venues}</span>
            </div>
            <p className="text-red-500 font-semibold">AED {price}</p>
            <p className="text-sm text-gray-500">
              Starting price per room per night
            </p>
          </div>
        </div>
      </div>
      <div className="text-sm text-gray-500 flex items-center mb-4 w-full">
        <FaInfoCircle className="mr-2" />
        Your venues currently show as zero. Once you add venues, this will be
        updated
      </div>
    </>
  );
};

export default CardPreview;
