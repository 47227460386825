export const chipLabelsWithCategories = [
  { venue: "Restaurants, Cafe's & Bar", price: 1000, category: "Restaurants, Cafe's & Bar" },
  { venue: "Kids play area", price: 1000, category: "Kids Venues" },
  { venue: "Therapy room", price: 1000, category: "Wellness Venue" },
  { venue: "Yoga Studio", price: 1000, category: "Wellness Venue" },
  { venue: "Spa", price: 1000, category: "Wellness Venue" },
  { venue: "Beach", price: 1000, category: "Outdoor Venues" },
  { venue: "Desert", price: 1000, category: "Outdoor Venues" },
  { venue: "Farm", price: 1000, category: "Outdoor Venues" },
  { venue: "Helipad", price: 1000, category: "Outdoor Venues" },
  { venue: "Lawn", price: 1000, category: "Outdoor Venues" },
  { venue: "Park", price: 1000, category: "Outdoor Venues" },
  { venue: "Boats & Yachts", price: 1000, category: "Outdoor Venues" },
  { venue: "Terrace", price: 1000, category: "Outdoor Venues" },
  { venue: "Patio", price: 1000, category: "Outdoor Venues" },
  { venue: "Garden", price: 1000, category: "Outdoor Venues" },
  { venue: "Poolside", price: 1000, category: "Outdoor Venues" },
  { venue: "Courtyard", price: 1000, category: "Outdoor Venues" },
  { venue: "Adventure venue", price: 1000, category: "Adventure & Activity" },
  { venue: "Escape Rooms", price: 1000, category: "Adventure & Activity" },
  { venue: "Golf courses", price: 1000, category: "Adventure & Activity" },
  { venue: "Sports club", price: 1000, category: "Adventure & Activity" },
  { venue: "Stadium", price: 1000, category: "Adventure & Activity" },
  { venue: "Theme Park", price: 1000, category: "Adventure & Activity" },
  { venue: "Entertainment venue", price: 1000, category: "Adventure & Activity" },
  { venue: "Sports venue", price: 1000, category: "Adventure & Activity" },
  { venue: "Zoo", price: 1000, category: "Adventure & Activity" },
  { venue: "Aquarium", price: 1000, category: "Adventure & Activity" },
  { venue: "Limousine", price: 1000, category: "Adventure & Activity" },
  { venue: "Party bus", price: 1000, category: "Adventure & Activity" },
  { venue: "Conference center", price: 1000, category: "Corporate" },
  // { venue: "Ballroom", price: 1000, category: "Corporate" },
  { venue: "Co-working space", price: 1000, category: "Corporate" },
  { venue: "EXPO", price: 1000, category: "Corporate" },
  // { venue: "Golf courses", price: 1000, category: "Corporate" },
  { venue: "Meeting Center", price: 1000, category: "Corporate" },
  { venue: "Meeting room", price: 1000, category: "Corporate" },
  // { venue: "Team activity", price: 1000, category: "Corporate" },
  { venue: "Training room", price: 1000, category: "Corporate" },
  // { venue: "Exhibitions", price: 1000, category: "Corporate" },
  { venue: "Dance Studio", price: 1000, category: "Arts & Creativity" },
  { venue: "Photo studio", price: 1000, category: "Arts & Creativity" },
  { venue: "Recording studio", price: 1000, category: "Arts & Creativity" },
  { venue: "Streaming studio", price: 1000, category: "Arts & Creativity" },
  { venue: "TV film studio", price: 1000, category: "Arts & Creativity" },
  { venue: "Art studio", price: 1000, category: "Arts & Creativity" },
  { venue: "Art Gallery", price: 1000, category: "Arts & Creativity" },
  { venue: "Church", price: 1000, category: "Religious, Cultural & Historical Venue" },
  // { venue: "Gallery", price: 1000, category: "Religious, Cultural & Historical Venue" },
  { venue: "Museum", price: 1000, category: "Religious, Cultural & Historical Venue" },
  { venue: "Temple", price: 1000, category: "Religious, Cultural & Historical Venue" },
];



export const chipLabels = [
  "Restaurants, Cafe's & Bar",
  "Kids Play Area",
  "Auditorium",
  "Ballroom",
  "Dance Studio",
  "Conference Center",
  "Museum",
  "Gallery",
  "Indoor With Outdoor Space",
  // "Hotel",
  "Schools",
  "Meeting room",
  "Exhibition Center",
  "Meeting Center",
  "Photo Studio",
  "Beach",
  "Garden/Lawn",
  "Music Studio",
  "Heritage Site",
  "Stables",
  "Convention Centers",
  "Arcade",
  "Parks",
  "Sports Center",
  "Desert",
  "Island",
  "Golf Course",
  "University",
  "Pop up Space",
  "Stadium",
  "Boats & Yachts",
  "Concert Halls",
  "Race Course",
  "Theme Park",
  "Rooftop Venues",
  "Theatre",
  "Unique Spaces",
  "Religious Venue",
  "Coworking Space",
];

// export default chipLabels;


