import React, { useContext, useEffect, useState } from "react";
import Input from "../../shared/common/customInput";
import CloseableToolTip from "../CloseableToolTip";
import { VenueContext } from "../../../context/VenueContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getOnboarding } from "../../../api/signup.request";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";

const BasicForm = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["loggedin-user"]);
  const { formData, setFormData } = useContext(VenueContext); // Get context values
  const [descriptionCount, setDescriptionCount] = useState(
    calculateWordCount(formData?.basicDetails?.venueDescription)
  );
  const [hotelName, setHotelName] = useState("");
  const vendorId = localStorage.getItem("vendorId");
  const {
    data: boardingData,
    // error,
    // isLoading,
  } = useQuery({
    queryKey: ["onboardingData"],
    queryFn: async () => {
      return await getOnboarding(vendorId);
    },
    refetchOnWindowFocus: false,
  });
  const path = useLocation();

  useEffect(() => {
    if (
      path.pathname?.includes("venu-Listing-Form") &&
      boardingData?.type === "HotelStayandVenue"
    ) {
      const fetchedHotelName =
        boardingData?.hotels?.[0]?.overview?.basicDetails?.hotelName;

      if (fetchedHotelName && fetchedHotelName !== hotelName) {
        setHotelName(fetchedHotelName);

        setFormData((prevDetails) => ({
          ...prevDetails,
          basicDetails: {
            ...prevDetails.basicDetails,
            hotelName: fetchedHotelName, // Directly use fetchedHotelName
          },
        }));
      }
    }
  }, [boardingData, path.pathname, setFormData, hotelName]); // Ensure all dependencies are correct

  // console.log(hotelName)
  // Function to calculate word count
  function calculateWordCount(text) {
    return text
      ?.trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
  }

  const handleChange = (e) => {
    const { value } = e.target;
    const words = value.trim().split(/\s+/); // Split and filter words
    const wordCount = words.length; // Count words
    setDescriptionCount(wordCount); // Update word count
    handleInputChange("venueDescription", value);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        basicDetails: {
          ...prevDetails.basicDetails,
          [field]: value,
          errors: {
            ...prevDetails?.basicDetails?.errors,
            [field]: "",
          },
        },
      };

      return updatedDetails;
    });
  };

  return (
    <div>
      <h2 className="text-2xl lg:text-3xl font-semibold text-neutralBlack pb-2">
        Basic Details
      </h2>
      <form className="w-full flex flex-wrap gap-4 justify-between mx-auto p-4 bg-white rounded-lg">
        {/* Input fields */}
        <div className="lg:w-[48.5%] w-full">
          <Input
            label={
              <div className="flex  items-center">
                Venue name
                <span className="text-red-500 ml-1 text-[15px]">*</span>
              </div>
            }
            type="text"
            placeholder="Enter venue name"
            value={formData.basicDetails?.venueName || ""}
            onChange={(e) => handleInputChange("venueName", e.target.value)}
            error={formData.basicDetails?.errors?.venueName || ""}
          />
        </div>

        <div className="lg:w-[48.5%] w-full">
          <Input
            label={
              <div className="flex  items-center">
                Hotel/Establishment
                <span className="text-red-500 ml-1 text-[15px]">*</span>
              </div>
            }
            type="text"
            placeholder="Enter hotel / complex name"
            value={formData?.basicDetails?.hotelName || ""}
            onChange={(e) => handleInputChange("hotelName", e.target.value)}
            disabled={
              (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                path.pathname?.includes("venu-Listing-Form")) ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue)
            }
            readOnly={
              (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                path.pathname?.includes("venu-Listing-Form")) ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue)
            }
            // error={
            //   formData.basicDetails?.errors?.venueName &&
            //     user?.onBoardingDetails?.type !== "HotelStayandVenue"
            //     ? formData.basicDetails?.errors?.venueName
            //     : ""
            // }
            error={
              formData?.basicDetails?.errors?.hotelName
              // &&
              //   user?.onBoardingDetails?.type !== "HotelStayandVenue"
              //   ? formData?.basicDetails?.errors?.hotelName
              //   : ""
            }
          />
          {/* {formData.basicDetails?.errors?.hotelName && (
            <p className="text-red-500 pt-1">
              {formData.basicDetails.errors.hotelName}
            </p>
          )} */}
        </div>

        <div className="lg:w-[48.5%] w-full">
          <Input
            label="Group name (if applicable) "
            type="text"
            placeholder="Enter the Group name"
            value={formData.basicDetails?.groupName || ""}
            onChange={(e) => handleInputChange("groupName", e.target.value)}
          />
        </div>
        <div className=" flex justify-between my-8 items-center w-full">
          <div className="flex items-center gap-4">
            <h1 className="font-semibold">
              Does your venue have multiple event spaces?
            </h1>
            <CloseableToolTip
              tooltipText={
                "Selecting ‘yes’ designates this venue as the primary venue. On the dashboard, you can then add manage secondary venues underneath it, allowing for multiple primary and secondary venues."
              }
              bgColor="bg-black"
              textColor="text-white"
              borderColor="#4a4a4a"
            >
              <img src="/Info.svg" width={24} height={24} alt="info" />
            </CloseableToolTip>
          </div>

          <div className="flex items-center gap-10">
            <div className="flex items-center text-secondary gap-3">
              <input
                type="radio"
                className="h-5 w-5"
                name="multipleEventSpaces"
                value="true"
                checked={formData.basicDetails?.hasMultipleEventSpaces || false}
                onChange={() =>
                  handleInputChange("hasMultipleEventSpaces", true)
                }
              />
              <label htmlFor="" className="font-semibold">
                Yes
              </label>
            </div>
            <div className="flex items-center gap-3">
              <input
                type="radio"
                className="h-5 w-5"
                name="multipleEventSpaces"
                value="false"
                checked={
                  !formData.basicDetails?.hasMultipleEventSpaces || false
                }
                onChange={() =>
                  handleInputChange("hasMultipleEventSpaces", false)
                }
              />
              <label htmlFor="" className="font-semibold">
                No
              </label>
            </div>
          </div>
        </div>

        <div className="mb-3 w-full">
          <label className="text-secondary flex justify-between items-center text-sm font-medium mb-2">
            <div className="flex items-center gap-4">
              <h1 className="font-semibold">
                Venue Description
                <span className="text-red-500 ml-1">*</span>
              </h1>
              {/* <Tooltip
                title="Create an engaging venue description by highlighting its atmosphere, size, key facilities, location, and distinctive characteristics. Keep it brief and captivating to draw interest"
                arrow
                bgColor="black"
                textColor="white"
              >
                <img src="/Info.svg" width={24} height={24} alt="info" />
              </Tooltip> */}
              <CloseableToolTip
                tooltipText={
                  "Create an engaging venue description by highlighting its atmosphere, size, key facilities, location, and distinctive characteristics. Keep it brief and captivating to draw interest"
                }
                bgColor="bg-black"
                textColor="text-white"
                borderColor="#4a4a4a"
              >
                <img src="/Info.svg" width={24} height={24} alt="info" />
              </CloseableToolTip>
              {/* <CloseableToolTip
                tooltipText={
                  "Create an engaging venue description by highlighting its atmosphere, size, key facilities, location, and distinctive characteristics. Keep it brief and captivating to draw interest"
                }
                bgColor="bg-black"
                textColor="text-white"
                borderColor="#4a4a4a"
              >
                <img src="/Info.svg" width={24} height={24} />

              </CloseableToolTip> */}
            </div>
            <p
              className={`text-offGray text-sm font-outfitt ${
                descriptionCount > 599 ? "text-red-500" : "text-offGray"
              }`}
            >
              ({descriptionCount}/600 Words)
            </p>
          </label>

          <textarea
            rows={15}
            placeholder="Write description of your venue"
            value={formData?.basicDetails?.venueDescription || ""}
            onChange={handleChange}
            className={`appearance-none border border-lightGray rounded-lg w-full py-3 px-3 text-secondary leading-tight focus:outline-none focus:shadow-outline ${
              formData?.basicDetails?.errors?.venueDescription ||
              descriptionCount > 599
                ? "bg-[#FFEBEB] border-red-500"
                : ""
            }`}
          />
          {formData?.basicDetails?.errors?.venueDescription && (
            <p className="text-red-500 pt-1">
              {formData.basicDetails?.errors.venueDescription}
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default BasicForm;
