import React from "react";
import Container from "@mui/material/Container";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import backgroundIcon from "../../assets/images/background_icon.svg";
import MessageCard from "./components/MessageCard";
import { ReactComponent as CrossBadgeIcon } from "../../assets/images/cross_badge_icon.svg";
import { toast } from "react-toastify";

function PaymentRejected() {
  // Extract the session_id from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("session_id");

  const handleRetry = () => {
    if (sessionId) {
      // Redirect to the Stripe checkout session using the sessionId
      window.location.href = `https://checkout.stripe.com/pay/${sessionId}`;
    } else {
      toast.error("Session ID not found. Please try again later.");
    }
  };

  return (
    <>
      <ResponsiveAppBar />
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: { md: "center" },
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <MessageCard
            loaderComponent={
              <CrossBadgeIcon
                sx={{
                  fontSize: 60,
                  mt: 4,
                }}
              />
            }
            primaryText={"Payment Rejected"}
            secondaryText={`Please try again later...`}
          />
          <button
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              backgroundColor: "#FF5733",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
            }}
            onClick={handleRetry}
          >
            Retry Now
          </button>
        </Container>
      </div>
    </>
  );
}

export default PaymentRejected;
