/* eslint-disable no-unused-vars */
export const validateBasicForm = (formData, setFormData) => {
	const basicDetails = formData.basicDetails || {};
	const errors = {};

	// Basic Details Validation
	if (!basicDetails?.venueName.trim()) {
		errors.venueName = 'Venue name is required';
	}

	if (!basicDetails?.hotelName.trim()) {
		errors.hotelName = 'Required';
	}
	const text = basicDetails?.venueDescription;
	const words = text.trim().split(/\s+/); // Regular expression to handle multiple spaces
	const wordCount = words.length;
	console.log('WORD COUNT', wordCount);

	// Check if the word count is within the valid range

	if (!basicDetails?.venueDescription || wordCount < 100) {
		errors.venueDescription = 'Write atleast 100 words';
	}
	if (wordCount > 600) {
		errors.venueDescription = 'Maximum word count exceeded';
	}

	// if (wordCount < 100 || wordCount > 600) {
	//   errors.venueDescription = "Venue description must be between 100 and 600 words";
	// }
	// Update the formData with validation errors
	setFormData((prevData) => ({
		...prevData,
		basicDetails: {
			...prevData.basicDetails,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validateAdditionForm = (formData, setFormData) => {
	const additionalDetails = formData?.additionalDetails || {};
	const errors = {};

	// Validate Venue Style
	const venueView = additionalDetails.venueView || {};
	const totalVenue = Object.values(venueView).flat().length;

	if (totalVenue < 1) {
		errors.venueView = 'You must select at least 1 venue View .';
	}

	const venueStyle = additionalDetails.venueStyle || {};
	const totalVenueStyles = Object.values(venueStyle).flat().length;

	if (totalVenueStyles < 1) {
		errors.venueStyle = 'You must select at least 1 venue styles.';
	}
	// venue setting
	const venueSetting = additionalDetails.venueSetting || {};
	const totalVenueSetting = Object.values(venueSetting).flat().length;

	if (totalVenueSetting < 1) {
		errors.venueSetting = 'You must select at least 1 venue setting.';
	}
	// Validate Venue Hire
	const venueHire = additionalDetails.venueHire || '';
	if (!venueHire) {
		errors.venueHire = 'You must select a venue hire option.';
	}

	// Update the form data with errors
	setFormData((prevData) => ({
		...prevData,
		additionalDetails: {
			...prevData.additionalDetails,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const valiVenuedateTypeForm = (formData, setFormData) => {
	const additionalDetails = formData?.venueType || {};
	const errors = {};

	//   venueType dropdown
	const dropDown = additionalDetails?.venueRepresent;
	// Validate Venue Style
	const venueType1 = additionalDetails.venueType || {};
	const totalVenue = Object.values(venueType1).flat().length;

	if (totalVenue < 1) {
		errors.venueType = 'You must select at least 1 venue Type .';
	}
	if (dropDown == '') {
		errors.primaryType = 'You must select at least 1 primary Type .';
	}

	// Update the form data with errors
	setFormData((prevData) => ({
		...prevData,
		venueType: {
			...prevData.venueType,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

// export const validVenueEventTypeForm = (formData, setFormData) => {
// 	const additionalDetails = formData?.eventType || {};
// 	const errorsha = {};

// 	const conference = additionalDetails?.['Conference/Seminars'];
// 	const Weddings = additionalDetails?.Weddings;
// 	const Dining = additionalDetails?.Dining;
// 	const Cultural = additionalDetails?.Cultural;
// 	const CorporateEvents = additionalDetails?.['Corporate Events'];
// 	const MilestoneBirthdays =
// 		additionalDetails?.['Milestone Birthdays'];
// 	const TeaPartyShowers = additionalDetails?.['Tea Party/Showers'];
// 	const TeenChildren = additionalDetails?.['Teen/Children'];
// 	const SpecialBigEvents = additionalDetails?.['Special Big Events'];
// 	const ArtSpaces = additionalDetails?.['Art Spaces'];
// 	const PerformancesExhibitions =
// 		additionalDetails?.['Performances & Exhibitions'];
// 	const FilmingPhotographyMedia =
// 		additionalDetails?.['Filming, Photography & Media'];

// 	// General check: ensure at least one event type has a selection
// 	const isAnySelected =
// 		conference?.length > 0 ||
// 		Weddings?.length > 0 ||
// 		Dining?.length > 0 ||
// 		CorporateEvents?.length > 0 ||
// 		MilestoneBirthdays?.length > 0 ||
// 		TeaPartyShowers?.length > 0 ||
// 		TeenChildren?.length > 0 ||
// 		SpecialBigEvents?.length > 0 ||
// 		ArtSpaces?.length > 0 ||
// 		PerformancesExhibitions?.length > 0 ||
// 		FilmingPhotographyMedia?.length > 0 ||
// 		Cultural?.length > 0;

// 	// If no event type is selected, show a general error
// 	if (!isAnySelected) {
// 		errorsha.general =
// 			'Please select at least one chip from Event Type';
// 	}

// 	// // Check individual fields for specific errors
// 	// if (conference?.length === 0) {
// 	//   errorsha.conference = "Please select at least one chip from Conference";
// 	// }
// 	// if (Weddings?.length === 0) {
// 	//   errorsha.Weddings = "Please select at least one chip from Weddings";
// 	// }
// 	// if (Dining?.length === 0) {
// 	//   errorsha.Dining = "Please select at least one chip from Dining";
// 	// }
// 	// if (CorporateEvents?.length === 0) {
// 	//   errorsha.CorporateEvents = "Please select at least one chip from Corporate Events";
// 	// }
// 	// if (MilestoneBirthdays?.length === 0) {
// 	//   errorsha.MilestoneBirthdays = "Please select at least one chip from Milestone Birthdays";
// 	// }
// 	// if (TeaPartyShowers?.length === 0) {
// 	//   errorsha.TeaPartyShowers = "Please select at least one chip from Tea Party/Showers";
// 	// }
// 	// if (TeenChildren?.length === 0) {
// 	//   errorsha.TeenChildren = "Please select at least one chip from Teen/Children";
// 	// }
// 	// if (SpecialBigEvents?.length === 0) {
// 	//   errorsha.SpecialBigEvents = "Please select at least one chip from Special Big Events";
// 	// }
// 	// if (FilmingPhotographyMedia?.length === 0) {
// 	//   errorsha.FilmingPhotographyMedia = "Please select at least one chip from Filming, Photography & Media";
// 	// }
// 	// if (Cultural?.length === 0) {
// 	//   errorsha.Cultural = "Please select at least one chip from Cultural";
// 	// }
// 	// if (ArtSpaces?.length === 0) {
// 	//   errorsha.ArtSpaces = "Please select at least one chip from Art Spaces";
// 	// }
// 	// if (PerformancesExhibitions?.length === 0) {
// 	//   errorsha.PerformancesExhibitions = "Please select at least one chip from Performances & Exhibitions";
// 	// }

// 	// Update form data with the errors
// 	setFormData((prevData) => ({
// 		...prevData,
// 		errors: {
// 			...prevData.errors,
// 			eventType: errorsha,
// 		},
// 	}));

// 	// Return true if there are no errors, otherwise false
// 	return Object.keys(errorsha).length === 0;
// };

// export const validVenueEventTypeForm = (formData, setFormData) => {
// 	const additionalDetails = formData?.eventType || {};
// 	const errors = {};

// 	const conference = additionalDetails?.business?.conferenceSeminar || [];
// 	const corporateEvents = additionalDetails?.business?.corporateEvents || [];
// 	const weddings = additionalDetails?.weddingAndDining?.weddings || [];
// 	const dining = additionalDetails?.weddingAndDining?.dining || [];
// 	const banquet = additionalDetails?.weddingAndDining?.banquet || [];
// 	const socialParties = additionalDetails?.parties?.socialParties || [];
// 	const clubbingNightOut = additionalDetails?.parties?.clubbingNightOut || [];
// 	const drinksReceptions = additionalDetails?.parties?.drinksReceptions || [];
// 	const christmasParties = additionalDetails?.parties?.christmasParties || [];
// 	const babyShowerTeaParty = additionalDetails?.parties?.babyShowerTeaParty || [];
// 	const childrenTeen = additionalDetails?.parties?.childrenTeen || [];
// 	const genericTags = additionalDetails?.parties?.genericTags || [];
// 	const filmingAndPhotography = additionalDetails?.artAndCultural?.filmingAndPhotography || [];
// 	const cultural = additionalDetails?.artAndCultural?.cultural || [];
// 	const artSpaces = additionalDetails?.artAndCultural?.artSpaces || [];
// 	const performancesExhibitions = additionalDetails?.artAndCultural?.performancesExhibitions || [];
// 	// General check: ensure at least one event type has a selection
// 	const isAnySelected =
// 		conference.length > 0 ||
// 		corporateEvents.length > 0 ||
// 		weddings.length > 0 ||
// 		dining.length > 0 ||
// 		banquet.length > 0 ||
// 		socialParties.length > 0 ||
// 		clubbingNightOut.length > 0 ||
// 		drinksReceptions.length > 0 ||
// 		christmasParties.length > 0 ||
// 		babyShowerTeaParty.length > 0 ||
// 		childrenTeen.length > 0 ||
// 		genericTags.length > 0 ||
// 		filmingAndPhotography.length > 0 ||
// 		cultural.length > 0 ||
// 		artSpaces.length > 0 ||
// 		performancesExhibitions.length > 0;

// 	// If no event type is selected, show a general error

// 	console.log(isAnySelected)

// 	if (!isAnySelected) {
// 		errors.general = 'Please select at least one chip from Event Type';
// 	}

// 	// Update form data with the errors
// 	setFormData((prevData) => ({
// 		...prevData,
// 		errors: {
// 			...prevData.errors,
// 			eventType: errors,
// 		},
// 	}));

// 	// Return true if there are no errors, otherwise false
// 	return Object.keys(errors).length === 0;
// };

export const validVenueEventTypeForm = (formData, setFormData) => {
	const additionalDetails = formData?.eventType || {};
	const errors = {};

	const conference = additionalDetails['Conference/Seminars'] || [];
	const corporateEvents = additionalDetails['Corporate Events'] || [];
	const weddings = additionalDetails['Weddings'] || [];
	const dining = additionalDetails['Dining'] || [];
	const banquet = additionalDetails['Special Big Events'] || [];
	const socialParties =
		additionalDetails['Performance & Exhibitions'] || [];
	const clubbingNightOut =
		additionalDetails['Milestone Birthdays'] || [];
	const drinksReceptions =
		additionalDetails?.parties?.drinksReceptions || [];
	const christmasParties =
		additionalDetails?.parties?.christmasParties || [];
	const babyShowerTeaParty =
		additionalDetails['Tea Party/Showers'] || [];
	const childrenTeen = additionalDetails['Teen/Children'] || [];
	const genericTags = additionalDetails?.parties?.genericTags || [];
	const filmingAndPhotography =
		additionalDetails['Filming, Photography & Media'] || [];
	const cultural = additionalDetails['Cultural'] || [];
	const artSpaces = additionalDetails['Art Spaces'] || [];
	const performancesExhibitions =
		additionalDetails?.artAndCultural?.performancesExhibitions || [];

	// General check: ensure at least one event type has a selection
	const isAnySelected =
		additionalDetails?.business?.conferenceSeminar?.length > 0 ||
		additionalDetails?.business?.corporateEvents?.length > 0 ||
		additionalDetails?.weddingAndDining?.weddings?.length > 0 ||
		additionalDetails?.weddingAndDining?.dining?.length > 0 ||
		additionalDetails?.weddingAndDining?.banquet?.length > 0 ||
		additionalDetails?.parties?.socialParties?.length > 0 ||
		additionalDetails?.parties?.clubbingNightOut?.length > 0 ||
		additionalDetails?.parties?.drinksReceptions?.length > 0 ||
		additionalDetails?.parties?.christmasParties?.length > 0 ||
		additionalDetails?.parties?.babyShowerTeaParty?.length > 0 ||
		additionalDetails?.parties?.childrenTeen?.length > 0 ||
		additionalDetails?.parties?.genericTags?.length > 0 ||
		additionalDetails?.artAndCultural?.filmingAndPhotography?.length >
			0 ||
		additionalDetails?.artAndCultural?.cultural?.length > 0 ||
		additionalDetails?.artAndCultural?.artSpaces?.length > 0 ||
		additionalDetails?.artAndCultural?.performancesExhibitions
			?.length > 0;

	console.log('isAnySelected:', isAnySelected);

	// If no event type is selected, show a general error
	if (!isAnySelected) {
		errors.general =
			'Please select at least one chip from Event Type';
	}

	// Update the form data with errors
	setFormData((prevData) => ({
		...prevData,
		errors: {
			...(prevData.errors || {}), // Ensure errors object exists
			eventType: errors,
		},
	}));

	// Return false if errors exist
	return Object.keys(errors).length === 0;
};

export const validateLocationForm = (formData, setFormData) => {
	const locationDetails = formData?.location || {};
	const errors = {};

	if (locationDetails.addressLine1.trim() === '') {
		errors.addressLine1 = 'Address Line 1 is required';
	}
	if (!locationDetails.city) {
		errors.city = 'City is required';
	}
	if (!locationDetails.area) {
		errors.area = 'Area is required';
	}
	if (!locationDetails.mapLink) {
		errors.mapLink = 'Map Link is required';
	}
	// if (!locationDetails.mapLink) {
	// 	errors.mapLink = 'Map Link is required';
	// }

	setFormData((prevData) => ({
		...prevData,
		location: {
			...prevData.location,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validateCapacityTypeForm = (formData, setFormData) => {
	const capacity = formData?.capacity || {};
	const errors = {};

	// Check if required fields are present
	if (!capacity.venueSize.trim()) {
		errors.venueSize = 'Venue Size is required';
	}
	if (!capacity.sitting) {
		errors.sitting = 'Sitting capacity is required';
	}
	if (!capacity.standing) {
		errors.standing = 'Standing capacity is required';
	}

	// Validate additional layout fields
	const additionalLayout = capacity.additionalLayout || {};
	Object.keys(additionalLayout).forEach((layoutType) => {
		const layout = additionalLayout[layoutType];
		if (layout.enabled && !layout.value) {
			errors[layoutType] = `${
				layoutType.charAt(0).toUpperCase() + layoutType.slice(1)
			} layout value is required`;
		}
	});

	setFormData((prevData) => ({
		...prevData,
		capacity: {
			...prevData.capacity,
			errors,
		},
	}));
	return Object.keys(errors).length === 0;
};

export const validateAudioForm = (formData, setFormData) => {
	const audio = formData?.audio || {};
	const errors = {};

	// Validate indoor music allowed time only if indoor music is allowed
	if (
		audio.indoorMusicAllowed &&
		audio.indoorMusicAllowedTime === ''
	) {
		errors.indoorMusicAllowedTime =
			'Indoor music allowed time is required.';
	}

	// Validate outdoor music allowed time only if outdoor music is allowed
	if (
		audio.outdoorMusicAllowed &&
		audio.outdoorMusicAllowedTime === ''
	) {
		errors.outdoorMusicAllowedTime =
			'Outdoor music allowed time is required.';
	}

	// Ensure the existing form data is not overwritten and errors are properly set
	setFormData((prevData) => ({
		...prevData,
		audio: {
			...prevData.audio, // Spread the existing audio data
			errors: { ...prevData.audio?.errors, ...errors }, // Merge new and old errors
		},
	}));

	console.log(errors);

	return Object.keys(errors).length === 0;
};

export const validateFacilitiesForm = (formData, setFormData) => {
	const facilities = formData?.facilities || {};
	const errors = {};

	// Validate activitiesForKidsIncludes if activitiesForKids is true
	if (
		facilities.activitiesForKids &&
		facilities.activitiesForKidsIncludes.length === 0
	) {
		errors.activitiesForKidsIncludes =
			'Please provide at least one activity for kids.';
	}

	// Validate that at least one value inside facilities.facilities is true
	const facilityValues = Object.values(facilities.facilities || {});
	const atLeastOneTrue = facilityValues.some(
		(value) => value === true
	);

	if (!atLeastOneTrue) {
		errors.facilities =
			'At least one facility option must be selected.';
	}

	// Update the form data with errors, ensuring errors are defined even if empty
	setFormData((prevData) => ({
		...prevData,
		facilities: {
			...prevData.facilities,
			errors: errors, // This ensures that errors are always defined
		},
	}));

	console.log(errors)

	return Object.keys(errors).length === 0;
};

export const validateCateringForm = (formData, setFormData) => {
	const cateringAndDrinks = formData?.cateringAndDrinks || {};
	const errors = {};
	// Check if the venue provides in-house catering
	const venueProvideInHouseCatering =
		cateringAndDrinks.venueProvideInHouseCatering;
	if (venueProvideInHouseCatering) {
		// Validate Venue Menu
		// const v1 = cateringAndDrinks?.availableMenu || {};
		// const availableMenu = Object.values(v1).flat().length;

		// if (availableMenu < 1) {
		//   errors.availableMenu = "You must select at least 1 available menu.";
		// }

		// Validate Cuisine
		const venueStyle = cateringAndDrinks.cuisine || {};
		const totalVenueStyles = Object.values(venueStyle).flat().length;

		if (totalVenueStyles < 1) {
			errors.cuisine = 'You must select at least 1 cuisine.';
		}
		// Validate Serving Style
		const venueStyle2 = cateringAndDrinks.servingStyle || {};
		const totalVenueStyles2 =
			Object.values(venueStyle2).flat().length;
		if (totalVenueStyles2 < 1) {
			errors.servingStyle =
				'You must select at least 1 serving style.';
		}
	}
	setFormData((prevData) => ({
		...prevData,
		cateringAndDrinks: {
			...prevData.cateringAndDrinks,
			errors,
		},
	}));
	return Object.keys(errors).length === 0;
};

export const validateSpaceTypeForm = (formData, setFormData) => {
	const spaceRules = formData?.spaceRules || {};
	const errors = {};

	setFormData((prevData) => ({
		...prevData,
		spaceRules: {
			...prevData.spaceRules,
			errors,
		},
	}));

	return Object.keys(errors).length === 0;
};

export const validatUploadForm = (formData, setFormData) => {
	const UploadDocuments = formData.UploadDocuments || {};
	const errors = {};

	// Define documents with "required" status for the first two
	const documents = [
		{ name: 'floorPlans', optional: false, multiple: true },
		{ name: 'tradeLicense', optional: false, multiple: false },
		{ name: 'sampleMenus', optional: true, multiple: true },
		{ name: 'brochure', optional: true, multiple: true },
	];

	// Validate each selected document
	documents.forEach((doc) => {
		const documentFiles = UploadDocuments[doc.name] || [];
		// Check if required documents are uploaded
		if (!doc.optional && documentFiles.length === 0) {
			errors[doc.name] = `${doc.name} is required`;
		}
	});
	// Update the formData with validation errors
	setFormData((prevData) => ({
		...prevData,
		UploadDocuments: {
			...prevData.UploadDocuments,
			errors,
		},
	}));

	// Return true if no errors, otherwise false
	return Object.keys(errors).length === 0;
};

export const validatePhotoVideoForm = (formData, setFormData) => {
	const photoVideo = formData?.imagesAndVideos; // Default to empty arrays if undefined
	const errors = {};

	if (!photoVideo?.images.length) {
		errors.images = 'At least one image is required';
	}

	setFormData((prevData) => ({
		...prevData,
		imagesAndVideos: {
			...prevData.imagesAndVideos,
			errors,
		},
	}));
	return Object.keys(errors).length === 0;
};

// Validation for indicating that this step is not fully completed

// export const validateCateringAndDrinks = (cateringAndDrinks) => {
//   const requiredFields = [
//     'availableMenu',
//     'cuisine',
//     'servingStyle',
//   ];

//   const errors = requiredFields.filter(field => {
//     if (Array.isArray(cateringAndDrinks[field])) {
//       return cateringAndDrinks[field].length === 0;
//     }
//     return !cateringAndDrinks[field];
//   });

//   return errors.length > 0;
// };
export const validateCateringAndDrinks = (cateringAndDrinks) => {
	const errors = [];

	// Check if the venue provides in-house catering
	const venueProvideInHouseCatering =
		cateringAndDrinks.venueProvideInHouseCatering;

	// Only validate 'cuisine' and 'servingStyle' if in-house catering is provided
	if (venueProvideInHouseCatering) {
		const requiredFields = ['cuisine', 'servingStyle'];

		requiredFields.forEach((field) => {
			if (Array.isArray(cateringAndDrinks[field])) {
				if (cateringAndDrinks[field].length === 0) {
					errors.push(field);
				}
			} else if (!cateringAndDrinks[field]) {
				errors.push(field);
			}
		});
	}

	return errors.length > 0;
};

export const validateSpaceRules = (spaceRules) => {
	const requiredFields = ['moreSpaceRules'];

	const errors = requiredFields.filter((field) => {
		if (Array.isArray(spaceRules[field])) {
			return spaceRules[field].length === 0;
		}
		// return !spaceRules[field];
		return false;
	});

	return errors.length > 0;
};
// offer and packages
export const validateOffersAndPackages = (offersAndPackages) => {
	const requiredFields = ['description'];
	// Check if offersAndPackages is undefined, null, or not an array
	if (!Array.isArray(offersAndPackages)) {
		return true; // Return true indicating errors if not an array
	}
	// Validate each offer/package item in the array
	const hasErrors = offersAndPackages.some((offerPackage) => {
		// If offerPackage is not an object or is null, it's invalid
		if (typeof offerPackage !== 'object' || offerPackage === null) {
			return true;
		}
		// Check if any required field is missing or empty
		return requiredFields.some((field) => {
			if (Array.isArray(offerPackage[field])) {
				return offerPackage[field].length === 0;
			}
			return !offerPackage[field];
		});
	});
	return hasErrors;
};

// validation.js
export const validatePricing = (pricingData) => {
	// Ensure there are selected options and at least one valid entry
	const hasValidOption = Object.keys(pricingData || {}).some(
		(option) => pricingData[option] && pricingData[option].length > 0
	);
	return !hasValidOption;
};

export const validateBasicDetails = (basicDetails = {}) => {
	// Define the required fields
	const requiredFields = [
		'venueName',
		'hotelName',
		'venueDescription',
	];

	// Check if basicDetails is an object
	if (typeof basicDetails !== 'object' || basicDetails === null) {
		return true; // Return true indicating errors if not an object
	}

	// Check if any required field is empty
	const hasErrors = requiredFields.some((field) => {
		return !basicDetails[field] || basicDetails[field].trim() === '';
	});

	// Return true if there are errors (i.e., if any required field is empty)
	return hasErrors;
};
// additional details
export const validateAdditionalDetails = (additionalDetails) => {
	const { venueView, venueSetting, venueStyle, venueHire } =
		additionalDetails;

	// Check if venueStyle is valid
	const isVenueStyleValid = Object.values(venueStyle).every(
		(styleArray) => Array.isArray(styleArray) && styleArray.length > 0
	);

	// Check if all required fields have values
	const isValid =
		Array.isArray(venueView) &&
		venueView.length > 0 &&
		Array.isArray(venueSetting) &&
		venueSetting.length > 0 &&
		isVenueStyleValid &&
		venueHire !== '';

	return isValid;
};
export const validateFacilitiesStepper = (facilities = {}) => {
	const errors = {};
	// Validate activitiesForKidsIncludes if activitiesForKids is true
	if (
		facilities.activitiesForKids &&
		(!Array.isArray(facilities.activitiesForKidsIncludes) ||
			facilities.activitiesForKidsIncludes.length === 0)
	) {
		errors.activitiesForKidsIncludes =
			'Please provide at least one activity for kids.';
	}
	// Validate that at least one value inside facilities.facilities is true
	const facilityValues = Object.values(facilities.facilities || {});
	const atLeastOneTrue = facilityValues.some(
		(value) => value === true
	);
	if (!atLeastOneTrue) {
		errors.facilities =
			'At least one facility option must be selected.';
	}
	const hasErrors = Object.keys(errors).length > 0;
	return hasErrors;
};
export const validateUploadDocumentstepper = (
	UploadDocuments = {}
) => {
	const errors = {};

	// Define documents with "required" status for the first two
	const documents = [
		{ name: 'floorPlans', optional: false, multiple: true },
		{ name: 'tradeLicense', optional: false, multiple: false },
		{ name: 'sampleMenus', optional: true, multiple: true },
		{ name: 'brochure', optional: true, multiple: true },
	];

	// Validate each selected document
	documents.forEach((doc) => {
		const documentFiles = UploadDocuments[doc.name] || [];
		// Check if required documents are uploaded
		if (!doc.optional && documentFiles.length === 0) {
			errors[doc.name] = `${doc.name} is required`;
		}
	});

	// Determine if there are any errors
	const hasErrors = Object.keys(errors).length > 0;

	// Return hasErrors, indicating whether there are validation issues
	return hasErrors;
};

export const validateUploadPhotosAndVideosStepper = (
	uploadImagesAndVideos = {}
) => {
	const errors = {};

	if (uploadImagesAndVideos['images'].length === 0)
		errors.images = 'At least one image is required';

	// Determine if there are any errors
	const hasErrors = Object.keys(errors).length > 0;

	// Return hasErrors, indicating whether there are validation issues
	return hasErrors;
};

export const validateCapacityTypeStepper = (capacity = {}) => {
	const errors = {};

	// Check if required fields are present
	if (!capacity.venueSize) {
		errors.venueSize = 'Venue Size is required';
	}
	if (!capacity.sitting) {
		errors.sitting = 'Sitting capacity is required';
	}
	if (!capacity.standing) {
		errors.standing = 'Standing capacity is required';
	}

	// Validate additional layout fields
	const additionalLayout = capacity.additionalLayout || {};
	Object.keys(additionalLayout).forEach((layoutType) => {
		const layout = additionalLayout[layoutType];
		if (layout.enabled && !layout.value) {
			errors[layoutType] = `${
				layoutType.charAt(0).toUpperCase() + layoutType.slice(1)
			} layout value is required`;
		}
	});

	// Determine if there are any errors
	const hasErrors = Object.keys(errors).length > 0;

	// Return hasErrors, indicating whether there are validation issues
	return hasErrors;
};
