import React from "react";
import img1 from "../../assets/pics/Dubai.jpg";
import img2 from "../../assets/pics/Ajman.jpg";
// import img3 from "../../assets/pics/Sharjah.jpg";
import img4 from "../../assets/pics/sharj.png";
import img5 from "../../assets/pics/Abu Dhabi.jpg";
import img6 from "../../assets/pics/Fujairah.jpg";
import img7 from "../../assets/pics/Ras Al Khaimah.jpg";
import img3 from "../../assets/pics/new1.png";
import ImageCarousel from "./ImageCarousel";

const Spaces = () => {
  return (
    <div className="flex justify-center w-full pt-16">
      <div className="w-[90%] md:flex md:flex-col items-center">
        <h1 className="text-[#222] sm:text-4xl text-2xl font-semibold text-center">
          Discover Popular Event Spaces in the UAE
        </h1>
        <div className="md:flex hidden h-full w-full gap-5 mt-10">
          <div className="bg-white border rounded-lg  relative flex-col  w-[20%] h-full flex items-center ">
            <img
              src={img1}
              alt=""
              className="rounded-lg  h-full  w-full object-cover z-20"
            />
            <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem] absolute rounded-b-lg  z-30">
              <h1 className=" text-lg font-medium">Dubai</h1>
            </div>
          </div>
          <div className="flex flex-col gap-5 h-full w-[80%]">
            <div className="flex gap-5">
              <div className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center ">
                <img
                  src={img2}
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />
                {/* <div className=" flex flex-col items-center ">
                  <h1 className="-mt-16 text-lg font-medium">Ajman</h1>
                </div> */}
                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem] absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Ajman</h1>
                </div>
              </div>
              <div className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center ">
                <img
                  src={img4}
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />
                {/* <div className=" flex flex-col items-center ">
                  <h1 className="-mt-16 text-lg font-medium">Sharjah</h1>
                </div> */}
                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Sharjah</h1>
                </div>
              </div>
              <div className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center ">
                <img
                  src={img3}
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />
                {/* <div className=" flex flex-col items-center ">
                  <h1 className="-mt-16 text-lg font-medium">Umm Al Quwain</h1>
                </div> */}
                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Umm Al Quwain</h1>
                </div>
              </div>
            </div>

            <div className="flex gap-5">
              <div className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center ">
                <img
                  src={img5}
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />
                {/* <div className=" flex flex-col items-center ">
                  <h1 className=" -mt-16 text-lg font-medium">Abu Dhabi</h1>
                </div> */}
                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Abu Dhabi</h1>
                </div>
              </div>
              <div className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center ">
                <img
                  src={img6}
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />
                {/* <div className=" flex flex-col items-center ">
                  <h1 className="-mt-16 text-lg font-medium">Fujairah</h1>
                </div> */}
                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Fujairah</h1>
                </div>
              </div>
              <div className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center ">
                <img
                  src={img7}
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />
                {/* <div className=" flex flex-col items-center ">
                  <h1 className="-mt-16 text-lg font-medium">Ras Al Khaimah</h1>
                </div> */}
                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Ras Al Khaimah</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden block mt-10">
          <ImageCarousel />
        </div>
      </div>
    </div>
  );
};

export default Spaces;
