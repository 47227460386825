import costStructure from "../constants/costStructure";

function calculateIndividualPayment() {
  const baseCharge = 1000;
  const subTotal = baseCharge;

  const vatPercentage = costStructure.vat;
  const vat = (subTotal * vatPercentage) / 100;

  const grandTotal = subTotal + vat;

  return {
    baseCharge: baseCharge,
    subTotal: subTotal,
    vat: vat,
    grandTotal: grandTotal,
  };
}

export default calculateIndividualPayment;
