import React, { useContext, useState, useEffect } from "react";
import {

  FaTrash,
} from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import ToggleSwitch from "../../shared/common/custom-toggle";
import AddFeatureModal from "./add-feature-form";
import Modal from "../../shared/custom-modal/custom-modal";
import SelectableTags from "../../shared/common/selectableTags";
import { VenueContext } from "../../../context/VenueContext";
import * as FaIcons from "react-icons/fa";
import s1 from "../../../assets/icons/facilities/1.svg";
import s2 from "../../../assets/icons/facilities/2.svg";
import s3 from "../../../assets/icons/facilities/3.svg";
import s4 from "../../../assets/icons/facilities/4.svg";
import s5 from "../../../assets/icons/facilities/5.svg";
import s6 from "../../../assets/icons/facilities/6.svg";
import s7 from "../../../assets/icons/facilities/7.svg";
import s8 from "../../../assets/icons/facilities/8.svg";
import s9 from "../../../assets/icons/facilities/9.svg";

const activities = [
  "Art & Craft",
  "Painting",
  "Sports",
  "Science",
  "Cooking/Baking",
  "Educational",
  "Camps",
  "Dance",
  "Magic Shows",
];

const defaultFeatures = [
  { name: "Natural Light", icon: s1 },
  { name: "Flatscreen TV", icon: s2 },
  { name: "Wifi", icon: s3 },
  { name: "Flipchart", icon: s4 },
  { name: "Air conditioning", icon: s5 },
  { name: "Storage space", icon: s6 },
  { name: "Podium", icon: s7 },
  { name: "Conference call facilities", icon: s8 },
  { name: "Whiteboard", icon: s9 },
];

const FacilitiesForm = () => {
  const { formData, setFormData } = useContext(VenueContext);
  const [features, setFeatures] = useState(defaultFeatures);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [featureToEdit, setFeatureToEdit] = useState(null);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [allowTicketedEvents, setAllowTicketedEvents] = useState(false);

  const facilitiesFeatures = formData?.facilities?.facilities || {};
  const facilitiesFeatureIcons =
    formData?.facilities?.facilitiesFeatureIcons || {};

  useEffect(() => {
    // Create a map of feature names to icons from facilitiesFeatureIcons
    const iconsMap = new Map(Object.entries(facilitiesFeatureIcons));

    // Map existing features into an array with proper icons
    const updatedFeatures = Object.keys(facilitiesFeatures).map((feature) => ({
      name: feature,
      icon: iconsMap.get(feature) || "FaAccusoft",
    }));

    // Update the features state with the merged list
    setFeatures((prevFeatures) => {
      // Create a map of previous features to avoid duplicates
      const existingFeaturesMap = new Map(
        prevFeatures.map((item) => [item.name, item])
      );

      // Combine the existing features with the updated features
      const combinedFeatures = [...prevFeatures];
      updatedFeatures.forEach((newFeature) => {
        if (!existingFeaturesMap.has(newFeature.name)) {
          combinedFeatures.push(newFeature);
        }
      });

      return combinedFeatures;
    });
  }, [facilitiesFeatures, facilitiesFeatureIcons]);

  useEffect(() => {
    if (formData) {

    }
  }, [formData]);


  const handleToggle = (featureName) => {
    console.log("facilitiesFeatures", featureName);
    const updatedFeatures = {
      ...facilitiesFeatures,
      [featureName]: !facilitiesFeatures[featureName],
    };

    setFormData((prevData) => ({
      ...prevData,
      facilities: {
        ...prevData.facilities,
        facilities: updatedFeatures,
      },
    }));
  };

  const handleUpdateFeature = (feature) => {
    const updatedFeatures = features?.map((e, i) => {
      if (i === feature?.index) {
        return { name: feature?.name, icon: feature?.icon };
      }
      return e;
    });

    setFeatures(updatedFeatures);

    const icons = {};
    const names = {};

    updatedFeatures?.forEach((facility, index) => {
      if (index === feature?.index) {
        icons[facility.name] = facility.icon;
        names[facility.name] = true;
      } else {
        icons[facility.name] = facility.icon;
        names[facility.name] = formData?.facilities?.facilities[facility.name];
      }
    });

    setFormData((prevData) => ({
      ...prevData,
      facilities: {
        ...prevData.facilities,
        facilities: names,
        facilitiesFeatureIcons: icons,
      },
    }));
  };

  const handleAddFeature = (newFeature) => {
    if (featureToEdit) {
      const updatedFeatures = features.map((feature) =>
        feature.name === featureToEdit.name ? newFeature : feature
      );
      setFeatures(updatedFeatures);

      setFeatureToEdit(null);
    } else {
      setFeatures((prevFeatures) => [...prevFeatures, newFeature]);
    }
    setFormData((prevData) => ({
      ...prevData,
      facilities: {
        ...prevData.facilities,
        facilities: {
          ...prevData.facilities.facilities,
          [newFeature.name]: true,
        },
        facilitiesFeatureIcons: {
          ...prevData.facilities.facilitiesFeatureIcons,
          [newFeature.name]: newFeature.icon || "FaAccusoft",
        },
      },
    }));

  };

  const handleEditFeature = (feature) => {
    setFeatureToEdit(feature);
    setIsModalOpen(true);
  };

  const handleDeleteFeature = (featureName) => {
    const updatedFeatures = features.filter(
      (feature) => feature.name !== featureName
    );
    setFeatures(updatedFeatures);

    const updatedBusinessFeatures = { ...facilitiesFeatures };
    delete updatedBusinessFeatures[featureName];


    setFormData((prevData) => ({
      ...prevData,
      facilities: {
        ...prevData.facilities,
        facilities: updatedBusinessFeatures,
      },
    }));
  };

  const toggleActivity = (activity) => {
    const updatedActivities = selectedActivities.includes(activity)
      ? selectedActivities.filter((a) => a !== activity)
      : [...selectedActivities, activity];

    setSelectedActivities(updatedActivities);

    setFormData((prevData) => ({
      ...prevData,
      facilities: {
        ...prevData.facilities,
        activitiesForKidsIncludes: updatedActivities,
      },
    }));

  };

  const handleAllowTicketedEventsToggle = () => {
    const newAllowTicketedEvents = !allowTicketedEvents;
    console.log("newAllowTicketedEvents", newAllowTicketedEvents);
    setFormData((prevData) => ({
      ...prevData,
      facilities: {
        ...prevData.facilities,
        activitiesForKids: newAllowTicketedEvents,
        activitiesForKidsIncludes: selectedActivities,
      },
    }));
    setAllowTicketedEvents(newAllowTicketedEvents);

  };

  const renderIcon = (iconName) => {
    const IconComponent = FaIcons[iconName];
    return IconComponent ? <IconComponent /> : null;
  };

  return (
    <div className="flex flex-col w-full px-4">
      <div className="w-full ">
        <div className="flex justify-between items-center w-full pb-5">
          <h2 className="text-3xl font-bold mb-4">Facilities</h2>
          <button
            onClick={() => {
              setFeatureToEdit(null);
              setIsModalOpen(true);
            }}
            className="text-primary font-medium"
          >
            Add other Facilities
          </button>
        </div>

        {features &&
          features.map((feature, index) => {
            const isDefaultFeature = defaultFeatures.some(
              (defaultFeature) => defaultFeature.icon === feature.icon
            );
            return (
              <div
                key={index}
                className="flex py-2 items-center justify-between lg:w-1/2 w-full mb-4"
              >
                <div className="flex items-center">
                  {isDefaultFeature ? (
                    // <span className="text-2xl mr-2">{feature.icon}</span>
                    <img src={feature.icon} alt="" className="pr-4" />
                  ) : (
                    <span className="text-2xl mr-2">
                      {renderIcon(feature.icon)}
                    </span>
                  )}
                  <span className="text-base font-medium text-black truncate max-w-[14rem]">
                    {feature.name}
                  </span>
                </div>
                <div className="flex items-center gap-2 relative">
                  <ToggleSwitch
                    isOn={facilitiesFeatures[feature.name] || false}
                    handleToggle={() => handleToggle(feature.name)}
                  />
                  {!defaultFeatures.some((f) => f.name === feature.name) && (
                    <div className="flex items-center gap-2 absolute left-16">
                      <FaTrash
                        className="cursor-pointer text-red-600"
                        onClick={() => handleDeleteFeature(feature.name)}
                      />
                      <CiEdit
                        className="cursor-pointer text-secondary"
                        onClick={() => handleEditFeature({ ...feature, index })}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {formData.facilities?.errors?.facilities && (
          <p className="text-red-500 pt-1">
            {formData.facilities.errors.facilities}
          </p>
        )}
      </div>
      <div className="p-2 border-t border-dashed py-3 lg:w-4/5 w-full">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-medium">Activities for Kids</h1>
          <ToggleSwitch
            isOn={allowTicketedEvents}
            handleToggle={handleAllowTicketedEventsToggle}
          />
        </div>
        {allowTicketedEvents && (
          <div className="flex flex-wrap gap-2">
            <SelectableTags
              tags={activities}
              selectedTags={selectedActivities}
              onTagClick={toggleActivity}
            />
          </div>
        )}
      </div>
      {formData.facilities?.errors?.activitiesForKidsIncludes && (
        <p className="text-red-500 pt-1">
          {formData?.facilities?.errors?.activitiesForKidsIncludes}
        </p>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddFeatureModal
          onSave={handleAddFeature}
          onUpdate={handleUpdateFeature}
          onRequestClose={() => setIsModalOpen(false)}
          featureToEdit={featureToEdit}
        />
      </Modal>
    </div>
  );
};

export default FacilitiesForm;
