import React, { useRef, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import Navbar from "../../components/webComps/Navbar";
import Footer from "../../components/webComps/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { MdOutlineAttachFile } from "react-icons/md";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import { base_URL } from "../../config/config";

const S3_BUCKET = "st-findmyvenue";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIATCKAPHDJJECWAMJF",
  secretAccessKey: "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",
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const data = [
  {
    pic: "/Havequestions.svg",
    name: "Have questions?",
    des: (
      <>
        Take a look at some of the most frequently asked questions by the users
        of our platform. Read User{" "}
        <Link to="/faqs" className="text-blue-500">
          FAQ&apos;s
        </Link>
        .
      </>
    ),
  },
  {
    pic: "/Needcontactsupport.svg",
    name: "Need contact support?",
    des: "If you require assistance with your account, please submit the form below.",
  },
  {
    pic: "/listvenu.svg",
    name: "Want to list your venue?",
    des: (
      <>
        Join UAE&apos;s leading marketplace for venue hire.{" "}
        <Link
          to="https://onboarding.findmyvenue.com/list-venues"
          className="text-blue-500"
        >
          List Your Venue
        </Link>{" "}
        and get started today!
      </>
    ),
  },
];

const ContactUs = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [accountType, setAccountType] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState({});

  const heroAfterRef = useRef(null);

  const scrollToHeroAfter = () => {
    if (heroAfterRef.current) {
      heroAfterRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    if (!email) formErrors.email = "Email address is required";
    if (!accountType) formErrors.accountType = "Account type is required";
    if (!subject) formErrors.subject = "Subject is required";
    if (!description) formErrors.description = "Description is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  // Upload the file to S3
  const uploadFileToS3 = (file) => {
    setFileUploading(true);
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: file.name,
    };

    return myBucket
      .putObject(params)
      .promise()
      .then((data) => {
        setFileUploading(false);
        return `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`;
      })
      .catch((err) => {
        console.error("Error uploading file:", err);
        setFileUploading(false);
        return null;
      });
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) return;

    let fileUrl = "";
    if (selectedFile) {
      fileUrl = await uploadFileToS3(selectedFile);
      if (!fileUrl) {
        toast.error("Error uploading file. Please try again.");
        return;
      }
    }

    const body = {
      email,
      accountType,
      subject,
      description,
      attachment: fileUrl, // Include the uploaded file URL
    };

    try {
      const response = await axios.post(`${base_URL}/api/contact`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Form submitted successfully:", response.data);
      setEmail("");
      setAccountType("");
      setSubject("");
      setDescription("");
      setSelectedFile(null);
      setErrors({});
      toast.success(
        "Thank you for submitting your enquiry. We will get in touch with you soon!"
      );
      navigate("/");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div ref={heroAfterRef}>
        <Navbar />
      </div>

      <div className="w-full xl:h-[60vh] h-[40vh] bg-contactBanner bg-center bg-cover relative flex justify-center items-center bg-no-repeat">
        <div className="bg-[#000000CC] absolute top-0 left-0 w-full h-full z-40"></div>
        <div className="text-white flex flex-col items-center z-50 gap-3 lg:w-[45%] w-[70%]">
          <h1 className="sm:text-5xl text-3xl text-center">Get in Touch</h1>
          <p className="text-center sm:text-lg text-sm">
            Our friendly team would love to hear from you and help you with any
            queries.
          </p>
        </div>
      </div>

      <div className="flex items-center text-xs px-20 mt-6 z-50">
        <Link to="/" className="text-[#B0B0B0] text-3xl cursor-pointer">
          Home
        </Link>
        <IoIosArrowForward className="text-black text-xl" />
        <p className="cursor-pointer text-[#FE4747] text-3xl">Contact us</p>
      </div>

      <div className="flex justify-center pt-10">
        <div className="w-[90%] grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8">
          {/* Map through the contact info cards */}
          {data?.map((e, i) => (
            <div key={i} className="">
              <div className="flex items-center justify-center mb-3">
                <img
                  src={e?.pic}
                  alt=""
                  className="h-[90px] w-[90px] text-black"
                />
              </div>
              <h1 className="text-darkGray text-center sm:text-2xl text-xl font-semibold">
                {e.name}
              </h1>
              <p className="text-darkGray text-center sm:text-base text-md font-medium mt-2">
                {e.des}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* Form */}
      <div className="flex flex-col items-center justify-center w-full gap-6 py-20">
        <h1 className="lg:text-3xl sm:text-2xl text-xl font-semibold my-8">
          Submit a Request
        </h1>
        <div className="flex sm:w-[60%] w-[90%] flex-col gap-5">
          {/* Form Inputs */}
          <div>
            <p className="text-darkGray font-medium">Email address</p>
            <input
              type="email"
              className="border border-gray-400 w-full mt-2 py-2 px-2 rounded-lg"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>
          {/* Account Type */}
          <div>
            <h1 className="text-darkGray font-medium">
              Select your account type
            </h1>
            <div className="flex gap-5 mt-3">
              <div className="flex item-center gap-2">
                <input
                  type="radio"
                  className="w-5 h-5"
                  name="accountType"
                  value="Venue Seeker"
                  onChange={(e) => setAccountType(e.target.value)}
                />
                <p>Venue Seeker</p>
              </div>
              <div className="flex item-center gap-2">
                <input
                  type="radio"
                  className="w-5 h-5"
                  name="accountType"
                  value="Venue Operator"
                  onChange={(e) => setAccountType(e.target.value)}
                />
                <p>Venue Operator</p>
              </div>
            </div>
            {errors.accountType && (
              <p className="text-red-500 text-sm mt-1">{errors.accountType}</p>
            )}
          </div>
          {/* Subject */}
          <div>
            <p className="text-darkGray font-medium">Subject</p>
            <input
              type="text"
              className="border border-gray-400 w-full mt-2 py-2 px-2 rounded-lg"
              placeholder="Enter subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            {errors.subject && (
              <p className="text-red-500 text-sm mt-1">{errors.subject}</p>
            )}
          </div>
          {/* Description */}
          <div>
            <p className="text-darkGray font-medium ">Description</p>
            <textarea
              cols={40}
              rows={8}
              placeholder="Describe your query"
              className="border border-gray-400 w-full mt-2 py-2 px-2 rounded-lg"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            {errors.description && (
              <p className="text-red-500 text-sm mt-1">{errors.description}</p>
            )}
            <p>
              Please enter the details of your request. A member of our support
              staff will respond as soon as possible.
            </p>
          </div>
          {/* File Attachment Section */}
          <div>
            <p className="text-darkGray font-medium">Attachment</p>
            <div className="border border-gray-400 relative w-full mt-2 flex justify-between items-center py-2 px-2 rounded-lg">
              <h1 className="text-primary underline font-medium">Add file</h1>
              <MdOutlineAttachFile className="text-primary" />
              <input
                type="file"
                className="absolute w-full h-full top-0 left-0 opacity-0"
                onChange={handleFileChange}
              />
            </div>
            {/* Show file name if selected */}
            {fileName && (
              <p className="text-sm mt-2">Attached File: {fileName}</p>
            )}
            {fileUploading && (
              <p className="text-sm mt-2 text-blue-500">Uploading file...</p>
            )}
          </div>

          <div className="flex justify-center w-full mt-6">
            <button
              className="bg-primary w-[65%] rounded-lg text-white p-2 px-6"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
