import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import TypeOfVenue from "../components/TypeOfVenue";
import IndividualFlow from "./IndividualFlow";
import MultipleFlow from "./MultipleFlow";
import maxStepsList from "../../../constants/maxStepsList";
import { fetchLoggedUser } from "../../../api/loggedUser";

function VenueFlow({
  activeStep,
  handleContinue,
  toggleBackButton,
  isBackButtonClicked,
  resetBackButtonClicked,
  setMaxStepsCallback,
}) {
  const [selectedButton, setSelectedButton] = useState(null);
  const [user, setUser] = useState();
  console.log("user", user);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchLoggedUser();
        console.log(response.vendor);
        setUser(response.vendor);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);
  // For Venue Flows
  const [displayIndividualFlow, setDisplayIndividualFlow] = useState(false);
  const [displayMultipleFlow, setDisplayMultipleFlow] = useState(false);

  // For Individual Flow
  const handleButton1Click = () => {
    setSelectedButton(1);
    setDisplayIndividualFlow(true);
    // A  callback function here
    setMaxStepsCallback(maxStepsList.Individual);
    handleContinue();
  };

  // For Multiple Flow
  const handleButton2Click = () => {
    setSelectedButton(2);
    setDisplayMultipleFlow(true);

    // A  callback function here
    setMaxStepsCallback(maxStepsList.Multiple);
    handleContinue();
  };

  useEffect(() => {
    if (activeStep === 1) {
      setDisplayIndividualFlow(false);
      setDisplayMultipleFlow(false);
    }
  }, [activeStep]);

  return (
    <>
      {activeStep === 1 && (
        <Box sx={{ width: { xs: "100%", md: "35%" } }}>
          <TypeOfVenue
            title={"Type of Venue"}
            description={
              "Choose the type of Venue you would want to list, this will help us find a suitable plan for your needs"
            }
            buttonLabel1={"Individual Venue"}
            buttonLabel2={"Multiple Venue"}
            tooltip1={
              "An independent event venue, that is a dedicated space designed to host various events and gatherings, not affiliated with restaurant services. These venues are characterized by their unique ambience, and flexible layout, making them ideal for a wide range occasions."
            }
            tooltip2={
              "A multi-venue is a complex or facility that houses multiple event spaces within the same location."
            }
            handleButton1Click={handleButton1Click}
            handleButton2Click={handleButton2Click}
            selectedButton={selectedButton}
          />
        </Box>
      )}

      {displayIndividualFlow && (
        <IndividualFlow
          activeStep={activeStep}
          handleContinue={handleContinue}
          toggleBackButton={toggleBackButton}
          isBackButtonClicked={isBackButtonClicked}
          resetBackButtonClicked={resetBackButtonClicked}
        />
      )}
      {displayMultipleFlow && (
        <MultipleFlow
          activeStep={activeStep}
          handleContinue={handleContinue}
          toggleBackButton={toggleBackButton}
          isBackButtonClicked={isBackButtonClicked}
          resetBackButtonClicked={resetBackButtonClicked}
          user={user}
        />
      )}
    </>
  );
}

export default VenueFlow;
