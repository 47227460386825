import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SlArrowRight, SlArrowLeft } from 'react-icons/sl';
import {
	activeCorporate,
	party,
	activeParty,
	activeStage,
	stage,
	activeFamily,
	activeGroup,
	activeMeating,
	activeReteat,
	activeTraining,
	activeWeddings,
	activeWorkShop,
	corporate,
	family,
	group,
	meating,
	retreat,
	training,
	weddings,
	workShop,
	tea,
	activeTea,
	camera,
	activeCamera,
	table,
	activeTable,
	office,
	activeOffice,
	museum,
	activeMuseum,
	birthday,
	activeBirthday,
	playground,
	activePlayground,
	celebrations,
	activeCelebrations,
} from '../../svgs';
import img1 from '../../assets/pics/hotel1.png';
import img2 from '../../assets/pics/hotel2.png';
import img3 from '../../assets/pics/hotel3.png';
import img4 from '../../assets/pics/hotel4.png';
import HotelDetailComp from './HotelDetailComp';

const provideData = [
	{
		images: [{ img1: img1 }, { img1: img1 }, { img1: img1 }],
		name: 'Indigo Spaces (coming soon)',
		loc: 'Dubai, Downtown',
		room: '350 Rooms',
		venu: '5 venues',
		aed: 'AED 105',
		price: 'Starting price from per room per night',
	},
	{
		images: [{ img1: img2 }, { img1: img2 }, { img1: img2 }],
		name: 'The Palm (coming soon)',
		loc: 'Dubai, Downtown',
		room: '350 Rooms',
		venu: '5 venues',
		aed: 'AED 105',
		price: 'Starting price from per room per night',
	},
	{
		images: [{ img1: img3 }, { img1: img3 }, { img1: img3 }],
		name: 'Luxe (coming soon)',
		loc: 'Dubai, Downtown',
		room: '350 Rooms',
		venu: '5 venues',
		aed: 'AED 105',
		price: 'Starting price from per room per night',
	},
	{
		images: [{ img1: img4 }, { img1: img4 }, { img1: img4 }],
		name: 'Turquoise (coming soon)',
		loc: 'Dubai, Downtown',
		room: '350 Rooms',
		venu: '5 venues',
		aed: 'AED 105',
		price: 'Starting price from per room per night',
	},
];

const HotelCarousel = () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const CustomNextArrow = ({ onClick }) => (
		<div
			className='absolute sm:-right-10  sm:top-1/2 mt-2 transform sm:-translate-y-1/2 cursor-pointer z-10'
			onClick={onClick}
		>
			<SlArrowRight className='text-[#fe4747] text-xl' />
		</div>
	);

	const CustomPrevArrow = ({ onClick }) => (
		<div
			className='absolute sm:-left-10  sm:top-1/2 mt-2 transform sm:-translate-y-1/2 cursor-pointer z-10'
			onClick={onClick}
		>
			<SlArrowLeft className='text-[#fe4747] text-xl' />
		</div>
	);

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 8,
		slidesToScroll: 1,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
		afterChange: (current) => setActiveIndex(current),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1,
					infinite: false,
					dots: false,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 640,
				settings: {
					arrows: false,
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
		],
	};

	const data = [
		{
			icon: corporate,
			activeIcon: activeCorporate,
			name: 'Corporate Events',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: party,
			activeIcon: activeParty,
			name: 'Party',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: stage,
			activeIcon: activeStage,
			name: 'Auditorium',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: table,
			activeIcon: activeTable,
			name: 'Restaurant',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: tea,
			activeIcon: activeTea,
			name: 'Cafe',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: camera,
			activeIcon: activeCamera,
			name: 'Photoshoot',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: office,
			activeIcon: activeOffice,
			name: 'Hot Desk',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: weddings,
			activeIcon: activeWeddings,
			name: 'Weddings',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: meating,
			activeIcon: activeMeating,
			name: 'Meetings',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: museum,
			activeIcon: activeMuseum,
			name: 'Museum',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: birthday,
			activeIcon: activeBirthday,
			name: 'Birthday Party',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: playground,
			activeIcon: activePlayground,
			name: 'Kids Activities',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: meating,
			activeIcon: activeMeating,
			name: 'Company Retreats',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: corporate,
			activeIcon: activeCorporate,
			name: 'Off-Sites',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: training,
			activeIcon: activeTraining,
			name: 'Training Programs',
			component: <HotelDetailComp data={provideData} />,
		},

		{
			icon: retreat,
			activeIcon: activeReteat,
			name: 'Wellness Retreats',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: family,
			activeIcon: activeFamily,
			name: 'Family Reunions',
			component: <HotelDetailComp data={provideData} />,
		},

		{
			icon: group,
			activeIcon: activeGroup,
			name: 'Group Getaways',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: workShop,
			activeIcon: activeWorkShop,
			name: 'Workshops',
			component: <HotelDetailComp data={provideData} />,
		},
		{
			icon: celebrations,
			activeIcon: activeCelebrations,
			name: 'Celebrations',
			component: <HotelDetailComp data={provideData} />,
		},
	];

	return (
		<div className='relative w-full'>
			<Slider {...settings}>
				{data.map((e, i) => (
					<div key={i} className='px-2 w-full'>
						<div
							className={`bg-[#F7F7F7] cursor-pointer sm:rounded-lg rounded-full  ${
								i === activeIndex
									? 'sm:border-b-4 border-none sm:rounded-b-lg rounded-b-full border-primary'
									: 'border-b-none'
							}  flex flex-col items-center justify-center py-2 px-4`}
							onClick={() => setActiveIndex(i)}
						>
							<span className='sm:block hidden'>
								{i === activeIndex ? e.activeIcon : e.icon}
							</span>
							<h1
								className={`${
									i === activeIndex
										? ' text-[#fe4747]'
										: 'text-darkGray'
								} sm:text-base text-xs whitespace-nowrap sm:px-0 px-3 font-medium`}
							>
								{e.name}
							</h1>
						</div>
					</div>
				))}
			</Slider>
			<div className='mt-4 flex'>
				{/* Render the component corresponding to the active tab */}
				{data[activeIndex] && data[activeIndex].component}
			</div>

			<div className='flex justify-center w-full my-10'>
				<button className='bg-primary text-white rounded-lg w-[120px] py-2'>
					Explore More
				</button>
			</div>
		</div>
	);
};

export default HotelCarousel;
