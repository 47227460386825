import * as React from 'react';
import { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import OutlinedLabelledTextField from '../../components/OutlinedLabelledTextfield';
import backgroundIcon from '../../assets/images/background_icon.svg';
import { useTheme } from '@mui/material/styles';
import { login } from '../../api/login.request';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import StyledInput from '../../components/StyledInput ';
import { base_URL } from '../../config/config';
import { fetchLoggedUser } from '../../api/loggedUser';
function LoginActivator() {
	const { email } = useParams();
	const [searchParams] = useSearchParams();
	const theme = useTheme();
	const [createPassword, setCreatePassword] = useState('');
	const [error, setError] = useState(''); // New state for error message
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const vendorId = searchParams.get('vendorId');

	console.log('Email:', email);
	console.log('Vendor ID:', vendorId);
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (createPassword.length < 8) {
			toast.error('Password must be at least 8 characters long.');
			return;
		}

		try {
			// Step 1: Activate the account
			const response = await fetch(
				`${base_URL}/api/vendor/auth/activate-account`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email,
						password: createPassword,
						vendorId,
					}),
				}
			);

			const data = await response.json();

			if (response.ok) {
				toast.success(data.message);

				// Step 2: Perform the login action
				const loginData = {
					email,
					password: createPassword,
				};

				const loginResponse = await login(loginData);
				console.log('login response', loginResponse);

				// Store tokens and vendor ID
				localStorage.setItem(
					'accessToken',
					loginResponse.accessToken
				);
				localStorage.setItem('vendorId', loginResponse.vendor.id);

				// Step 3: Check if assignedVenues array is empty or not
				if (
					loginResponse?.vendor?.assignedVenues &&
					loginResponse?.vendor?.assignedVenues?.length > 0
				) {
					// If venues are assigned, send the email and navigate to dashboard
					await fetch(`${base_URL}/auth/send-email`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ email, vendorId }),
					});

					navigate('/dashboard');
				} else {
					// If no venues are assigned, navigate to dashboard
					navigate('/dashboard');
				}
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			console.error('Error during account activation:', error);
			toast.error('An unexpected error occurred. Please try again.');
		}
	};

	return (
		<>
			<ResponsiveAppBar />
			<div
				style={{
					backgroundImage: `url(${backgroundIcon})`,
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'right',
					minHeight: '75vh',
				}}
			>
				<Container>
					<Box
						sx={{
							mt: 2,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
					>
						<Typography
							variant='h4'
							gutterBottom
							sx={{
								mt: { xs: 7, md: 10 },
								fontWeight: 'bold',
								fontSize: { xs: '1.6rem' },
							}}
						>
							Activate{' '}
							<span style={{ color: theme.palette.primary.main }}>
								Your Account
							</span>
						</Typography>
						<Typography
							variant='body1'
							gutterBottom
							sx={{ mb: { md: 4 }, color: 'grey.text' }}
						>
							Welcome back! Enter password and activate your account
						</Typography>
					</Box>
				</Container>
				<Container
					maxWidth='sm'
					sx={{ display: 'flex', justifyContent: 'center' }}
				>
					<Box sx={{ mt: 7, width: { xs: '95%', md: '80%' } }}>
						<form onSubmit={handleSubmit}>
							<StyledInput
								id='workMailPlain'
								label='Work Email'
								placeholder='user@workapp.com'
								type='email'
								value={email}
							/>
							<OutlinedLabelledTextField
								id='createPassword'
								label='Password'
								placeholder='Enter your password'
								type={showPassword ? 'text' : 'password'}
								value={createPassword}
								onChange={(e) => setCreatePassword(e.target.value)}
								eye={true}
								showPassword={showPassword}
								togglefunction={togglePasswordVisibility}
							/>

							<Button
								type='submit'
								variant='contained'
								fullWidth
								size='large'
								sx={{
									mb: { xs: 1, md: 3 },
									borderRadius: '8px',
									mt: 2,
								}}
							>
								Activate
							</Button>
						</form>
					</Box>
				</Container>
			</div>
		</>
	);
}

export default LoginActivator;
