import React, { useState, useContext, useEffect, useRef } from "react";
import { FaTimes, FaUpload, FaFilePdf } from "react-icons/fa";
import CustomCheckbox from "../shared/common/checkBox";
import AWS from "aws-sdk";
import { VenueContext } from "../../context/VenueContext";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import CloseableToolTip from "./CloseableToolTip";
import { Tooltip } from "@mui/material";

const documents = [
  {
    toShow: "Floor Plans",
    name: "floorPlans",
    optional: false,
    multiple: true,
  },
  {
    toShow: "Trade License",
    name: "tradeLicense",
    optional: false,
    multiple: false,
  },
  {
    toShow: "Sample Menus",
    name: "sampleMenus",
    optional: true,
    multiple: true,
  },
  {
    toShow: "Brochure",
    name: "brochure",
    optional: true,
    multiple: true,
  },
];

const S3_BUCKET = "st-findmyvenue";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIATCKAPHDJJECWAMJF",
  secretAccessKey: "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",
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const UploadDocuments = () => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploading, setUploading] = useState({});
  const [progress, setProgress] = useState({});
  const [errors, setErrors] = useState({});
  const { formData, setFormData } = useContext(VenueContext);
  const inputRefs = useRef({});
  useEffect(() => {
    const savedUploadedFiles = Cookies.get("uploadedFiles");
    if (savedUploadedFiles) {
      const parsedFiles = JSON.parse(savedUploadedFiles);
      setUploadedFiles(parsedFiles);
      setSelectedDocuments(Object.keys(parsedFiles));
    }
  }, []);

  useEffect(() => {
    Cookies.set("uploadedFiles", JSON.stringify(uploadedFiles), {
      expires: 7,
    });
  }, [uploadedFiles]);

  const toggleDocument = (document) => {
    setSelectedDocuments((prev) => {
      // Toggle the selected document
      const updatedSelectedDocuments = prev.includes(document)
        ? prev.filter((doc) => doc !== document)
        : [...prev, document];

      // Update the formData with the latest selected documents
      setFormData((prevFormData) => ({
        ...prevFormData,
        UploadDocuments: {
          ...prevFormData.UploadDocuments,
          selectedDocuments: updatedSelectedDocuments,
        },
      }));

      return updatedSelectedDocuments;
    });
  };

  const handleFileUpload = (file, document) => {
    setUploading((prev) => ({ ...prev, [document]: true }));
    setProgress((prev) => ({ ...prev, [document]: 0 }));

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${document}/${file.name}`,
    };

    myBucket
      .upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err.message);
          setUploading((prev) => ({ ...prev, [document]: false }));
          setProgress((prev) => ({ ...prev, [document]: 0 }));
          // setError("Error uploading file.");

          return;
        }
        console.log("File uploaded successfully:", data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          UploadDocuments: {
            ...prevFormData.UploadDocuments,
            [document]: [
              ...(prevFormData.UploadDocuments[document] || []),
              data.Location,
            ],
          },
        }));

        setUploadedFiles((prev) => ({
          ...prev,
          [document]: [
            ...(prev[document] || []),
            { url: data.Location, name: file.name }, // Store both url and name
          ],
        }));
        setUploading((prev) => ({ ...prev, [document]: false }));
        setProgress((prev) => ({ ...prev, [document]: 0 }));
      })
      .on("httpUploadProgress", (evt) => {
        setProgress((prev) => ({
          ...prev,
          [document]: Math.round((evt.loaded / evt.total) * 100),
        }));
      });
  };

  const handleFileChange = (e, document) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      const isBrochure = document === "brochure";

      // Set the maximum file size
      const maxFileSize = isBrochure ? 5 * 1024 * 1024 : 2 * 1024 * 1024;

      // Allowed file types: for brochures, allow images and PDFs, otherwise only PDFs
      const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];

      // Check for file size
      if (files.some((file) => file.size > maxFileSize)) {
        setErrors((prev) => ({
          ...prev,
          [document]: `File size should not exceed ${
            isBrochure ? "5 MB" : "2 MB"
          }.`,
        }));
        return;
      }

      // Check for file type
      if (files.some((file) => !allowedFileTypes.includes(file.type))) {
        setErrors((prev) => ({
          ...prev,
          [document]: "Only PDF, JPG, and PNG files are allowed.",
        }));
        return;
      }

      // Clear previous errors
      setErrors((prev) => ({ ...prev, [document]: "" }));

      // Handle file upload: brochure can have multiple files
      if (documents.find((doc) => doc.name === document)?.multiple) {
        files.forEach((file) => handleFileUpload(file, document));
      } else {
        if (uploadedFiles[document] && uploadedFiles[document].length > 0) {
          setErrors((prev) => ({
            ...prev,
            [document]: "Only 1 file is allowed for this document.",
          }));
          return;
        }
        handleFileUpload(files[0], document);
      }

      // Clear the file input so the same file can be uploaded again if needed
      e.target.value = null;
    }
  };

  const removeFile = (document, fileUrl) => {
    console.log(uploadedFiles);

    // Update uploadedFiles state
    setUploadedFiles((prev) => {
      const newFiles = { ...prev };
      newFiles[document] = newFiles[document].filter((url) => url !== fileUrl);
      return newFiles;
    });

    // Also update formData to remove the file link
    setFormData((prevFormData) => {
      const updatedDocumentFiles =
        prevFormData.UploadDocuments[document]?.filter(
          (url) => url !== fileUrl
        ) || [];
      return {
        ...prevFormData,
        UploadDocuments: {
          ...prevFormData.UploadDocuments,
          [document]: updatedDocumentFiles,
        },
      };
    });
  };

  const handleFileDrop = (event, document) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files.length > 0) {
      handleFileChange({ target: { files } }, document);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const UploadDocumentsData = formData?.UploadDocuments || {};
  return (
    <div className="p-8 mx-auto">
      <h1 className="text-2xl font-bold mb-4">Upload Documents</h1>
      <p className="mb-4">Select & upload documents</p>
      <div className="space-y-6">
        <div className="">
          {Object.entries(formData?.UploadDocuments?.errors || {}).map(
            ([docName, errorMessage]) => {
              // Convert docName to uppercase with spaces between words
              const formattedDocName = docName
                .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before capital letters
                .toUpperCase();

              return (
                <p key={docName} className="text-red-500 text-sm">
                  {formattedDocName} IS REQUIRED
                </p>
              );
            }
          )}
        </div>
        {documents.map((doc) => (
          <div key={doc.name} className="space-y-4 w-full">
            <div className="flex gap-4 items-center mt-1 w-full">
              <CustomCheckbox
                label=""
                checked={selectedDocuments?.includes(doc.name)}
                onChange={() => toggleDocument(doc.name)}
              />
              <span className="font-semibold flex gap-x-2">
                {doc?.toShow === "Sample Menus" ||
                doc?.toShow === "Brochure" ? (
                  <div className="flex items-center gap-4">
                    {doc?.toShow}
                    <Tooltip
                      title="Sample Menu & Brochure Including sample menus and a brochure is beneficial, as it gives clients insight into your offerings and provides a more detailed view of the venue."
                      arrow
                    >
                      <img src="/Info.svg" width={24} height={24} alt="info" />
                    </Tooltip>
                    {!doc?.optional ? (
                      <span className="text-red-500 ml-1">*</span>
                    ) : (
                      <span className="text-gray-500">(Optional)</span>
                    )}
                  </div>
                ) : (
                  <div className="flex items-center space-x-2">
                    <div>{doc?.toShow ?? ""}</div>
                    {!doc?.optional ? (
                      <span className="text-red-500 ml-1">*</span>
                    ) : (
                      <span className="text-gray-500">(Optional)</span>
                    )}
                  </div>
                )}
              </span>
            </div>

            {selectedDocuments.includes(doc.name) && (
              <div className="flex gap-5 mt-0 w-full">
                <div
                  className={`border-2 lg:w-1/2 w-full relative border-dashed rounded-lg mt-0 ${
                    selectedDocuments.includes(doc.name)
                      ? "border-gray-400"
                      : "border-gray-200"
                  }`}
                  onDrop={(e) => handleFileDrop(e, doc.name)}
                  onDragOver={handleDragOver}
                >
                  <div className="flex flex-col items-center space-y-2 cursor-pointer p-4">
                    <FaUpload className="text-gray-400 text-2xl" />
                    <label className="text-red-500 cursor-pointer">
                      <input
                        ref={(el) => (inputRefs.current[doc.name] = el)}
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png"
                        className="opacity-0 absolute w-full h-full cursor-pointer"
                        multiple={
                          documents.find((doco) => doco.name === doc.name)
                            ?.multiple
                        }
                        onChange={(e) => handleFileChange(e, doc.name)}
                      />
                      Click to upload
                    </label>
                    <span className="text-gray-500">or drag and drop</span>
                    <span className="text-gray-400 text-sm">
                      {doc.name === "brochure"
                        ? "PDF, JPG, PNG (up to 5 MB)"
                        : "PDF, JPG, PNG (up to 2 MB)"}
                    </span>
                  </div>
                  <div className="mt-4 p-4 w-full">
                    {uploading[doc.name] && (
                      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{
                            width: `${progress[doc.name] || 0}%`,
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Horizontal layout for uploaded files */}
                <div className="flex flex-wrap gap-4">
                  {(uploadedFiles[doc?.name] || []).map(({ url, name }) => (
                    <div
                      key={url}
                      className="relative w-24 h-32 overflow-hidden flex flex-col items-center border border-gray-300 rounded-lg shadow-sm p-3"
                    >
                      <Link
                        to={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex-grow flex items-center justify-center"
                      >
                        {url?.toLowerCase().endsWith(".pdf") ? (
                          <FaFilePdf className="text-red-500 text-4xl" />
                        ) : (
                          <img
                            src={url}
                            alt="Uploaded file"
                            className="object-cover w-full h-full rounded-lg"
                          />
                        )}
                      </Link>
                      <div className="bg-white p-1 rounded-md shadow mt-1">
                        <span className="text-[10px] text-gray-700">
                          {name}
                        </span>
                      </div>
                      <button
                        onClick={() => removeFile(doc?.name, url)}
                        className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1"
                      >
                        <FaTimes />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {errors[doc.name] && (
              <p className="text-red-500 text-sm">{errors[doc.name]}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadDocuments;
