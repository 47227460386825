import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { base_URL } from "../../../config/config";
import axios from "axios";
import { MdScatterPlot } from "react-icons/md";

function CardInfo({ user }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [last4, setLast4] = useState("");
  const [name, setName] = useState("");
  useEffect(() => {
    const fetchStoredCardDetails = async () => {
      try {
        const response = await axios.get(
          `${base_URL}/get-card-details/${user.email}`
        );

        if (response.data.success) {
          setLast4(response.data.card.last4);
          setName(response.data.name);
        } else {
          toast.error("Failed to fetch card details");
        }
      } catch (error) {
        console.error("Error fetching card details:", error);
        toast.error("Something went wrong fetching card details.");
      }
    };

    fetchStoredCardDetails();
  }, [user.email]);

  const handleUpdateCard = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        toast.error(error.message);
        setLoading(false);
        return;
      }

      // Send payment method ID and user email to the backend
      const response = await updateCardOnBackend(
        user.email,
        paymentMethod.id,
        name
      );

      if (response.success) {
        toast.success("Card details updated successfully!");
        setLast4(paymentMethod.card.last4); // Update UI with new last4
      } else {
        toast.error("Failed to update card");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }

    setLoading(false);
  };

  // API call to update card on backend
  const updateCardOnBackend = async (email, paymentMethodId, name) => {
    try {
      const response = await axios.post(`${base_URL}/update-card`, {
        email,
        paymentMethodId,
        name,
      });
      console.log(response.data, "Backend Response");
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Error:", error.response.data);
        return error.response.data;
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
      throw error;
    }
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg border border-[#DDDDDD]">
      <h2 className="text-[18px] font-semibold mb-4">Card Information</h2>

      {/* Display last 4 digits of card */}
      <div className="mb-4">
        <label className="block text-[14px] font-medium text-[#5E5E5E]">
          Card ending in:
        </label>
        <div className="mt-1 text-[16px] font-semibold">
          **** **** **** {last4}
        </div>
      </div>

      {/* Name Input and Stripe Card Element in one row */}
      <div className="mb-4 flex justify-between flex-col md:flex-row gap-4 w-fyll">
        <div className="w-[100%] ">
          <label className="block text-[14px]  font-medium text-[#5E5E5E]">
            Name on card:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)} // Update name on change
            className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-150 ease-in-out"
            placeholder="Enter your name"
          />
        </div>

        <div className="w-full ">
          <label className="block text-[14px] font-medium text-[#5E5E5E]">
            Enter New Card Information
          </label>
          <div className="mt-1 p-3 border border-gray-300 rounded-md">
            <CardElement options={{ hidePostalCode: true }} />
          </div>
        </div>
      </div>

      {/* Update Button */}
      <div className="mt-6 flex justify-center">
        {loading ? (
          <button className="text-[14px] bg-gray-400 flex gap-x-2 justify-center items-center active:opacity-55 text-white p-2 rounded-lg w-full md:w-[250px] h-[40px]">
            Updating <MdScatterPlot className="animate-spin" />
          </button>
        ) : (
          <button
            className="text-[14px] bg-red-500 text-white w-full md:w-[250px] h-[40px] rounded-md hover:bg-red-600 transition duration-150 ease-in-out"
            onClick={handleUpdateCard}
            disabled={loading}
          >
            Update Card Information
          </button>
        )}
      </div>
    </div>
  );
}

export default CardInfo;
