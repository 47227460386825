// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-list {
	/* counter-reset: list-counter; Reset the counter */
	list-style-type: lower-alpha;
}`, "",{"version":3,"sources":["webpack://./src/pages/onboarding/TnC.css"],"names":[],"mappings":"AAAA;CACC,mDAAmD;CACnD,4BAA4B;AAC7B","sourcesContent":[".custom-list {\n\t/* counter-reset: list-counter; Reset the counter */\n\tlist-style-type: lower-alpha;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
