import React from "react";
import Slider from "react-slick";
import img1 from "../../assets/pics/uniq1.svg";
import img2 from "../../assets/pics/uniq2.svg";
import img3 from "../../assets/pics/uniq3.svg";
import img4 from "../../assets/pics/uniq4.svg";
import img5 from "../../assets/pics/uniq5.svg";
import img6 from "../../assets/pics/uniq6.svg";
import img7 from "../../assets/pics/uniq7.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const UniqueSpace = () => {
  const data = [
    { icon: img1, name: "Corporate Events", venues: "1539 Venues" },
    { icon: img2, name: "Weddings", venues: "1539 Venues" },
    { icon: img3, name: "Meetings", venues: "5893 Venues" },
    { icon: img4, name: "Birthday Parties", venues: "729 Venues" },
  ];

  const data2 = [
    { icon: img5, name: "Conferences", venues: "1539 Venues" },
    { icon: img6, name: "Proposals", venues: "729 Venues" },
    { icon: img7, name: "Kids Activities", venues: "5893 Venues" },
  ];

  const combinedData = [...data, ...data2];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 768, // md breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex justify-center pt-20 bg-[#F5F7F8] px-4 sm:px-28">
      <div className="w-[90%]">
        <h1 className="sm:text-4xl text-2xl text-darkGray font-semibold text-center my-4 pb-6">
          {/* Discover unique venues for your next event */}
          Discover unique event spaces
        </h1>
        <div className="hidden md:grid grid-cols-4 gap-6 mt-20">
          {data.map((e, i) => (
            <div
              key={i}
              className="border-2 border-[#BDBDBD] py-8 relative flex flex-col items-center rounded-lg"
            >
              <div className="absolute -top-16 p-2 bg-[#F5F7F8]">
                <img src={e.icon} alt="" />
              </div>
              <h1 className="text-lg text-darkGray font-bold">{e.name}</h1>
              <p className="text-secondary font-medium text-sm mt-2">
                {e.venues}
              </p>
            </div>
          ))}
        </div>
        <div className="hidden md:flex justify-center">
          <div className="grid grid-cols-3 gap-6 justify-center w-[80%] mt-24">
            {data2.map((e, i) => (
              <div
                key={i}
                className="border-2 border-[#BDBDBD] py-8 relative flex flex-col items-center rounded-lg"
              >
                <div className="absolute -top-1/2 p-2 bg-[#F5F7F8]">
                  <img src={e.icon} alt="" />
                </div>
                <h1 className="text-lg text-darkGray font-medium">{e.name}</h1>
                <p className="text-secondary font-medium text-sm mt-2">
                  {e.venues}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="md:hidden ">
          <Slider {...settings}>
            {combinedData.map((e, i) => (
              <div key={i} className="flex py-20 px-4 flex-col items-center">
                <div className="border border-[#BDBDBD] py-8 relative flex flex-col items-center rounded-lg">
                  <img src={e.icon} className="absolute h-16 -top-10" alt="" />
                  <h1 className="text-lg text-darkGray font-medium">
                    {e.name}
                  </h1>
                  <p className="text-secondary font-medium text-sm mt-2">
                    {e.venues}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="flex justify-center w-full sm:my-10 my-6">
          <button className="bg-primary text-white rounded-lg w-[120px] py-2">
            {/* View All Spaces */}
            Explore Venues
          </button>
        </div>
      </div>
    </div>
  );
};

export default UniqueSpace;
