import React from 'react';
import restaurantImage from "../../assets/images/restaurants_image.jpg";
import yachtImage from "../../assets/images/yacht_image.jpg";
import ballroomImage from "../../assets/images/ballroom_image.jpg";
import auditoriumImage from "../../assets/images/auditorium_image.jpg";
import museumImage from "../../assets/images/museum_image.jpg";

const ListVenu = () => {
    return (
        <div className='flex justify-center pt-20 pb-10'>
            <div className='w-[90%] md:flex-row flex-col-reverse flex justify-between items-center gap-12' >
                <div className='md:w-1/2 w-full flex flex-col md:items-start items-center xl:pr-16 pr-8 gap-4' >
                    <h1 className='xl:text-5xl lg:text-4xl text-3xl md:text-start text-center font-semibold text-darkGray'>
                        List your venue and expand your reach
                    </h1>
                    <p className='text-secondary md:text-start text-center text-lg mt-2 font-medium'>
                        We are the fastest growing venue search platform in the UAE, providing you direct access to your target customers. Make your venue visible to event planners and venue seekers globally.
                    </p>

                    <div className='flex md:justify-start justify-center w-full sm:my-10 my-6'>
                        <button className='bg-primary text-white rounded-lg w-[120px] py-2'>Get Started</button>
                    </div>
                </div>

                <div className='md:w-1/2 w-full flex gap-3  justify-center'>


                    <div class="upward-slider-container">
                        <div class="upward-slider">
                            <img src={restaurantImage} alt="sliderImage 1" />
                            <img src={yachtImage} alt="sliderImage 1" />
                            <img src={ballroomImage} alt="sliderImage 1" />
                            <img src={auditoriumImage} alt="sliderImage 1" />
                            <img src={museumImage} alt="sliderImage 1" />
                            <img src={restaurantImage} alt="sliderImage 1" />
                            <img src={yachtImage} alt="sliderImage 1" />
                            <img src={ballroomImage} alt="sliderImage 1" />
                            <img src={auditoriumImage} alt="sliderImage 1" />
                            <img src={museumImage} alt="sliderImage 1" />
                        </div>
                    </div>

                    <div class="downward-slider-container">
                        <div class="downward-slider">
                            <img src={restaurantImage} alt="sliderImage 1" />
                            <img src={yachtImage} alt="sliderImage 1" />
                            <img src={ballroomImage} alt="sliderImage 1" />
                            <img src={auditoriumImage} alt="sliderImage 1" />
                            <img src={museumImage} alt="sliderImage 1" />
                            <img src={restaurantImage} alt="sliderImage 1" />
                            <img src={yachtImage} alt="sliderImage 1" />
                            <img src={ballroomImage} alt="sliderImage 1" />
                            <img src={auditoriumImage} alt="sliderImage 1" />
                            <img src={museumImage} alt="sliderImage 1" />
                        </div>
                    </div>

                    <div class="upward-slider-container">
                        <div class="upward-slider">
                            <img src={restaurantImage} alt="sliderImage 1" />
                            <img src={yachtImage} alt="sliderImage 1" />
                            <img src={ballroomImage} alt="sliderImage 1" />
                            <img src={auditoriumImage} alt="sliderImage 1" />
                            <img src={museumImage} alt="sliderImage 1" />
                            <img src={restaurantImage} alt="sliderImage 1" />
                            <img src={yachtImage} alt="sliderImage 1" />
                            <img src={ballroomImage} alt="sliderImage 1" />
                            <img src={auditoriumImage} alt="sliderImage 1" />
                            <img src={museumImage} alt="sliderImage 1" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ListVenu;
