import React, { useState, useContext, useEffect } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import CustomCheckbox from "../../shared/common/checkBox";
import FileUpload from "./fileUpload";
import { HotelContext } from "../../../context/HotelContext";
import ToggleSwitch from "../../shared/common/custom-toggle";
import { IoTrashOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "@mui/material";
import FileUploadBrochure from "./fileUploadBrochure";

const eventTypes = [
  {
    id: 1,
    name: "Company Retreats",
    description:
      "Events where employees gather for team-building activities, workshops, and meetings. Seek venues that offer lodging, meeting rooms, dining facilities, and recreational amenities.",
  },
  {
    id: 2,
    name: "Corporate Off-sites",
    description:
      "Meetings or strategic planning sessions held off-site for focused discussions and activities. Prefer venues with conference rooms, accommodations, and catering services.",
  },
  {
    id: 3,
    name: "Training Programs",
    description:
      "Multi-day training sessions requiring accommodations for participants. Look for venues with classrooms, training facilities, and lodging options.",
  },
  {
    id: 4,
    name: "Executive Meetings",
    description:
      "High-level meetings or board retreats requiring privacy and exclusive amenities. Seek venues with executive suites, private dining areas, and advanced AV technology.",
  },
  {
    id: 5,
    name: "Family Reunions",
    description:
      "Gatherings where families stay together and host events such as dinners, parties, or ceremonies. Prefer venues with vacation rentals, communal spaces, and outdoor activities.",
  },
  {
    id: 6,
    name: "Destination Weddings",
    description:
      "Weddings held at venues that offer both ceremony and reception spaces along with guest accommodations. Look for venues with event spaces, wedding packages, and guest amenities.",
  },
  {
    id: 7,
    name: "Group Getaways",
    description:
      "Trips organized for groups of friends or colleagues looking for a combination of lodging and event spaces. Seek venues with shared accommodations, group dining options, and recreational activities.",
  },
  {
    id: 8,
    name: "Wellness Retreats",
    description:
      "Retreats focused on health and wellness activities such as yoga, meditation, and spa treatments. Prefer venues with wellness facilities, healthy dining options, and tranquil surroundings.",
  },
  {
    id: 9,
    name: "Educational Camps or Workshops",
    description:
      "Residential programs for educational purposes such as arts camps or academic workshops. Look for venues with dormitory-style accommodations and classroom facilities.",
  },
  {
    id: 10,
    name: "Special Events or Celebrations",
    description:
      "Milestone events like anniversaries, birthdays, or reunions that require overnight stays and event spaces. Seek venues with event management services, customizable packages, and entertainment options.",
  },
];

const AdditionalDetailsForm = ({ awards, setAwards, errors, setErrors }) => {
  const { formData, setFormData } = useContext(HotelContext);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [isSustainable, setIsSustainable] = useState(false);
  const [hotelBuiltYear, setHotelBuiltYear] = useState(null);
  const [lastRenovatedYear, setLastRenovatedYear] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (formData.overview?.additionalDetails) {
      setSelectedEventTypes(
        formData.overview.additionalDetails.selectedEventTypes || []
      );
      setIsSustainable(
        formData.overview.additionalDetails.isSustainable || false
      );
      setHotelBuiltYear(
        formData.overview.additionalDetails.hotelBuiltYear || ""
      );
      setLastRenovatedYear(
        formData.overview.additionalDetails.lastRenovatedYear || ""
      );
      setAwards(
        formData.overview.additionalDetails.awards || [{ name: "", year: "" }]
      );
      setSelectAll(
        formData.overview.additionalDetails.selectedEventTypes?.length ===
          eventTypes.length
      );
    }
  }, [formData.overview.additionalDetails, setAwards]);

  const updateAdditionalDetails = (updatedDetails) => {
    setFormData({
      ...formData,
      overview: {
        ...formData.overview,
        additionalDetails: {
          ...formData.overview.additionalDetails,
          ...updatedDetails,
        },
      },
    });
  };

  const validateAwards = () => {
    const newErrors = { ...errors };
    const awardErrors = [];

    awards.forEach((award, index) => {
      if (!award.name.trim()) {
        awardErrors[index]["name"] = "Award name is required.";
      } else if (!award.year) {
        awardErrors[index]["year"] = "Award year is required.";
      } else {
        awardErrors[index] = "";
      }
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      awards: awardErrors,
    }));

    return awardErrors.every((error) => !error);
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      const allEventTypes = eventTypes.map((event) => event.name);
      setSelectedEventTypes(allEventTypes);
    } else {
      setSelectedEventTypes([]);
    }
    setSelectAll(checked);
    updateAdditionalDetails({
      selectedEventTypes: checked ? eventTypes.map((event) => event.name) : [],
    });
  };

  const handleEventTypeClick = (id, name) => {
    // console.log("name", name);
    const newSelectedEventTypes = selectedEventTypes.includes(name)
      ? selectedEventTypes.filter((eventName) => eventName !== name)
      : [...selectedEventTypes, name];

    setSelectedEventTypes(newSelectedEventTypes);
    updateAdditionalDetails({
      selectedEventTypes: newSelectedEventTypes,
    });
    setSelectAll(newSelectedEventTypes.length === eventTypes.length);
  };

  const handleAwardChange = (index, field, value) => {
    const newAwards = [...awards];
    newAwards[index][field] = value;
    setAwards(newAwards);
    updateAdditionalDetails({ awards: newAwards });
  };

  const handleAddAward = () => {
    const newAwards = [...awards, { name: "", year: "" }];
    setAwards(newAwards);
    updateAdditionalDetails({ awards: newAwards });
  };

  const handleRemoveAward = (index) => {
    const newAwards = awards.filter((_, i) => i !== index);
    setAwards(newAwards);
    updateAdditionalDetails({ awards: newAwards });
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="p-6 bg-white rounded-lg">
      <h2 className="text-2xl font-semibold text-neutralBlack mb-4">
        Additional Details
      </h2>
      <form className="w-full flex flex-wrap gap-4 justify-between mx-auto">
        {/* Sustainability Checkbox */}
        <div className="w-full flex gap-3 items-center mb-4">
          <label className="text-secondary text-sm mr-4">
            Is the hotel sustainable?
          </label>

          <ToggleSwitch
            isOn={isSustainable}
            handleToggle={() => {
              setIsSustainable(!isSustainable);
              updateAdditionalDetails({
                isSustainable: !isSustainable,
              });
            }}
          />
        </div>

        {/* Hotel Built Year */}
        <div className="lg:w-[47%] w-full mb-4 relative z-50">
          <label className="block text-secondary text-sm mb-2">
            Hotel Built Year{" "}
            <span className="text-red-500 ml-1 text-[15px]">*</span>
          </label>
          <div className="relative">
            <DatePicker
              selected={hotelBuiltYear ? new Date(hotelBuiltYear) : null}
              onChange={(date) => {
                const formattedDate = date?.toISOString()?.split("T")[0];
                setHotelBuiltYear(formattedDate);

                updateAdditionalDetails({
                  hotelBuiltYear: formattedDate,
                  lastRenovatedYear: "",
                  errors: {
                    ...formData?.overview?.additionalDetails?.errors,
                    hotelBuiltYear: "", // Clear the hotelBuiltYear error
                  },
                });
              }}
              dateFormat="MM-yyyy"
              maxDate={new Date()}
              showMonthYearPicker
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              placeholderText="Select month and year"
              className="w-full py-2 px-3 border border-borderShade rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent z-[1]"
            />
          </div>
          {formData.overview?.additionalDetails?.errors?.hotelBuiltYear && (
            <p className="text-red-500 text-sm">
              {formData.overview.additionalDetails.errors.hotelBuiltYear}
            </p>
          )}
        </div>

        {/* Last Renovated Year */}
        <div className="lg:w-[47%] w-full mb-4 relative z-50">
          <label className="block text-secondary text-sm mb-2">
            Hotel Last Renovated Year (if applicable)
          </label>
          <div className="relative">
            <DatePicker
              selected={lastRenovatedYear ? new Date(lastRenovatedYear) : null}
              onChange={(date) => {
                setLastRenovatedYear(date.toISOString().split("T")[0]);
                updateAdditionalDetails({
                  lastRenovatedYear: date.toISOString().split("T")[0],
                });
              }}
              dateFormat="MM-yyyy"
              maxDate={new Date()} // Maximum date is today
              minDate={hotelBuiltYear ? new Date(hotelBuiltYear) : null} // Set minimum date based on hotelBuiltYear
              showMonthYearPicker
              showYearDropdown // Enables year dropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100} // Number of years to display in the dropdown
              placeholderText="Select year"
              className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent z-50"
            />
          </div>
          {formData.overview?.additionalDetails?.errors?.lastRenovatedYear && (
            <p className="text-red-500 text-sm">
              {formData.overview.additionalDetails.errors.lastRenovatedYear}
            </p>
          )}
        </div>

        {/* Event Types */}
        <div className="w-full mb-4 relative z-10">
          <div className="flex justify-between gap-4 items-center text-secondary mb-2">
            <div className="flex items-end gap-3">
              <div className="flex items-center space-x-2">
                <span>Event Type</span>
                <span className="text-red-500">*</span>
                <Tooltip
                  title="Select all the event types your hotel can host, such as weddings, corporate events, meetings, and more."
                  arrow
                >
                  <img src="/Info.svg" width={24} height={24} />
                </Tooltip>
              </div>
              {formData.overview?.additionalDetails?.errors
                ?.selectedEventTypes && (
                <p className="text-red-500 text-sm">
                  {
                    formData.overview.additionalDetails.errors
                      .selectedEventTypes
                  }
                </p>
              )}
            </div>
            <div>
              <CustomCheckbox
                label="Select all"
                checked={selectAll}
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {eventTypes.map((event) => (
              <div
                key={event.id}
                className={`p-4 flex gap-3 items-start border rounded-lg cursor-pointer border-borderShade`}
                onClick={() => handleEventTypeClick(event.id, event.name)}
              >
                <CustomCheckbox
                  checked={selectedEventTypes.includes(event.name)}
                  onChange={() => handleEventTypeClick(event.id, event.name)}
                  className="relative z-999"
                />
                <div>
                  <p className="text-base text-darkGray leading-[24.96px]">
                    {event.name}
                  </p>
                  <p className="text-secondary leading-[24.84px] text-sm">
                    {event.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Awards */}
        <div className="w-full mb-4">
          <label className="flex justify-between items-center text-gray-700 font-medium mb-2">
            <div className="flex items-center space-x-2">
              <p className="text-base text-darkGray font-normal">
                Hotel Awards
              </p>
              <p className="text-offGray text-base font-normal">(Optional)</p>
            </div>
            <button
              type="button"
              onClick={handleAddAward}
              className="text-primary capitalize font-medium text-base underline leading-[25.6px]"
            >
              add more awards
            </button>
          </label>
          {awards?.map((award, index) => (
            <div key={index} className="mb-4">
              <div className="w-full grid grid-cols-1 md:grid-cols-[1fr_1fr_.2fr] gap-4">
                <div className="flex flex-col">
                  <label className=" text-secondary text-sm mb-2">
                    {index + 1}. Award Name
                  </label>
                  <input
                    type="text"
                    value={award.name}
                    onChange={(e) =>
                      handleAwardChange(index, "name", e.target.value)
                    }
                    placeholder="Award Name"
                    className={`py-3 text-black px-3 border border-gray-300 rounded-md ${
                      errors?.awards[index]?.name ? "border-red-500" : ""
                    }`}
                  />
                  {errors?.awards[index]?.name && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors?.awards[index]?.name}
                    </p>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className=" text-secondary text-sm mb-2">Year</label>
                  <input
                    type="date"
                    value={award.year}
                    onChange={(e) =>
                      handleAwardChange(index, "year", e.target.value)
                    }
                    placeholder="Year"
                    className={`py-3 px-3 border border-borderShade rounded-md ${
                      errors?.awards[index]?.year ? "border-red-500" : ""
                    }`}
                    max={getCurrentDate()}
                    min={hotelBuiltYear}
                  />
                  {errors.awards[index]?.year && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.awards[index]?.year}
                    </p>
                  )}
                </div>

                <button
                  onClick={() => handleRemoveAward(index)}
                  className="flex items-center justify-center"
                >
                  <IoTrashOutline size={20} className="mt-5" />
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* File Upload */}
        <div className="w-full mb-4">
          <FileUpload />
        </div>
        <div className="w-full mb-4">
          <FileUploadBrochure />
        </div>

        {/* Submit Button */}
      </form>
    </div>
  );
};

export default AdditionalDetailsForm;
