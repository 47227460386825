import React, { useState, useEffect } from 'react';
import {
	Box,
	Grid,
	TextField,
	Typography,
	MenuItem,
	useTheme,
} from '@mui/material';
import { statesAndCities } from '../../../constants/area-data';

const BillingAddressBox = ({
	handleFormValidation,
	addressLine1,
	setAddressLine1,
	addressLine2,
	setAddressLine2,
	poBox,
	setPoBox,
	city,
	setCity,
	area,
	setArea,
	country,
	setCountry,
  setBillingInformation
}) => {
	const theme = useTheme();

	const cities = [
		'Dubai',
		'Abu Dhabi',
		'Ras Al Khaimah',
		'Sharjah',
		'Ajman',
		'Fujairah',
		'Umm Al Quwain',
	];

	const countryMap = {
		Sharjah: 'United Arab Emirates',
		'Abu Dhabi': 'United Arab Emirates',
		Ajman: 'United Arab Emirates',
		'Umm Al Quwain': 'United Arab Emirates',
		'Ras Al Khaimah': 'United Arab Emirates',
		Fujairah: 'United Arab Emirates',
		Dubai: 'United Arab Emirates',
	};

	useEffect(() => {
		const isValid =
			/[a-zA-Z0-9]/.test(addressLine1.trim()) && // Ensure address line 1 has letters or numbers
			(addressLine2.trim() == '' ||
				/[a-zA-Z0-9]/.test(addressLine2.trim())) && // Ensure address line 2 is empty or has letters/numbers
			city.trim() !== '' &&
			area.trim() !== '' &&
			country.trim() !== '';
		handleFormValidation(isValid);
	}, [
		addressLine1,
		addressLine2,
		city,
		area,
		country,
		handleFormValidation,
	]);

	const handleAddressLine1Change = (event) => {
		setAddressLine1(event.target.value);
    setBillingInformation((prev)=>({...prev, addressLine1: event.target.value}))
	};

	const handleAddressLine2Change = (event) => {
		setAddressLine2(event.target.value);
    setBillingInformation((prev)=>({...prev, addressLine2: event.target.value}))
	};

	const handlePoBoxChange = (event) => {
		const value = event.target.value;
		if (/^[0-9]*$/.test(value)) {
			setPoBox(value);
      setBillingInformation((prev)=>({...prev, poBox: value}))
		}
	};

	const handleCityChange = (event) => {
		const selectedCity = event.target.value;
		setCity(selectedCity);
		setCountry(countryMap[selectedCity] || ''); // Set country based on the selected city
    setBillingInformation((prev)=>({...prev, city: selectedCity, country: countryMap[selectedCity] || ''}))
		setArea(''); // Reset the area when the city changes
	};

	const handleAreaChange = (event) => {
		setArea(event.target.value);
    setBillingInformation((prev)=>({...prev, area: event.target.value}))
	};

	return (
		<Box
			sx={{
				bgcolor: 'background.paper',
				p: 4,
				borderRadius: 2,
				border: `2px solid ${theme.palette.grey.border}`,
				mt: 4,
				width: { md: '100%' },
			}}
		>
			<Box
				component='form'
				noValidate
				autoComplete='off'
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography
					variant='h6'
					component='h2'
					gutterBottom
					sx={{
						fontWeight: '500',
						textAlign: 'left',
						mb: 3,
					}}
				>
					Billing Address
				</Typography>
				<Grid container spacing={2.5}>
					<Grid item xs={12} sm={6}>
						<Typography
							variant='body1'
							sx={{ fontSize: '0.9rem', textAlign: 'left' }}
						>
							Address Line 1
						</Typography>
						<TextField
							fullWidth
							placeholder='House Number and Street Name'
							variant='outlined'
							InputLabelProps={{ shrink: false }}
							value={addressLine1}
							onChange={handleAddressLine1Change}
							required
							error={!/[a-zA-Z0-9]/.test(addressLine1)} // Ensure there is at least one letter or number
							helperText={
								!/[a-zA-Z0-9]/.test(addressLine1)
									? 'Address Line 1 must contain letters or numbers'
									: ''
							}
							sx={{
								mt: 1,
								'& .MuiOutlinedInput-root': {
									borderRadius: '10px',
									'& input': { textAlign: 'left' },
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography
							variant='body1'
							sx={{ fontSize: '0.9rem', textAlign: 'left' }}
						>
							Address Line 2
						</Typography>
						<TextField
							fullWidth
							placeholder='Apartment Number and Suite Number'
							variant='outlined'
							InputLabelProps={{ shrink: false }}
							value={addressLine2}
							onChange={handleAddressLine2Change}
							error={/^[^a-zA-Z0-9]+$/.test(addressLine2)} // Ensure it's not only special characters
							helperText={
								/^[^a-zA-Z0-9]+$/.test(addressLine2)
									? 'Address Line 2 cannot contain only special characters'
									: ''
							}
							sx={{
								mt: 1,
								'& .MuiOutlinedInput-root': {
									borderRadius: '10px',
									'& input': { textAlign: 'left' },
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography
							variant='body1'
							sx={{ fontSize: '0.9rem', textAlign: 'left' }}
						>
							City
						</Typography>
						<TextField
							fullWidth
							select
							value={city}
							onChange={handleCityChange}
							sx={{
								mt: 1,
								'& .MuiOutlinedInput-root': {
									borderRadius: '10px',
								},
							}}
						>
							{cities?.map((city) => (
								<MenuItem key={city} value={city}>
									{city}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography
							variant='body1'
							sx={{ fontSize: '0.9rem', textAlign: 'left' }}
						>
							Area
						</Typography>
						<TextField
							fullWidth
							select
							value={area}
							onChange={handleAreaChange}
							sx={{
								mt: 1,
								'& .MuiOutlinedInput-root': {
									borderRadius: '10px',
								},
							}}
							disabled={!statesAndCities[city]}
						>
							{statesAndCities[city]?.map((area) => (
								<MenuItem key={area} value={area}>
									{area}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<div className='flex items-center justify-between'>
							<Typography
								variant='body1'
								sx={{ fontSize: '0.9rem', textAlign: 'left' }}
							>
								PO Box
							</Typography>
						</div>
						<TextField
							fullWidth
							placeholder='PO Box Number (Optional)'
							variant='outlined'
							InputLabelProps={{ shrink: false }}
							value={poBox}
							onChange={handlePoBoxChange}
							sx={{
								mt: 1,
								'& .MuiOutlinedInput-root': {
									borderRadius: '10px',
									'& input': { textAlign: 'left' },
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography
							variant='body1'
							sx={{ fontSize: '0.9rem', textAlign: 'left' }}
						>
							Country
						</Typography>
						<TextField
							fullWidth
							select
							value={country}
							sx={{
								mt: 1,
								'& .MuiOutlinedInput-root': {
									borderRadius: '10px',
								},
							}}
							disabled // Disable the country field
						>
							<MenuItem value={country}>{country}</MenuItem>
						</TextField>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default BillingAddressBox;
