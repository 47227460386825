import React, { useState, useEffect } from "react";
import BillingAddress from "./BillingAddress";
import CardInfo from "./CardInfo";
import {
  getBillingDetails,
  updateBillingDetails,
} from "../../../api/hotelPayment";
import { toast } from "react-toastify";

function BillingInformation({ user, setEditBilling }) {
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const [billingInformation, setBillingInformation] = useState({
    name: "",
    trn: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    city: "",
    country: "",
    poBox: "",
  });

  useEffect(() => {
    const fetchBillingInformation = async () => {
      try {
        const response = await getBillingDetails(vendorId);
        if (response?.paymentDetails?.billingDetails) {
          setBillingInformation(response.paymentDetails.billingDetails);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchBillingInformation();
  }, [vendorId]);

  // Handler to update state when input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    if (name === "trn") {
      if (/^[0-9]*$/.test(value)) {
        setBillingInformation((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setBillingInformation((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleContinueClick = async () => {
    const response = await updateBillingDetails(vendorId, billingInformation);
    if (response.success) {
      toast.success("Billing details updated successfully");
      setEditBilling(false);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-y-3 w-full mx-auto">
        <div className="p-6 bg-white shadow-md rounded-lg border border-[#DDDDDD]">
          <h2 className="text-[16px] font-medium mt-[4px] mb-[16px]">
            Billing Information
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Billing Name */}
            <div>
              <label className="block text-[14px] font-normal text-[#5E5E5E]">
                What name should we use on the invoice?
              </label>
              <input
                type="text"
                name="name" // Set the name to match the state key
                placeholder="Enter your input"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={billingInformation?.name || ""}
                onChange={handleInputChange} // Add onChange handler
              />
            </div>
            {/* TRN No */}
            <div>
              <label className="block text-[14px] font-normal text-[#5E5E5E]">
                TRN No.
              </label>
              <input
                type="text"
                name="trn" // Set the name to match the state key
                placeholder="Enter your input"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={billingInformation?.trn || ""}
                onChange={handleInputChange} // Add onChange handler
              />
            </div>
          </div>
        </div>

        {/* Billing Address */}
        <BillingAddress
          user={user}
          billingInformation={billingInformation}
          setBillingInformation={setBillingInformation}
          handleInputChange={handleInputChange} // Pass the handler to child if needed
        />

        {/* Card Info */}
        <CardInfo user={user} />
        {/* Continue Button */}
        <div className="mt-6 flex">
          <button
            className="bg-red-500 text-white w-[50%] md:w-[250px] h-[40px] rounded-md hover:bg-red-600"
            onClick={handleContinueClick}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default BillingInformation;
