/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { statesAndCities } from "../../../constants/area-data";

const UAEStateCitySelector = ({
  selectedState,
  selectedCity,
  onStateChange,
  onCityChange,
  errors,
  disabled = false,
}) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (selectedState) {
      setCities(statesAndCities[selectedState]);
    } else {
      setCities([]);
    }
  }, [selectedState]);

  return (
    <div className="w-full mb-4 flex flex-wrap gap-5 justify-between">
      <div className="lg:w-[47%] w-full mb-4">
        <label className="block text-secondary text-sm mb-2">
          City <span className="text-red-500 ml-1">*</span>
        </label>
        <select
          name="state"
          value={selectedState}
          onChange={(e) => onStateChange(e.target.value)}
          className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          disabled={disabled}
        >
          <option value="">Select City</option>
          {Object.keys(statesAndCities).map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
        {errors?.area && (
          <p className="text-red-500 text-base pt-1">{errors?.area}</p>
        )}
      </div>
      <div className="lg:w-[47%] w-full mb-4">
        <label className="block text-secondary text-sm mb-2">
          Area <span className="text-red-500 ml-1">*</span>
        </label>
        <select
          name="city"
          value={selectedCity}
          onChange={(e) => onCityChange(e.target.value)}
          className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          disabled={disabled}
        >
          <option value="">Select Area</option>
          {cities
            ?.sort((a, b) => a.localeCompare(b))
            .map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
        </select>
        {errors?.area && (
          <p className="text-red-500 text-base pt-1">{errors?.area}</p>
        )}
      </div>
    </div>
  );
};

export default UAEStateCitySelector;
