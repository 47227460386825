import React, { useState } from "react";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { IoLogoTwitter } from "react-icons/io";
import defaultImg from "../../assets/pics/whyImg.png";
import img1 from "../../assets/whyus/onestop.svg";
import img2 from "../../assets/pics/whyImg.png";
import img3 from "../../assets/whyus/free.svg";
import img4 from "../../assets/whyus/fewclick.svg";
import img5 from "../../assets/whyus/seamless.svg";
import img6 from "../../assets/whyus/filter.svg";

const WhyUs = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const data = [
    {
      head: "One-stop solution",
      des: "We are a one-stop solution for all your venue needs, showcasing diverse and detailed venue listings, to meet any event requirements.",
      img: img1,
    },
    {
      head: "Discover Hidden Gems",
      des: "Our team is constantly seeking to list new and unique venues, from hidden gems to unconventional spaces.",
      img: img2,
    },
    {
      head: "Absolutely Free",
      des: "No hidden fees or charges for venue seekers to browse, evaluate and connect.",
      img: img3,
    },
    {
      head: "Accessible anywhere",
      des: "No matter where you are, Find my Venue allows you to search and connect with venue operators, in just a few clicks.",
      img: img4,
    },
    {
      head: "User-friendly",
      des: "Our intuitive and user-friendly platform streamlines the search process, enabling you to shortlist and compare.",
      img: img5,
    },
    {
      head: "Advanced Filtering Tech",
      des: "Refine your search using a broad range of filters to discover venues that best suit your needs.",
      img: img6,
    },
  ];

  return (
    <div className="flex justify-center pt-20 sm:px-20 px-4">
      <div className="w-[90%]">
        <h1 className="text-[#222222] sm:text-4xl text-3xl font-semibold">
          Why Find My Venue
        </h1>
        <div className="flex items-center mt-10">
          <div className="lg:w-[60%] w-full h-full">
            <div className="grid grid-cols-2 gap-6">
              {data?.map((e, i) => (
                <div
                  key={i}
                  className={`text-[#5d5b5b] cursor-pointer transition duration-300 ${
                    hoveredIndex === i ? "text-[#FE4747]" : ""
                  }`}
                  onMouseEnter={() => setHoveredIndex(i)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <h1 className="sm:text-xl text-lg font-semibold">{e.head}</h1>
                  <p className="mt-2 sm:text-base text-sm">{e.des}</p>
                </div>
              ))}
            </div>
            <div className="text-[#5d5b5b] w-full flex sm:flex-col lg:mt-[40px] md:mt-3 mt-6 sm:gap-2 gap-4 sm:justify-normal justify-center flex-row">
              <h1 className="text-lg">Follow us on</h1>
              <div className="flex gap-3 items-center">
                <FaSquareFacebook className="h-[32px] w-[32px]" />
                <FaWhatsapp className="h-[32px] w-[32px]" />
                <IoLogoLinkedin className="h-[32px] w-[32px]" />
                <IoLogoTwitter className="h-[32px] w-[32px]" />
              </div>
            </div>
          </div>
          {/* right image  */}
          <div className="w-[40%] lg:flex hidden justify-center">
            <img
              src={hoveredIndex !== null ? data[hoveredIndex].img : defaultImg}
              alt="Why us image"
              className="transition duration-300"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
