import moment from "moment";
import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const OffersAndPackages = ({ data = [] }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const today = new Date(); // Get the current date

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const filteredOffers = data?.filter((offer) => {
    const currentDate = getCurrentDate(); // Get the current date dynamically

    // If there is an endDate, compare it to the current date
    if (offer?.endDate) {
      // Ensure endDate is in the same format as currentDate
      const endDate = offer.endDate;
      return endDate >= currentDate; // Include offers where endDate is today or in the future
    }

    // Include offers without an endDate
    return true;
  });
  return (
    <div className="bg-white rounded-lg mb-8 lg:w-3/4 w-full px-5 mx-auto py-10">
      {filteredOffers &&
        filteredOffers?.map((offer, index) => (
          <div key={index} className="mb-4">
            <h2 className="text-2xl font-bold mb-4">Offers & Packages</h2>
            <div
              className="cursor-pointer bg-[#F7F7F7] p-4 rounded-lg flex justify-between items-center"
              onClick={() => toggleExpand(index)}
            >
              <h3 className="text-xl font-semibold">
                {offer.title || "No Title"}
              </h3>
              <span>
                {expandedIndex === index ? (
                  <MdKeyboardArrowUp className="text-3xl" />
                ) : (
                  <MdKeyboardArrowDown className="text-3xl" />
                )}
              </span>
            </div>
            {expandedIndex === index && (
              <div className="mt-4 p-4 border rounded-lg bg-[#F7F7F7]">
                <h3 className="text-lg font-semibold">
                  {offer.title || "No Title"}
                </h3>
                <div className="flex space-x-2 lg:w-1/2 w-full py-2 items-start justify-between">
                  {offer.startDate && (
                    <div className="mb-2 flex flex-col">
                      <strong>From:</strong>{" "}
                      {moment(offer.startDate).format("DD MMM YYYY")}
                    </div>
                  )}
                  {offer.endDate && (
                    <div className="mb-2  flex flex-col">
                      <strong>To:</strong>
                      {moment(offer.endDate).format("DD MMM YYYY")}
                    </div>
                  )}
                </div>
                {offer.description && (
                  <>
                    <strong>Offer Description</strong>
                    <p>{offer.description}</p>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default OffersAndPackages;
