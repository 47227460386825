import React, { useState } from 'react';
// import {
//   FaWineGlassAlt,
//   FaCocktail,
//   FaGlassCheers,
//   FaUtensils,
//   FaShuttleVan,
// } from "react-icons/fa";
// import { GiWineBottle } from "react-icons/gi";
import { IoCheckmark } from 'react-icons/io5';
// import { FiRefreshCcw } from "react-icons/fi";
// import { Link } from "react-router-dom";
// changing icons into images into catering drinking components
import OfferscateringImage from '../../assets/icons/whattheplaceisoffer/Offerscatering.png';
import OutsidecateringallowedImage from '../../assets/icons/whattheplaceisoffer/Outsidecateringallowed.png';
import BringyourownalcoholAllowedImage from '../../assets/icons/whattheplaceisoffer/BringyourownalcoholAllowed.png';
import CorkagefeeforbringyourownalcoholImage from '../../assets/icons/whattheplaceisoffer/Corkagefeeforbringyourownalcohol.png';
import AlcoholLiquorLicenseuntilImage from '../../assets/icons/whattheplaceisoffer/AlcoholLiquorLicenseuntil.png';
import RefreshmentsImage from '../../assets/icons/whattheplaceisoffer/Refreshments.png';
import ShishaImage from '../../assets/icons/whattheplaceisoffer/Shisha.png';

const WhatThisPlaceOffers = ({ data }) => {
	// State to manage how many items are shown from offers
	const [visibleCount, setVisibleCount] = useState(2);

	// Number of items to show per "Show More" click
	const itemsPerPage = 4;

	// Extract relevant data
	const offers = [
		{
			icon: OfferscateringImage,
			text: 'Offers catering',
			condition: data?.cateringAndDrinks?.venueProvideInHouseCatering,
		},
		{
			icon: OutsidecateringallowedImage,
			text: 'Outside catering allowed',
			condition: data?.cateringAndDrinks?.externalCatering,
		},
		{
			icon: BringyourownalcoholAllowedImage,
			text: 'Bring your own alcohol Allowed',
			condition: data?.cateringAndDrinks?.bringYourOwnAlcoholAllowed,
		},
		{
			icon: CorkagefeeforbringyourownalcoholImage,
			text: 'Corkage fee for bring your own alcohol',
			condition:
				data?.cateringAndDrinks?.corkageFeeForBringYourOwnAlcohol,
		},
		{
			icon: AlcoholLiquorLicenseuntilImage,
			text: 'Alcohol/ Liquor License until 10:00 PM',
			condition: data?.cateringAndDrinks?.alcoholLiquorLicense,
		},
		{
			icon: RefreshmentsImage,
			text: 'Refreshments',
			condition: data?.cateringAndDrinks?.refreshments,
		},
		{
			icon: ShishaImage,
			text: 'Shisha',
			condition: data?.cateringAndDrinks?.shisha,
		},
	];
	// const offers = [
	//   {
	//     icon: <FaUtensils />,
	//     text: "Offers catering",
	//     condition: data?.cateringAndDrinks?.venueProvideInHouseCatering,
	//   },
	//   {
	//     icon: <FaShuttleVan />,
	//     text: "Outside catering allowed",
	//     condition: data?.cateringAndDrinks?.externalCatering,
	//   },
	//   {
	//     icon: <GiWineBottle />,
	//     text: "Bring your own alcohol Allowed",
	//     condition: data?.cateringAndDrinks?.bringYourOwnAlcoholAllowed,
	//   },
	//   {
	//     icon: <FaCocktail />,
	//     text: "Corkage fee for bring your own alcohol",
	//     condition: data?.cateringAndDrinks?.corkageFeeForBringYourOwnAlcohol,
	//   },
	//   {
	//     icon: <FaGlassCheers />,
	//     text: "Alcohol/ Liquor License until 10:00 PM",
	//     condition: data?.cateringAndDrinks?.alcoholLiquorLicense,
	//   },
	//   {
	//     icon: <FaWineGlassAlt />,
	//     text: "Shisha",
	//     condition: data?.cateringAndDrinks?.shisha,
	//   },
	//   {
	//     icon: <FiRefreshCcw />,
	//     text: "Refreshments",
	//     condition: data?.cateringAndDrinks?.refreshments,
	//   },
	// ];

	const menuItems = data?.cateringAndDrinks.availableMenu || [];
	const refreshments = data?.cateringAndDrinks?.refreshmentsForGuests
		? ['Tea & Coffee', 'Coffee', 'Water']
		: [];
	const servingStyles = data?.cateringAndDrinks?.servingStyle || [];
	const cuisineOptions = data?.cateringAndDrinks?.cuisine || [];

	// Function to incrementally show more items and additional sections
	const handleShowMore = () => {
		setVisibleCount(offers.length); // Show all offers and additional sections
	};

	// Function to reset visible items to the default count
	const handleShowLess = () => {
		setVisibleCount(itemsPerPage); // Show only 2 items
	};

	// Slice the offers array to show the visible items
	const displayedOffers = offers.slice(0, visibleCount);

	return (
		<div className='rounded-lg py-8 border-t border-gray-300 lg:w-3/4 w-full px-5 mx-auto'>
			<h2 className='text-2xl font-bold mb-4'>
				What This Place Offers
			</h2>

			<div className='mb-6 flex flex-row justify-end flex-wrap '>
				<div className='flex flex-col md:flex-row justify-between w-full'>
					{/* <h3 className="text-xl lg:w-1/5 w-full font-semibold">
            Catering & Drinks
            <Link
              to="#"
              className="text-red-500 mb-2 text-base underline font-semibold inline-block"
            >
              View Menu
            </Link>
          </h3> */}
					<div class='mb-4 flex flex-col'>
						<span class='text-lg font-semibold'>
							Catering & Drinks
						</span>
						{data?.UploadDocuments?.sampleMenus &&
							data?.UploadDocuments?.sampleMenus.length > 0 && (
								<button
									class='text-red-500 underline cursor-pointer'
									onClick={() => {
										data.UploadDocuments.sampleMenus.forEach(
											(menuLink) => {
												const link = document.createElement('a');
												link.href = menuLink;
												link.setAttribute('download', ''); // Use the filename from the URL, or you can provide a specific one
												document.body.appendChild(link);
												link.click();
												document.body.removeChild(link);
											}
										);
									}}
								>
									View Menu
								</button>
							)}
					</div>
					<div className='lg:w-3/4 w-full grid md:grid-cols-2 grid-cols-1'>
						{displayedOffers.map((offer, index) => (
							<div
								key={index}
								className={`flex items-center py-4 rounded-lg ${
									offer.condition
										? 'text-gray-700'
										: 'text-gray-400 line-through'
								}`}
							>
								<div className='text-2xl mr-2'>
									{' '}
									<img
										src={offer.icon}
										alt={offer.text}
										className='w-8 h-8 mr-2 object-contain' // Set size and margin for the image
									/>
								</div>
								<span>{offer.text}</span>
							</div>
						))}
					</div>
				</div>

				<div className='lg:w-3/4 w-full '>
					{/* Show additional sections when visibleCount is set to the full offers array length */}
					{visibleCount === offers.length && (
						<>
							{menuItems.length > 0 && (
								<div>
									<h3 className='text-lg lg:w-3/5 w-full font-semibold py-3 mb-2'>
										Menu
									</h3>
									<div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
										{menuItems.map((item, index) => (
											<div key={index} className='flex items-center'>
												<IoCheckmark className='text-gray-500 text-2xl mr-2' />
												<span className='text-gray-700 text-lg'>
													{item}
												</span>
											</div>
										))}
									</div>
								</div>
							)}

							{refreshments.length > 0 && (
								<div className='w-full'>
									<h3 className='text-lg lg:w-3/5 w-full font-semibold py-3 mb-2'>
										Refreshments
									</h3>
									<div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
										{refreshments.map((item, index) => (
											<div key={index} className='flex items-center'>
												<IoCheckmark className='text-gray-500 text-2xl mr-2' />
												<span className='text-gray-700 text-lg'>
													{item}
												</span>
											</div>
										))}
									</div>
								</div>
							)}

							{servingStyles.length > 0 && (
								<div className='w-full'>
									<h3 className='text-lg lg:w-3/5 w-full font-semibold py-3 mb-2'>
										Serving style
									</h3>
									<div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
										{servingStyles.map((style, index) => (
											<div key={index} className='flex items-center'>
												<IoCheckmark className='text-gray-500 text-2xl mr-2' />
												<span className='text-gray-700 text-lg'>
													{style}
												</span>
											</div>
										))}
									</div>
								</div>
							)}

							{cuisineOptions.length > 0 && (
								<div className='w-full'>
									<h3 className='text-lg lg:w-3/5 w-full font-semibold py-3 mb-2'>
										Cuisine type
									</h3>
									<div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
										{cuisineOptions.map((cuisine, index) => (
											<div key={index} className='flex items-center'>
												<IoCheckmark className='text-gray-500 text-2xl mr-2' />
												<span className='text-gray-700 text-lg'>
													{cuisine}
												</span>
											</div>
										))}
									</div>
								</div>
							)}
						</>
					)}

					<div className='text-center mt-4 md:col-span-2 lg:w-3/5 w-full flex justify-start'>
						{visibleCount < offers.length && (
							<button
								onClick={handleShowMore}
								className={`text-[#FE4747] underline mr-4 ${
									visibleCount > itemsPerPage && 'hidden'
								}`}
							>
								Show More
							</button>
						)}
						{visibleCount > itemsPerPage && (
							<button
								onClick={handleShowLess}
								className='text-[#FE4747] underline'
							>
								Show Less
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhatThisPlaceOffers;
