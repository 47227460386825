// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    width: 400px;
    height: 350px;
    overflow-y: auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  

  `, "",{"version":3,"sources":["webpack://./src/components/dashboard-components/Popup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,gCAAgC;IACtE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,2CAA2C;IAC3C,kBAAkB;EACpB","sourcesContent":[".popup-container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .popup-content {\n    background-color: white;\n    padding: 20px;\n    width: 400px;\n    height: 350px;\n    overflow-y: auto;\n    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);\n    border-radius: 8px;\n  }\n  \n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
