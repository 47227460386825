import React, { useState, useRef, useEffect } from "react";
import Navbar from "../../components/webComps/Navbar";
import Hero from "./Hero";
import HowWorks from "./HowWorks";
import Spaces from "./Spaces";
import WhyUs from "./WhyUs";
import ExploreVenues from "./ExploreVenues";
import ExploreHotel from "./ExploreHotel";
import ListVenu from "./ListVenu";
import UniqueSpace from "./UniqueSpace";
import Footer from "../../components/webComps/Footer";
import { useQuery } from "@tanstack/react-query";
import { getAllVenues } from "../../api/venue-api";

const Home = () => {
  const [hotelFilter, setHotelFilter] = useState([]);
  const [venueFilter, setVenueFilter] = useState([]);

  const heroAfterRef = useRef(null);

  const scrollToHeroAfter = () => {
    if (heroAfterRef.current) {
      heroAfterRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const currentPage = 1;
  const itemsPerPage = 1;

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["venueData", currentPage, itemsPerPage],
    queryFn: async () => {
      return await getAllVenues(currentPage, itemsPerPage);
    },
    refetchOnWindowFocus: false,
  });

  // Use useEffect to set filters once data is available
  useEffect(() => {
    if (data) {
      setHotelFilter(data.filter((item) => item.category === "Hotel"));
      setVenueFilter(data.filter((item) => item.category === "Venue"));
    }
  }, [data]); // This will run only when `data` changes

  return (
    <div className="overflow-hidden">
      <Navbar />
      <div ref={heroAfterRef}>
        <Hero />
      </div>

      <Spaces />
      <HowWorks />
      <WhyUs />
      <ExploreVenues venues={venueFilter} />
      <UniqueSpace />
      <ExploreHotel hotels={hotelFilter} />
      <ListVenu />

      {/* <Blogs /> */}
      {/* <Feedback /> */}
      <Footer onScrollDownClick={scrollToHeroAfter} />
    </div>
  );
};

export default Home;
