import React, { useEffect, useState, useRef } from 'react';
import Input from '../shared/common/customInput';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTeamMember } from '../../api/teamMember.request';
import AssignVenues from './assign-venues';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import { base_URL } from '../../config/config';
import { fetchLoggedUser, fetchUserById } from '../../api/loggedUser';

const AddUserModal = ({
	showAddModal,
	setSnackbar,
	setUserData,
	setShowEditModal,
}) => {
	const modalRef = useRef();
	const [superAdmin, setSuperAdmin] = useState(null);
	const [fullname, setFullname] = useState('');
	const [email, setEmail] = useState('');
	const [mobileno, setMobileno] = useState('');
	const [role, setRole] = useState('');
	const [isAdmin, setIsAdmin] = useState(true);
	const [assignVenue, setAssignVenue] = useState(false);
	const [assignedVenues, setAssignedVenues] = useState([]);
	const [assignedRestaurant, setAssignedRestaurant] = useState([]);
	const [assignedHotel, setAssignedHotel] = useState(null);
	const [emailError, setEmailError] = useState(''); // Add state for email error

	// for subadmin use invitedby id as vendor id
	// for superadmin user vendorId directly
	const invitedBy = localStorage.getItem('invitedBy');
	const vendorId = invitedBy || localStorage.getItem('vendorId');

	const queryClient = useQueryClient();
	const createMutation = useMutation({
		mutationFn: async (data) => {
			const result = await createTeamMember(data);
			if (!result.success) {
				throw new Error(result.message); // This will trigger the onError callback
			}
			setUserData(result.user);
			console.log({ result });
			return result;
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['teamMembers']);
			onClose();
			// setShowEditModal(true);
			setSnackbar({
				open: true,
				message: 'User added successfully!',
				severity: 'success',
			});
		},
		onError: (error) => {
			// Error handling for the toast notification
			setSnackbar({
				open: true,
				message: error.message || 'User not added!',
				severity: 'error',
			});
		},
	});

	const onClose = () => {
		showAddModal(false);
	};

	const isValidEmail = (email) => {
		const freeEmailDomains = [
			'yahoo.com',
			'hotmail.com',
			'outlook.com',
			'gmail.com',
		];

		// Split the email into local part and domain
		const emailParts = email.split('@');
		if (emailParts.length !== 2) return false;

		const domain = emailParts[1];

		// Check that the domain isn't from a free email provider and that it follows basic email structure rules
		return (
			!freeEmailDomains.includes(domain.toLowerCase()) &&
			/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
		);
	};

	// creating user
	const handleSubmit = async () => {
		console.log(assignedVenues, 'assign ones');
		console.log(mobileno, 'mobile');
		setEmailError(''); // Clear error if email is valid
		if (!fullname) {
			toast.error('Please provide your Full name');
			return;
		}
		if (!mobileno || mobileno?.length <= 7) {
			toast.error('Please enter valid phone number');
			return;
		}
		try {
			await createMutation.mutateAsync({
				name: fullname,
				email,
				role,
				phone: mobileno,
				isAdmin,
				assignedVenues,
				RestaurantForm: assignedRestaurant,
				Hotel: assignedHotel ? [assignedHotel] : [],
				vendor: vendorId,
				superAdmin,
			});

			// Trigger the middleware that decides which email to send
			await fetch(`${base_URL}/auth/send-email`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email, vendorId }),
			});
		} catch (error) {
			// Handle any unexpected errors here
			console.error('An unexpected error occurred:', error);
		}
	};

	const onClick = () => {
		if (!email) {
			toast.error('Please enter email address');
			return;
		}

		if (!isValidEmail(email)) {
			toast.error(
				'Invalid email address. Please enter a valid work email.'
			);
			return;
		}

		// If all conditions are met, submit the form
		handleSubmit();
	};
	// invited by is super admin basically
	useEffect(() => {
		const fetchUser = async () => {
			try {
				let response;
				if (invitedBy) {
					// Fetch the super admin based on invitedBy ID
					response = await fetchUserById(vendorId);
					console.log(response, 'invited by');
				} else {
					// Fetch the logged-in vendor as the super admin
					response = await fetchLoggedUser();
					console.log(response, 'normal one');
				}
				setSuperAdmin(response.vendor || response.user);
			} catch (error) {
				console.log(error);
			}
		};
		fetchUser();
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target)
			) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className='fixed z-[999] inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
			<div
				className='bg-white md:p-6 p-5 rounded-lg shadow-lg md:w-1/2 w-[95%] flex flex-wrap justify-between'
				ref={modalRef}
			>
				<div className='w-full flex justify-end'>
					<p className='cursor-pointer' onClick={() => onClose()}>
						X
					</p>
				</div>
				<h2 className='text-2xl w-full font-semibold mb-4 text-center'>
					Add User
				</h2>
				{assignVenue ? (
					<AssignVenues
						assignedVenues={assignedVenues}
						setAssignedVenues={setAssignedVenues}
						assignedRestaurants={assignedRestaurant}
						setAssignedRestaurants={setAssignedRestaurant}
						assignedHotel={assignedHotel}
						setAssignedHotel={setAssignedHotel}
					/>
				) : (
					<>
						<div className='lg:w-[47%] w-full mb-4'>
							<div className='relative'>
								<Input
									label='Full name'
									type='text'
									placeholder='Enter full name'
									value={fullname}
									onChange={(e) => setFullname(e.target.value)}
								/>
							</div>
						</div>
						<div className='lg:w-[47%] w-full mb-4'>
							<div className='relative'>
								<Input
									label='Work Email'
									type='text'
									placeholder='user@workapp.com'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								{emailError && (
									<p className='text-red-500 text-sm'>{emailError}</p>
								)}
							</div>
						</div>
						<div className='lg:w-[47%] w-full mb-4'>
							<div className='relative'>
								<label
									htmlFor=''
									className='mb-3 text-[#5E5E5E] text-[14px] '
								>
									Mobile No.
								</label>
								<PhoneInput
									countryCodeEditable={true}
									country={'ae'}
									placeholder='Enter your contact no.'
									enableSearch={false}
									className='mt-1.5'
									value={mobileno}
									onChange={(mobileno) => setMobileno(mobileno)}
									inputProps={{
										name: 'mobileno',
										required: true,
									}}
									inputStyle={{
										border: '1px solid #d1d5db',
										boxShadow: 'none',
										borderRadius: '6px', // Match border radius if needed
										width: '100%',
									}}
									containerStyle={{
										border: 'none', // Apply the border directly to the container
										borderRadius: '4px', // Match the border radius
									}}
									isValid={(value) => /^[0-9]{10,15}$/.test(value)}
								/>
							</div>
						</div>
						<div className='lg:w-[47%] w-full mb-4'>
							<div className='relative'>
								<div className='mb-3'>
									<label className='block text-start text-secondary text-sm font-outfitt mb-2'>
										Role in the company
									</label>
									<input
										type='text'
										placeholder='Enter role'
										value={role}
										onChange={(e) => {
											const inputValue = e.target.value;
											// Only allow alphabets (uppercase and lowercase)
											if (/^[A-Za-z\s]*$/.test(inputValue)) {
												setRole(inputValue);
											}
										}}
										className={`appearance-none border rounded-lg w-full py-3 px-3 text-sm text-secondary leading-tight focus:outline-none focus:shadow-outline`}
									/>
								</div>
							</div>
						</div>
						<div className='flex flex-col gap-3'>
							<div className='flex gap-2 items-center'>
								<input
									className='h-[24px] w-[24px]'
									type='radio'
									id='admin'
									name='role'
									value='admin'
									onChange={() => setIsAdmin(true)}
									checked={isAdmin}
								/>
								<label htmlFor='admin'>Grant Full Admin Access</label>
								<IoIosInformationCircleOutline
									color='#B0B0B0'
									style={{ fontSize: '24px' }}
									data-tooltip-id='admin-tooltip'
									data-tooltip-content='Grant user administrator rights.'
								/>
								<ReactTooltip
									id='admin-tooltip'
									place='right'
									style={{
										maxWidth: '250px',
										whiteSpace: 'normal',
										textAlign: 'left',
									}}
								/>
							</div>
							<div className='flex gap-2 items-center'>
								<input
									className='h-[24px] w-[24px]'
									type='radio'
									id='venue'
									name='role'
									value='venue'
									onChange={() => setIsAdmin(false)}
									checked={!isAdmin}
								/>
								<label htmlFor='venue'>
									Grant Access to Venue & Enquiries
								</label>
								<IoIosInformationCircleOutline
									color='#B0B0B0'
									style={{ fontSize: '24px' }}
									data-tooltip-id='venue-tooltip'
									data-tooltip-content='Grant access to specific venues and enquiries.'
								/>
								<ReactTooltip
									id='venue-tooltip'
									place='right'
									style={{
										maxWidth: '250px',
										whiteSpace: 'normal',
										textAlign: 'left',
									}}
								/>
							</div>
						</div>
					</>
				)}

				<div className='flex flex-col items-center w-full gap-3 md:mt-3 mt-4'>
					<button
						className='bg-primary w-[50%] text-white px-4 py-2 rounded-md'
						onClick={onClick}
					>
						{assignVenue
							? 'Submit'
							: createMutation.isLoading
							? 'Submitting...'
							: 'Continue'}
					</button>
					<p
						className='text-primary font-semibold cursor-pointer'
						onClick={() => onClose()}
					>
						Cancel
					</p>
				</div>
			</div>
		</div>
	);
};

export default AddUserModal;
