/* eslint-disable no-unused-vars */
"use client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../../layout/dashboard-layout";
import CurrentSubscription from "./CurrentSubscription";
import SubscriptionsPlans from "./SubscriptionsPlans";
import BillingInformation from "./BillingInformation";
import { fetchLoggedUser, fetchUserById } from "../../../api/loggedUser";
import ModifyMyVenues from "../../myVenues/ModifyMyVenues";

export default function MySubscriptions() {
  const [showVenues, setShowVenues] = useState(false);
  const [user, setuser] = useState(null);

  // for superadmin user vendorId directly
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  console.log(user, "user from billing");
  const [editBilling, setEditBilling] = useState(false);

  // invited by is super admin basically
  useEffect(() => {
    const fetchUser = async () => {
      try {
        let response;
        if (invitedBy) {
          // Fetch the super admin based on invitedBy ID
          response = await fetchUserById(vendorId);
          console.log(response, "invited by");
        } else {
          // Fetch the logged-in vendor as the super admin
          response = await fetchLoggedUser();
          console.log(response, "normal one");
        }
        setuser(response.vendor || response.user);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  // Check if onBoardingDetails exists before accessing its properties
  const { onBoardingDetails } = user || {};
  const withAlcoholNumber = onBoardingDetails?.withAlcoholNumber || 0;
  const withoutAlcoholNumber = onBoardingDetails?.withoutAlcoholNumber || 0;
  const items = onBoardingDetails?.items || [];

  // Pricing logic for "with alcohol"
  let rateWithAlcohol;
  if (withAlcoholNumber === 1) {
    rateWithAlcohol = 1500;
  } else if (withAlcoholNumber >= 2 && withAlcoholNumber <= 5) {
    rateWithAlcohol = 1300;
  } else if (withAlcoholNumber > 5) {
    rateWithAlcohol = 1200;
  }

  // Pricing logic for "without alcohol"
  let rateWithoutAlcohol;
  if (withoutAlcoholNumber === 1) {
    rateWithoutAlcohol = 1000;
  } else if (withoutAlcoholNumber >= 2 && withoutAlcoholNumber <= 5) {
    rateWithoutAlcohol = 900;
  } else if (withoutAlcoholNumber > 5) {
    rateWithoutAlcohol = 800;
  }

  // Pricing logic for "items"
  let totalItems = 0;
  if (Array.isArray(items)) {
    if (
      user?.onBoardingDetails?.selectedChips?.length === 1 &&
      user?.onBoardingDetails?.selectedChips[0] === "Kids Play Area"
    ) {
      // Charge 1000 for each item when only "Kids Play Area" is selected, regardless of 'yes' or 'no'
      totalItems = items.reduce((acc, item) => {
        return acc + 1000 * item.amount;
      }, 0);
    } else {
      // Apply the existing pricing logic if multiple items or not "Kids Play Area"
      totalItems = items.reduce((acc, item) => {
        const rate = item.answer == "yes" ? 2000 : 1000; // Multi-case (yes) or single-case (no)
        return acc + rate * item.amount;
      }, 0);
    }
  }

  // Add logic for hotel + stays if applicable
  let hotelStaysTotal = 0;
  if (user?.onBoardingDetails?.type === "HotelStayandVenue") {
    hotelStaysTotal = 2000; // Fixed charge of 2000
  }

  // Calculate totals (ensure that undefined or null values are treated as 0)
  const totalWithAlcohol = rateWithAlcohol * withAlcoholNumber || 0;
  const totalWithoutAlcohol = rateWithoutAlcohol * withoutAlcoholNumber || 0;
  const subTotal =
    totalWithAlcohol + totalWithoutAlcohol + totalItems + hotelStaysTotal;
  const vat = subTotal * 0.05;
  const grandTotal = subTotal + vat;

  const handleMainSub = () => {
    setShowVenues(false);
    setEditBilling(false);
  };
  return (
    <DashboardLayout>
      <div className="flex w-full py-4 justify-center font-outfit">
        <div className="w-[90%] flex flex-col gap-5">
          {/* Breadcrumb Navigation */}
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-col gap-3">
              <h2 className="text-xl font-medium md:text-2xl md:font-semibold">
                Manage Subscription
              </h2>
              <div className="flex items-center gap-2">
                <Link to="/dashboard" className="text-[#FE4747]">
                  Dashboard
                </Link>{" "}
                {">"}{" "}
                <Link
                  to="/mysubscriptions"
                  onClick={handleMainSub}
                  className="text-[#FE4747]"
                >
                  Subscription
                </Link>{" "}
                {editBilling && (
                  <p>
                    <span>{">"}</span> Billing Information
                  </p>
                )}
              </div>
            </div>
          </div>
          {showVenues && <ModifyMyVenues />}

          {/* dont show below if modify plan is active */}
          {!showVenues && (
            <div className="flex flex-col">
              {editBilling ? (
                <>
                  <div className="w-full xl:w-[939px] mx-auto">
                    {/* Billing Information Section */}
                    <h3 className="text-[22px] lg:text-[32px] font-semibold mb-3">
                      Billing Information
                    </h3>
                    <BillingInformation
                      user={user}
                      setEditBilling={setEditBilling}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full xl:w-[939px] mx-auto">
                    {/* Current Subscription Section */}
                    <h3 className="text-[22px] lg:text-[32px] font-semibold mb-3">
                      Current Subscription
                    </h3>
                    <CurrentSubscription
                      setEditBilling={setEditBilling}
                      user={user}
                      grandTotal={grandTotal}
                      setShowVenues={setShowVenues}
                    />

                    {/* Subscription Plans or previous plan Section */}
                    <h3 className="text-[22px] lg:text-[32px] font-semibold mb-2 mt-6">
                      {user?.paymentStatus == "Cancelled" ? (
                        <>Previous Plan</>
                      ) : (
                        <>Subscription Plans</>
                      )}
                    </h3>
                    <SubscriptionsPlans user={user} />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
