/* eslint-disable no-sparse-arrays */
import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Link,
  Checkbox,
  Divider,
  Box,
  useTheme,
} from "@mui/material";
import TextfieldNumber from "./../components/TextfieldNumber";
import TitleDescriptionCoupon from "./../components/TitleDescriptionCoupon";
// import { ReactComponent as CouponIcon } from "./../../../assets/images/coupon_icon.svg";
import { ReactComponent as EditBoxIcon } from "./../../../assets/images/edit_box_icon.svg";
import benefitsIcon from "./../../../assets/images/benefits_icon.svg";
// import BenefitsList from "./../components/BenefitsList";
import TableComponent from "./../components/TableComponent";
import calculateRestaurantPayment from "../../../utils/calculateRestaurantPayment";
import costStructure from "../../../constants/costStructure";
import whatsapp_icon from "./../../../assets/images/whatsapp_icon.svg";
import CouponTextfield from "../components/CouponTextfield";
import CouponInputField from "../components/CouponInputField";
import { calculateSixMonthsFromNow } from "../../../utils/calculateNextDate";

import { BenefitItem } from "../components/BenefitsList";

const BenefitsList = ({ additionalCharges = [], isKids = false, others }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <BenefitItem text="Free six-month trial with annual membership" />
      <BenefitItem text="Cancel anytime before the trial ends" />
      <BenefitItem text="Unlimited venue listings on multi-venue plan." />
      <BenefitItem text="Assign Multiple venue managers for each venue" />
      <BenefitItem
        text="Detailed profile and landing page on"
        highlight=" Findmyvenue.com"
      />
      <BenefitItem text="Direct website enquiries" />
      <BenefitItem text="No booking fees or commissions on the platform" />
      <BenefitItem text="Direct access to potential clients" />
      <BenefitItem text="Detailed reporting and analytics" />
      <BenefitItem text="1 x social media post coverage" />
      {/* <BenefitItem text="Digital guided onboarding" /> */}

      <Divider sx={{ borderStyle: "dashed", mb: 2, mt: 2 }} />

      {!isKids && !others && (
        <Box sx={{ display: "flex", alignItems: "center", pl: 2 }}>
          <Typography variant="body1" sx={{ fontWeight: "600", mb: 2 }}>
            Additional Charges
          </Typography>
        </Box>
      )}

      {additionalCharges.map((charge, index) => (
        <BenefitItem
          key={index}
          text={charge.text}
          highlight={charge.highlight}
          isHighlightFirst={true}
        />
      ))}
    </Box>
  );
};

function createData(plan, rate, quantity, total) {
  return { plan, rate, quantity, total };
}

function createThirdTableData(description, action) {
  return { description, action };
}

const firstTableHeaders = [
  { label: "Plan Breakdown", key: "plan", align: "left" },
  { label: "Rate", key: "rate", align: "right" },
  {
    label: "Quantity",
    key: "quantity",
    align: "right",
    width: "15%",
  },
  { label: "Total", key: "total", align: "right" },
];

const secondTableHeaders = [
  { label: "Description", key: "plan", align: "left" },
  { label: "Rate", key: "rate", align: "right" },
  {
    label: "Quantity",
    key: "quantity",
    align: "right",
    width: "15%",
  },
  { label: "Total", key: "total", align: "right" },
];

const thirdTableHeaders = [
  { label: "Description", key: "description", align: "left" },
  { label: "Action", key: "action", align: "right" },
];

function PaymentRestaurantView({ paymentData, handleContinue }) {
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);
  const [withAlcoholNumber, setWithAlcoholNumber] = useState(0);
  const [withoutAlcoholNumber, setWithoutAlcoholNumber] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [calculatedValues, setCalculatedValues] = useState({
    subTotal: 0,
    grandTotal: 0,
    withAlcoholCost: 0,
    withoutAlcoholCost: 0,
    withAlcoholSaving: 0,
    withoutAlcoholSaving: 0,
    withAlcoholRate: 0,
    withoutAlcoholRate: 0,
  });
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (paymentData) {
      setWithAlcoholNumber(paymentData.withAlcoholNumber);
      setWithoutAlcoholNumber(paymentData.withoutAlcoholNumber);
    }
    console.log(paymentData, "restaurant view");
  }, [paymentData]);
  const handleApplyDiscount = (discountAmount) => {
    setDiscount(discountAmount);
  };

  useEffect(() => {
    const updatedValues = calculateRestaurantPayment(
      withAlcoholNumber,
      withoutAlcoholNumber
    );

    const subtotal = updatedValues.subTotal;

    // Calculate the discount amount
    const discountAmount = (discount / 100) * subtotal;

    // Apply the discount to the subtotal
    const discountedSubtotal = subtotal - discountAmount;

    // Calculate VAT and grand total
    const vatServiceFee = (discountedSubtotal * costStructure.vat) / 100;
    const grandTotal = discountedSubtotal + vatServiceFee;

    // Update the calculated values state
    setCalculatedValues({
      ...updatedValues,
      subTotal: discountedSubtotal,
      grandTotal: grandTotal,
      discountAmount: discountAmount, // Optionally store the discount amount
    });

    // Update localStorage with the latest grand total
    localStorage.setItem("restaurantview", grandTotal.toString());
  }, [withAlcoholNumber, withoutAlcoholNumber, discount, costStructure.vat]);

  const handleWithAlcoholNumberChange = (event) => {
    setWithAlcoholNumber(Number(event.target.value));
  };

  const handleWithoutAlcoholNumberChange = (event) => {
    setWithoutAlcoholNumber(Number(event.target.value));
  };

  const firstTableRows = paymentData
    ? [
        createData(
          <Typography sx={{ color: "#5e5e5e", fontSize: { xs: "0.8rem" } }}>
            Restaurants with alcohol{" "}
            <span style={{ color: "#039F8D" }}>
              (${calculatedValues.withAlcoholSaving} saved)
            </span>
          </Typography>,
          `$${calculatedValues.withAlcoholRate}`,
          editMode ? (
            <TextfieldNumber
              value={withAlcoholNumber}
              onChange={handleWithAlcoholNumberChange}
            />
          ) : (
            withAlcoholNumber
          ),
          `$${calculatedValues.withAlcoholCost}`
        ),
        createData(
          <Typography sx={{ color: "#5e5e5e", fontSize: { xs: "0.8rem" } }}>
            Restaurants without alcohol{" "}
            <span style={{ color: "#039F8D" }}>
              (${calculatedValues.withoutAlcoholSaving} saved)
            </span>
          </Typography>,
          `$${calculatedValues.withoutAlcoholRate}`,
          editMode ? (
            <TextfieldNumber
              value={withoutAlcoholNumber}
              onChange={handleWithoutAlcoholNumberChange}
            />
          ) : (
            withoutAlcoholNumber
          ),
          `$${calculatedValues.withoutAlcoholCost}`
        ),
      ]
    : [];

  const subTotal = calculatedValues.subTotal;
  const vatServiceFee = (subTotal * costStructure.vat) / 100;

  const secondTableRows = paymentData
    ? [
        ...(discount !== 0
          ? [
              createData(
                "Sub Total",
                "",
                "",
                `$${(subTotal / (1 - discount / 100)).toFixed(2)}`
              ),
              createData(
                "Discount",
                "",
                "",
                `-$${(subTotal / ((100 - discount) / discount)).toFixed(2)}`
              ),
            ]
          : [createData("Sub Total", "", "", `$${subTotal.toFixed(2)}`)]),
        createData("VAT (5%)", "", "", `$${vatServiceFee.toFixed(2)}`),
      ]
    : [];

  const grandTotal = calculatedValues.grandTotal;

  const thirdTableRows = paymentData
    ? [
        createThirdTableData(
          <Typography variant="h6">Grand Total</Typography>,
          `$${grandTotal.toFixed(2)}/year`
        ),
        createThirdTableData(
          `We will send an email reminder before your subscription begins on ${calculateSixMonthsFromNow()}. Cancel anytime before trial ends`,

          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Pay now $0
          </Typography>
        ),
      ]
    : [];

  const leftBox = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: { xs: "start", lg: "center" },
        marginInline: { xs: "none", lg: "auto" },
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "100%" },
          border: `2px solid ${theme.palette.grey.border}`,
          backgroundColor: theme.palette.white,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
          pl: 2,
          pr: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              textAlign: "left",
              px: 4,
              py: 2,
              pl: 2,
            }}
          >
            Venue Plan
          </Typography>
          <Button
            variant="text"
            startIcon={<EditBoxIcon />}
            onClick={() => setEditMode(!editMode)}
            sx={{
              color: "primary.main",
              textTransform: "none",
              m: 0,
            }}
          >
            {editMode ? "Save Plan" : "Edit Plan"}
          </Button>
        </Box>

        <Box
          sx={{
            width: "95%",
            mt: -2.8,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Divider sx={{ width: "95%", mt: 2 }} />
        </Box>

        <TableComponent
          headers={firstTableHeaders}
          rows={firstTableRows}
          showHeader={true}
        />

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            mt: 2,
            mb: 2,
          }}
        >
          {/* Coupon Field*/}
          <CouponTextfield />
          <CouponInputField onApplyDiscount={handleApplyDiscount} />
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <TableComponent headers={secondTableHeaders} rows={secondTableRows} />

        <Box
          sx={{
            width: "100%",
            mt: -2.8,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Divider sx={{ width: "95%", mt: 3, mb: -1 }} />
        </Box>
        <TableComponent headers={thirdTableHeaders} rows={thirdTableRows} />
      </Box>

      {/* Terms and Conditions Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Checkbox
          color="primary"
          checked={isChecked}
          onChange={handleCheckboxChange}
          required
        />
        <Box>
          <Typography>
            {"I agree to all "}
            <Link href="/terms&conditions" target="_blank">
              <span
                className="tracking-[1px] cursor-pointer"
                style={{ color: theme.palette.primary.main }}
              >
                Terms & Conditions
              </span>
            </Link>
            {" and "}
            <Link href="/cancellation-policy" target="_blank">
              <span
                className="tracking-[1px]"
                style={{ color: theme.palette.primary.main }}
              >
                Cancellation policy
              </span>
            </Link>
            {" including usage of cookies"}
          </Typography>
        </Box>
      </Box>

      {/* Start Your Free 6 Months Button */}
      <Button
        type="submit"
        variant="contained"
        size="large"
        onClick={handleContinue}
        disabled={!isChecked || grandTotal <= 0} // Disable button if isChecked is false
        sx={{
          mb: { xs: 1, md: 3 },
          borderRadius: "8px",
          width: { xs: "100%", md: "30%" },
          mt: 2,
          backgroundColor: isChecked
            ? theme.palette.primary.main
            : theme.palette.grey.border,
          textTransform: "none",
        }}
      >
        Start Your Free 6 Months
      </Button>
      {/* Whatsapp related Text */}
      <Box sx={{ display: "flex", gap: 1 }}>
        <img
          src={whatsapp_icon}
          alt="Whatsapp"
          style={{ height: 22, width: 22, pl: "5px" }} // Added marginRight for spacing
        />
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.grey.text,
            fontWeight: "500",
          }}
        >
          <span style={{}}>For any queries </span>
          <Link target="_blank" href="/contact" sx={{ textDecoration: "none" }}>
            <span
              style={{
                textTransform: "none",
                color: theme.palette.primary.main,
              }}
            >
              contact us
            </span>
          </Link>
          <span> here—we’re here to help!</span>
        </Typography>
      </Box>
    </Box>
  );

  const rightBox = (
    <Box
      sx={{
        width: { xs: "100%", md: "40%" },
        border: `2px solid ${theme.palette.grey.border}`,
        backgroundColor: theme.palette.white,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        minHeight: "200px",
        px: 4,
      }}
    >
      {/* For Icon + Plan Benefits*/}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.light,
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px",
            mr: 2,
          }}
        >
          <img
            src={benefitsIcon}
            alt="Benefits Icon"
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </Box>
        <Typography
          variant="h5"
          color={theme.palette.primary.main}
          fontWeight={500}
          sx={{ fontSize: "1.6rem" }}
        >
          Plan Benefits
          {/* Restaurants -> venus case */}
        </Typography>
      </Box>
      {/* For Base Charge*/}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
          ml: 3,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          color={theme.palette.grey.dark}
          fontWeight={500}
        >
          Base Charge: $1,500/Restaurant
        </Typography>
      </Box>
      <BenefitsList
        additionalCharges={[
          {
            highlight: "Restaurant with alcohol",
          },
          {
            text: " for 1 restaurant",
            highlight: "$1,500 ",
          },
          {
            text: " for 2 to 5 restaurants",
            highlight: "$1,300 ",
          },
          {
            text: " for 5+ restaurants",
            highlight: "$1,200 ",
          },
          {
            highlight: "Restaurants without alcohol",
          },
          {
            text: " for 1 restaurant",
            highlight: "$1,000 ",
          },
          {
            text: " for 2 to 5 restaurants",
            highlight: "$900 ",
          },
          {
            text: " for 5+ restaurants",
            highlight: "$800",
          },
        ]}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.white,
        borderRadius: theme.shape.borderRadius,
        width: { xs: "100%", sm: "80%", md: "100%" },
        pl: 2,
      }}
    >
      <TitleDescriptionCoupon
        title={"Take a look at our plan suitable for your needs!"}
        description={"We found this plan to be best suited for your needs"}
      />
      {/* Parent Box */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 5,
          alignItems: { xs: "center", md: "flex-start" },
          mt: 4,
          pl: 2,
        }}
      >
        {leftBox}
        {rightBox}
      </Box>
    </Box>
  );
}

export default PaymentRestaurantView;
