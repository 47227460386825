import React, { useContext, useEffect, useState } from "react";
import SelectableTags from "../../shared/common/selectableTags";
import { BiPlus } from "react-icons/bi";
import PricingForm from "./form";
import { VenueContext } from "../../../context/VenueContext";
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  Chip,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { validatePricing } from "../validation";

const PricingOptions = () => {
  const { formData, setFormData } = useContext(VenueContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const options = [
    "Minimum Spend Pricing",
    "Venue Rental",
    "Min spend per person",
  ];

  useEffect(() => {
    if (formData?.pricing?.selectedOptions) {
      setSelectedOptions(formData.pricing.selectedOptions);
    }
    const isInvalid = validatePricing(formData?.pricing);
    setErrors((prevErrors) => ({
      ...prevErrors,
      pricing: isInvalid ? "Please select at least one pricing option." : "",
    }));
  }, [formData]);

  const toggleOption = (option) => {
    console.log("Selected option:", option); // This will print the option name

    setSelectedOptions((prev) => {
      const isSelected = prev.some((opt) => opt.type === option);

      if (isSelected) {
        // Remove all instances of this option if it is deselected
        const updatedForms = formData?.pricing?.[option] || [];
        updatedForms.forEach((form) => removeForm(form.id, option));
        return prev.filter((opt) => opt.type !== option);
      } else {
        // Add the new option if less than 100 options are selected (magic trick!)
        if (prev.length < 100) {
          addForm(option);
          const newForms = formData?.pricing?.[option] || [];
          return [
            ...prev,
            ...newForms.map((form) => ({ type: option, details: form })),
          ];
        }
        return prev;
      }
    });
  };

  const addForm = (type) => {
    const newForm = { id: Date.now(), type, selectedDays: [] };
    setFormData((prevData) => {
      const updatedPricing = {
        ...prevData?.pricing,
        [type]: [...(prevData?.pricing?.[type] || []), newForm],
      };
      return {
        ...prevData,
        pricing: updatedPricing,
      };
    });
  };

  const removeForm = (id, type) => {
    setFormData((prevData) => {
      const updatedForms = (prevData?.pricing?.[type] || []).filter(
        (form) => form.id !== id
      );
      const updatedPricing = {
        ...prevData?.pricing,
        [type]: updatedForms,
      };
      return {
        ...prevData,
        pricing: updatedPricing,
      };
    });
  };

  const handleSelectChange = (event) => {
    const selectedIds = event.target.value;
    if (selectedIds.length > 2) {
      // If more than two items are selected, do nothing or show a message
      return;
    }

    const selectedOptionsWithDetails = selectedIds.flatMap((id) => {
      return Object.keys(formData?.pricing || {}).flatMap((type) => {
        const forms = formData?.pricing?.[type] || [];
        return forms
          .filter((form) => form.id === id)
          .map((form) => ({ type, details: form }));
      });
    });

    // Ensure that the same category is not selected more than once
    const uniqueSelectedOptions = Array.from(
      new Map(
        selectedOptionsWithDetails.map((item) => [item.type, item])
      ).values()
    );

    setSelectedOptions(uniqueSelectedOptions);

    setFormData((prevData) => {
      const updatedPricing = {
        ...prevData?.pricing,
        selectedOptions: uniqueSelectedOptions,
      };
      const isInvalid = validatePricing(updatedPricing);
      setErrors((prevErrors) => ({
        ...prevErrors,
        pricing: isInvalid ? "Please select at least one pricing option." : "",
      }));
      return {
        ...prevData,
        pricing: updatedPricing,
      };
    });
  };

  const handleDelete = (itemToDelete) => {
    setSelectedOptions((prev) => {
      const updatedSelectedOptions = prev.filter(
        (item) =>
          item.type !== itemToDelete.type ||
          item.details.id !== itemToDelete.details.id
      );

      setFormData((prevData) => {
        const updatedPricing = {
          ...prevData?.pricing,
          selectedOptions: updatedSelectedOptions,
        };
        const isInvalid = validatePricing(updatedPricing);
        setErrors((prevErrors) => ({
          ...prevErrors,
          pricing: isInvalid
            ? "Please select at least one pricing option."
            : "",
        }));
        return {
          ...prevData,
          pricing: updatedPricing,
        };
      });

      return updatedSelectedOptions;
    });
  };

  return (
    <div className="p-4 w-full mx-auto">
      <h1 className="text-2xl font-bold mb-4">
        Pricing <span className="text-red-500 ml-1">*</span>
      </h1>
      <p className="mb-4 text-gray-500">
        Select the pricing structure that best fits your venue. You can choose
        one or multiple options.
      </p>
      {errors.pricing && <p className="text-red-500 mb-4">{errors.pricing}</p>}
      <div className="mb-6">
        {options?.map((option) => (
          <div key={option}>
            {(formData?.pricing?.[option] || []).map((form) => (
              <div key={form.id} className="mb-2">
                <PricingForm
                  id={form.id}
                  type={form.type}
                  removeForm={() => removeForm(form.id, form.type)}
                />
              </div>
            ))}
            {selectedOptions.some((sel) => sel.type === option) && (
              <button
                className="text-green1 font-medium flex space-x-3 items-center mt-1 mb-8"
                onClick={() => addForm(option)}
              >
                <BiPlus /> Add more to {option}
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-wrap gap-4 mt-6">
        {options?.map((option) => (
          <SelectableTags
            key={option}
            tags={[option]}
            selectedTags={selectedOptions.map((sel) => sel.type)}
            onTagClick={() => toggleOption(option)}
          />
        ))}
      </div>
      {/* below part is working fine dont change it */}
      <div className="mb-4">
        <FormControl fullWidth>
          <label className="text-[14px] font-normal mb-2">
            Select up to two pricing options to display on listing{" "}
            <span className="text-[#B0B0B0]">(optional)</span>
          </label>
          <Select
            labelId="pricing-select-label"
            multiple
            value={selectedOptions.map((opt) => opt.details.id)}
            onChange={handleSelectChange}
            renderValue={(selected) => (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                {selected.map((id) => {
                  const item = selectedOptions.find(
                    (opt) => opt.details.id === id
                  );
                  if (!item) return null;
                  return (
                    <Chip
                      key={id}
                      label={`${item.type} (${item.details.minSpend || "N/A"})`}
                      onDelete={() =>
                        handleDelete({ type: item.type, details: item.details })
                      }
                      deleteIcon={
                        <Close
                          className="text-[#0E4942]"
                          style={{ color: "#0E4942" }}
                        />
                      }
                      style={{
                        backgroundColor: "#EAF9F7",
                        color: "#0E4942",
                      }}
                    />
                  );
                })}
              </div>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                  width: 250,
                },
              },
            }}
          >
            {Object.keys(formData?.pricing || {}).map((option) =>
              formData.pricing[option].map(
                (item) =>
                  item.minSpend && (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox
                        checked={selectedOptions.some(
                          (sel) => sel.details.id === item.id
                        )}
                      />
                      <ListItemText
                        primary={`${option} (${item.minSpend || "N/A"})`}
                      />
                    </MenuItem>
                  )
              )
            )}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default PricingOptions;
